const sponsoredLineItemIds: number[] = [
  6410169963, 6412440145, 6412440124, 6532579189, 6412440151, 6410169834, 6412439977, 6617961980, 6774713752,
  6741018131, 6741675761, 6731504051, 6746931247, 6746930089, 6492083901, 6492088047, 6706762926, 6714643634,
  6717918849, 6717918858, 6741018158, 6741675755, 6492094203, 6746930086, 6492094218, 6733876297, 6707133785,
  6706225567, 6704658897, 6708538053, 6714090390, 6725516360, 6729918715, 6744441506, 6746432056, 6737762534,
  6761344699, 6762373188, 6705519817, 6643887764, 6643887815, 6643887095, 6640917072, 6653334671, 6652387678,
  6653367341, 6744292291, 6756455004, 6758776024, 6756436068, 6759862202, 6758776240, 6768833498, 6766277889,
  6787428467, 6779284270, 6430122433, 6736920682, 6764739994, 6764475522, 6775452607, 6775452616, 6776656073,
  6788528305, 6767346410, 6763918512, 6763918533, 6763918686, 6763918722, 6763918740, 6766143760, 6766143769,
  6766143775, 6766143784, 6766143805, 6766143820, 6763916073, 6767375681, 6767375696, 6763896648, 6763918524,
  6763918548, 6763918701, 6763918716, 6763918731, 6766143757, 6766143766, 6766143772, 6766143778, 6766143796,
  6766143811, 6763916070, 6767375675, 6763916076, 6765936717, 6768796031, 6768199603, 6742911563, 6741721406,
  6741743516, 6632242490, 6694168074, 6402760771, 6402760465, 6449259727, 6449260051, 6686594327, 6426969759,
  6420624407, 6420017170, 6429227890, 6420624398, 6420017884, 6394013015, 6497812032, 6746270368, 6422014441,
  6717871099, 6419741706, 6717267498, 6406196786, 6403353963, 6586940393, 6726718503, 6732102143, 6749047846,
  6788501978, 6636534232, 6723812578, 6417763091, 6505956640, 6506588111, 6702073393, 6703209032, 6700044135,
  6700044129, 6700044147, 6702073399, 6709157250, 6721530945, 6710438787, 6714800124, 6737662242, 6742050612,
  6715832310, 6746900818, 6452266720, 6143597719, 6449939904, 6142721187, 6449951931, 6143597740, 6449948610,
  6143597734, 6079570209, 6089008467, 6082626704, 6488792385, 6488790171, 6491633252, 6488788461, 6449955240,
  6513528420, 6516440063, 6515949316, 6513630663, 6513630918, 6516548060, 6515435754, 6737252337, 6775302670,
  6763898151, 6732132848, 6776506445, 6255812150, 6321288679, 6692300117, 6422325564, 6429803678, 6404117749,
  6404785679, 6404785424, 6487137981, 6512889294, 6722650767, 6722650788, 6743709727, 6744743689, 6367276444,
  6498341017, 6527721312, 6404772236, 6729020768, 6731676672, 6739155006, 6785452301, 6554049500, 6769971111,
  6549917932, 6788124670, 6774479013, 6741744386, 6509265021, 6509269197, 6511586524, 6509270790, 6512150828,
  6509268465, 6706820818, 6730659329, 6556869507, 6705449013, 6726801324, 6712622326, 6710775819, 6713974310,
  6729190264, 6716802463, 6729192646, 6775420957, 6776454453, 6560101570, 6560075695, 6668184122, 6557959587,
  6560087755, 6696719499, 6690176547, 6695598907, 6641011877, 6405374366, 6405374774, 6766611973, 6535405829,
  6772889759, 6779099278, 6727405385, 6696754535, 6696754538, 6698048118, 6701142638, 5351286635, 5356230915,
  5351314043, 5447315756, 5351337806, 5533744230, 6706610794, 6706624942, 6778637404, 6685136843, 6708639521,
  6762563494, 6586230616, 6603690652, 6604513163, 6584072142, 6694979460, 6696931428, 6696971983, 6698037299,
  6697014664, 6698080478, 6647170493, 6762706540, 6702851709, 6704845390, 6787376594, 6786236332, 6762552950,
  6759745806, 6696860864, 6652625027, 6719833606, 6209546375, 6207193653, 6633638874, 6633684168, 6716957662,
  6500255686, 6500874473, 6728559040, 6744772447, 6476133975, 5094132954, 5233081411, 5273374934, 5291384100,
  5317991185, 5351009704, 5480829989, 6476133975, 5450286464, 6168975481, 6168975769, 6458953143, 6461881370,
  6461881430, 6505961989, 6505961998, 6739125720, 6739131336, 6739151460, 6741279088, 6741284617, 6742911575,
  6746282797, 6756454980, 6763869342, 6776458296, 6776458749, 6776459670, 6778623247, 6778623250, 6778623775,
  6782726554, 6786376775,
];

export default sponsoredLineItemIds;
