import { LoaderWrapper } from './LoaderStyles';
import { Loading } from '../../../../public/icons/Loading';

const Loader = ({ className }: { className?: string }) => (
  <LoaderWrapper className={className}>
    <Loading className='logo-fortune-animated' />
  </LoaderWrapper>
);

export default Loader;
