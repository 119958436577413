import { FavoritesList, FavoritesListItem } from 'interfaces/Account';
import { Tag } from 'interfaces/Taxonomy';
import { PrimaryPostTag } from 'interfaces/content/articles/Post';
import { PERSONALIZATION_ITERABLE_FAVORITES_ID } from 'lib/personalizationApi/constants';

export const formatAmpersand = (name: string) => (name !== 'Uncategorized' ? name.replace(/&amp;/g, '&') : '');

export const isSubscribedToIterableNewsletter = (subscribedMessageTypeIds: number[]) => {
  const iterableFavoritesId = parseInt(PERSONALIZATION_ITERABLE_FAVORITES_ID, 10);
  return subscribedMessageTypeIds.includes(iterableFavoritesId);
};

export const getFollowablePostTopics = (postTopics: Tag[] | null, favoritesTopics: FavoritesList) => {
  if (!postTopics) return [];
  const followableTopics = favoritesTopics.filter(
    (favoriteTopic: FavoritesListItem) => !!postTopics.find((postTopic: Tag) => postTopic.name === favoriteTopic.name),
  );
  return followableTopics;
};

export const getFollowablePrimaryTopic = (
  defaultPrimaryTopic: PrimaryPostTag | FavoritesListItem,
  followablePostTopics: FavoritesList,
): string => {
  const isDefaultPrimaryTopicFollowable = !!followablePostTopics.find(
    (followablePostTopic: FavoritesListItem) => followablePostTopic.name === defaultPrimaryTopic.name,
  );

  if (!isDefaultPrimaryTopicFollowable) {
    // eslint-disable-next-line no-param-reassign
    [defaultPrimaryTopic] = followablePostTopics;
  }

  return defaultPrimaryTopic.name;
};
