/* eslint-disable import/prefer-default-export */

export enum LoggerTags {
  // ********* Error specializations *********
  // Thrown by Error Boundaries
  UI_ERROR = 'ui-error',
  // Thrown by safe/safeAsync data processing helpers
  DATA_ERROR = 'data-error',
  // Reported by the `logApiError` function
  API_ERROR = 'api-error',
  // ********* General errors *********
  // Client-side errors and promise rejections
  CLIENT_ERROR = 'client-error',
  // Server-side errors and promise rejections, including `NotFoundError`s
  SERVER_ERROR = 'server-error',
}
