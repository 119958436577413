/* eslint-disable import/prefer-default-export */
import { IconProps } from "./IconType";

export const Play = ({ className }: IconProps) => (
  <svg className={className} width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <mask id='mask0_704_9287' style={{ maskType: 'alpha' }} maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='25'>
      <rect y='0.53125' width='24' height='24' fill='#D9D9D9'/>
    </mask>
    <g mask='url(#mask0_704_9287)'>
      <path d='M9.5 17.0312L16.5 12.5312L9.5 8.03125V17.0312ZM12 22.5312C10.6167 22.5312 9.31667 22.2687 8.1 21.7437C6.88333 21.2188 5.825 20.5063 4.925 19.6063C4.025 18.7063 3.3125 17.6479 2.7875 16.4313C2.2625 15.2146 2 13.9146 2 12.5312C2 11.1479 2.2625 9.84792 2.7875 8.63125C3.3125 7.41458 4.025 6.35625 4.925 5.45625C5.825 4.55625 6.88333 3.84375 8.1 3.31875C9.31667 2.79375 10.6167 2.53125 12 2.53125C13.3833 2.53125 14.6833 2.79375 15.9 3.31875C17.1167 3.84375 18.175 4.55625 19.075 5.45625C19.975 6.35625 20.6875 7.41458 21.2125 8.63125C21.7375 9.84792 22 11.1479 22 12.5312C22 13.9146 21.7375 15.2146 21.2125 16.4313C20.6875 17.6479 19.975 18.7063 19.075 19.6063C18.175 20.5063 17.1167 21.2188 15.9 21.7437C14.6833 22.2687 13.3833 22.5312 12 22.5312ZM12 20.5312C14.2333 20.5312 16.125 19.7563 17.675 18.2063C19.225 16.6562 20 14.7646 20 12.5312C20 10.2979 19.225 8.40625 17.675 6.85625C16.125 5.30625 14.2333 4.53125 12 4.53125C9.76667 4.53125 7.875 5.30625 6.325 6.85625C4.775 8.40625 4 10.2979 4 12.5312C4 14.7646 4.775 16.6562 6.325 18.2063C7.875 19.7563 9.76667 20.5312 12 20.5312Z' fill='#111111'/>
    </g>
  </svg>
);