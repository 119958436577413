import CustomWindow from 'interfaces/Utils';
import { useRouter } from 'next/router';
import { UserContext } from 'providers/UserProvider';
import { useContext, useEffect } from 'react';
import { EventData, GtagEvent, GtagObject, PageViewDataLayerObject } from 'services/Gtm/entities';
import { GTMPageView, pushToDataLayer, trackEvent } from 'services/Gtm/functions';
import { onCLS, onINP, onLCP } from 'web-vitals';
import InitPageStatus from './InitPageStatus';

declare let window: CustomWindow;

export interface PostMessageData {
  type: string;
  trackingData: EventData;
}

export interface PostMessage {
  data: PostMessageData;
}

export interface GoogleTagManagerProps {
  dataLayer: PageViewDataLayerObject;
}

const GoogleTagManager = (props: React.PropsWithChildren<GoogleTagManagerProps>) => {
  const { dataLayer, children } = props;
  const { userState: user } = useContext(UserContext);
  const router = useRouter();

  useEffect(() => {
    const trackWebVitals = ({ name, delta, id }: GtagEvent) => {
      const gtagObject: GtagObject = {
        event: name,
        eventAction: name,
        eventCategory: 'Web Vitals',
        eventLabel: id,
        eventValue: Math.round(name === 'CLS' ? delta * 1000 : delta),
        nonInteraction: 1,
      };
      pushToDataLayer(gtagObject);
    };

    onCLS(trackWebVitals);
    onINP(trackWebVitals);
    onLCP(trackWebVitals);
  }, []);

  useEffect(() => {
    window.addEventListener('message', (e: PostMessage) => {
      if (e.data && e.data.type === 'gtm') {
        trackEvent(e.data.trackingData);
      }
    });

    return () => {
      window.removeEventListener('message', () => {});
    };
  }, []);

  useEffect(() => {
    if (router.asPath) {
      GTMPageView({
        ...dataLayer,
        SubAudienceCD: user.subAudience,
        history: [],
        path: router.asPath,
      });
    }
  }, [router.asPath, dataLayer, user.subAudience]);

  return (
    <>
      {/* <noscript> */}
      {/*   <iframe */}
      {/*     title='fortune-gtm' */}
      {/*     src={`https://www.googletagmanager.com/ns.html?id=${process.env.GTM_ID || 'default'}`} */}
      {/*     height='0' */}
      {/*     width='0' */}
      {/*     style={{ */}
      {/*       display: 'none', */}
      {/*       visibility: 'hidden', */}
      {/*     }} */}
      {/*   /> */}
      {/* </noscript> */}
      <InitPageStatus />
      {children}
    </>
  );
};

export default GoogleTagManager;
