import { MenuItemProps } from 'interfaces/navigation/Header';
import { EventData } from 'services/Gtm/entities';
import SubMenuItem from './SubMenuItem';
import * as S from './SubmenuStyles';

interface SubMenuV1Props {
  parentLabel: string;
  dataCyLabel: string;
  subMenu: MenuItemProps[] | null;
  trackingData: EventData;
}

const SubMenuV1 = ({ subMenu, parentLabel, trackingData, dataCyLabel }: SubMenuV1Props) => {
  if (!subMenu) return null;

  return (
    <S.MenuItemSubUl data-cy={dataCyLabel}>
      {subMenu.map((menuItem: MenuItemProps) => (
        <SubMenuItem
          key={menuItem.label}
          {...menuItem}
          trackingData={trackingData}
          level={1}
          parentLabel={parentLabel}
        />
      ))}
    </S.MenuItemSubUl>
  );
};

export default SubMenuV1;
