import hashUserData from 'utils/dataUtils/hashUserData';
import { PAYMENT_API_HASH_SECURITY_KEY, PAYMENT_GEO_URL } from 'lib/paymentApi/constants';

const geoIp = (pianoUID: string): void => {
  const hashedUserData = hashUserData(pianoUID, PAYMENT_API_HASH_SECURITY_KEY);
  fetch(`${PAYMENT_GEO_URL}${pianoUID}&hash=${hashedUserData}`)
    .then((response) => response.json())
    .then((data) => {
      console.info('Success:', data);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
};

export default geoIp;
