const nFormatter = (num: number): string => {
  if (num >= 1000000000) {
    return `${(num / 1000000000).toFixed(1).replace(/\.0$/, '')}G`;
  }
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(1).replace(/\.0$/, '')}M`;
  }
  if (num >= 1000) {
    return `${(num / 1000).toFixed(1).replace(/\.0$/, '')}K`;
  }
  return String(num);
};

const existingAdIds: Set<number> = new Set();

export const generateUniqueId = (): number => {
  const startingNum = 0;
  let newId: number;

  if (existingAdIds.size === 0) {
    newId = startingNum;
  } else {
    const maxId = Math.max(...existingAdIds);
    newId = maxId + 1;
  }

  existingAdIds.add(newId);
  return newId;
};

export default nFormatter;
