import styled from 'styled-components';
import { font, color } from 'styles/globals';
import { getColor, Theme } from 'styles/theme';

export const Wrapper = styled.div`
  display: block;
  padding: 28.15%;
  position: relative;
  width: 100% !important;

  &:not(.companyVideo) {
    height: 100% !important;
  }

  > div {
    margin: 0 !important;
  }
`;

interface DockingModeProps {
  $dockingMode: boolean;
}

export const Container = styled.div<DockingModeProps>`
  ${({ $dockingMode }) =>
    $dockingMode &&
    `
    width: 300px;
    height: 169px;
    position: fixed;
    left: auto;
    bottom: 70px;
    right: 16px;
    z-index: 10000;
  `}

  ${({ $dockingMode }) =>
    !$dockingMode &&
    `
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
  `}
`;

export const CloseButton = styled.button<DockingModeProps>`
  ${({ $dockingMode }) =>
    $dockingMode &&
    `
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 10001;
    borderColor: ${getColor('neutralWhite')};
  `}

  ${({ $dockingMode }) =>
    !$dockingMode &&
    `
    display: none;
  `}
`;

export const CloseIconContainer = styled.span<{ theme: Theme }>`
  & > svg {
    fill: ${getColor('neutralBlack')};
    height: 16px;
    width: 16px;
    text-align: center;
    vertical-align: middle;
    margin-top: 10%;
    padding-left: 10%;
  }
`;

export const IFrame = styled.iframe<DockingModeProps>`
  ${({ $dockingMode }) =>
    $dockingMode &&
    `
    width: 300px;
    height: 169px;
    position: fixed;
    left: auto;
    bottom: 70px;
    right: 16px;
    z-index: 10000;
  `}

  ${({ $dockingMode }) =>
    !$dockingMode &&
    `
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
  `}
`;

export const NoConsent = styled.div<{ theme: Theme }>`
  align-items: center;
  background-color: ${getColor('neutralBlack')};
  color: ${getColor('neutralWhite')};
  display: flex;
  flex-direction: column;
  font-family: ${font.saolText};
  font-size: 18px;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  text-transform: uppercase;
  top: 0;
  width: 100%;

  a,
  span {
    margin-right: 5px;
  }

  a {
    color: ${getColor('neutralWhite')};
    text-decoration: underline;

    &:hover {
      color: ${color.linkBlue};
    }
  }
`;
