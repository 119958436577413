// eslint-disable-next-line import/prefer-default-export
export enum LinkTypes {
  // Default links
  primary, // Default 1
  secondary, // Default 2
  bluePrimary, // Default 3
  blueSecondary, // Default 4
  // Brand links
  brandPrimary, // Brand 1
  brandSecondary, // Brand 2
  brandAccentPrimary, // Brand 3
  brandAccentSecondary, // Brand 4
  brandGray,
  // Buttons
  accentButton,
  primaryButton,
  secondaryButton,
  tertiaryButton,
  tertiaryBlackButton,
  primaryBrandButton,
  secondaryBrandButton,
  tertiaryBrandButton,
  whiteButton,
  // Other links
  red,
  white,
  image,
  topicNavigation,
  // Unstyled Link
  notStyled,
}
