import { Page, PageType } from 'interfaces/content/articles/Post';
import { PianoPageConfig } from './entities/PianoPageConfig';

export function setPianoConfig(
  { dateGmt, pageType, premiumCategory, sectionNames, tagNames, topicNames }: Page,
  franchiseWall = false,
): PianoPageConfig {
  const companyPaywallException = pageType === PageType.COMPANY && !franchiseWall;

  return {
    contentSection: companyPaywallException ? 'companyNoPaywall' : premiumCategory.name,
    franchiseWall,
    pageType,
    publishDate: dateGmt,
    tags: tagNames.concat(sectionNames, topicNames),
  };
}

export function setMockPianoConfig(): PianoPageConfig {
  return {
    contentSection: '',
    franchiseWall: false,
    pageType: PageType.NO_TYPE,
    publishDate: '',
    tags: [],
  };
}
