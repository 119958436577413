import styled from 'styled-components';
import { breakpoint, font, color } from 'styles/globals';
import { Theme, getColor } from 'styles/theme';

export const MenuItemSubUl = styled.ul<{ theme: Theme }>`
  display: none;
  min-width: 160px;
  flex-direction: column;
  position: absolute;
  z-index: 11;
  background-color: ${getColor('neutralWhite')};
  padding: 8px 16px;
  left: 50%;
  transform: translate(-50%, 0);
  box-shadow: 2px 2px 4px 0px ${`${getColor('neutralBlack')}25`};
  font-family: ${font.graphikCond};
  
  // FWRC-242: A bug causes submenus to disappear on cursor movement. It was determined to happend because of
  // a blank space between the submenu and the menu. This should fix the issue.
  @media (${breakpoint.lgMin}) {
    margin-top: -1px;
  } 

  & > li:first-child {
    border-top: none;
  }

  .submenu-link {
    font-weight: 500;
    line-height: 20px;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 1px;
  }

  &.open {
    display: flex;
    transform: translate(0, 8px);
    min-width: 230px;
  }
`;

interface MenuItemSubLiProps {
  $padding?: number;
}

export const MenuItemSubLi = styled.li<MenuItemSubLiProps>`
  width: 100%;
  text-align: left;
  border-top: 1px solid ${color.secondaryLightGray};
  padding: 8px 0;
  ${(props) => props.$padding && `padding-left: ${props.$padding}px;`}

  .submenu-link {
    font-weight: 500;
    line-height: 20px;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 1px;

  }
`;
