import styled, { css } from 'styled-components';
import { breakpoint } from 'styles/globals';
import { Theme, getBodyStyle, getColor } from 'styles/theme';
import { getIcon } from 'styles/icons';

export const ButtonsWrapper = styled.div`
  width: 100%;
  overflow: auto;
  white-space: nowrap;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  scroll-behavior: smooth;
`;

export const ArrowButton = styled.button<{ theme: Theme }>`
  position: absolute;
  width: 40px;
  height: 20px;

  ${getIcon('arrow-states')}

  &.next {
    background-image: linear-gradient(to left, ${getColor('backgroundPrimary')} 70%, rgba(255, 255, 255, 0.4) 100%);
    right: -13px;

    .arrow {
      ${getIcon('next-arrow', 'small')}
    }

    &.hide {
      display: none;
    }
  }

  &.prev {
    background-image: linear-gradient(to right,  ${getColor('backgroundPrimary')} 70%, rgba(255, 255, 255, 0.4) 100%);
    z-index: 2;
    left: -11px;
    
    .arrow {
      ${getIcon('prev-arrow', 'small')}
    }

    &.hide {
      display: none;
    }
  }
  
  &.disabled {
    visibility: hidden;
  }
`;

export const Header = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;
  position: relative;
`;

export const TabsSliderWrapper = styled.div<{ theme: Theme }>`
  .next, .prev {
    width: 40px;
    height: 20px;
    svg {
      width: 18px;
      height: 12px;
    }
    &.slick-disabled {
      visibility: hidden;
    }
  }

  .slick-prev {
    background-image: linear-gradient(to right,  ${getColor('backgroundPrimary')} 70%, rgba(255, 255, 255, 0.4) 100%);
    z-index: 2;
    left: -13px;
  }

  .slick-next {
    background-image: linear-gradient(to left, ${getColor('backgroundPrimary')} 70%, rgba(255, 255, 255, 0.4) 100%);
    right: -13px;
  }
 `;

export const Button = styled.button<{ theme: Theme }>`
  display: inline-block;
  height: 38px;
  padding: 8px 24px;
  ${getBodyStyle('graphikCond', { default: 'default' })};
  text-transform: uppercase;

  &[aria-selected="true"] {
    pointer-events: none;
  }
`;

export const Wrapper = styled.div`
  grid-column: 1 / 3;

`;

export const Panel = styled.section`
  height: auto;
  padding-top: 24px;

  &.is-hidden {
    visibility: hidden;
    height: 0;
    padding-top: 0;
  }
`;

export const TabList = styled.div<{
  theme: Theme;
  $variant: 'default' | 'boxed' | 'buttons';
  $color?: string;
  $dropdownMobile?: boolean;
}>`
  & > .dropdown-controls {
    display: none;
  }

  ${({ $variant, $color, theme }) => {
    switch ($variant) {
      case 'boxed':
        return css`
          ${Button} {
            font-weight: 500;

            &[aria-selected="true"] {
              background: ${theme.colors.backgroundPrimary};
              border: 1px solid ${theme.colors.borderBlack};
            }
          }
        `;
      case 'buttons':
        return css<{ theme: Theme }>`
          & > .tab-controls > div {
            display: flex;
            gap: 18px;

            ${Button} {
              height: auto;
              border-radius: 8px;
              background: ${getColor('shadeBrandPrimary100')};
              color: ${getColor('textPrimary')};
              padding: 12px;
              text-transform: none;
              ${getBodyStyle('graphikCond', { default: 'small' }, 'semiBold')};
              
              &:hover {
                background: ${getColor('shadeBrandPrimary200')};
              }

              &[aria-selected="true"] {
                background: ${getColor('surfaceBrand')};
                color: ${getColor('textWhite')};
              }
            }
          }
        `;
      case 'default':
        return css`
          ${ButtonsWrapper}, ${TabsSliderWrapper} {
            border-bottom: 1px solid ${theme.colors.borderSecondary};
          }

          ${Button} {
            font-weight: 600;

            &:hover {
              color: ${$color || theme.colors.accentPrimary};
            }

            &[aria-selected="true"] {
              color: ${$color || theme.colors.accentPrimary};
              border: none;
              border-bottom: 1px solid ${$color || theme.colors.accentPrimary};
            }
          }
        `;
      default:
        return null;
    }
  }}
  ${({ $dropdownMobile }) =>
    $dropdownMobile &&
    css`
    .tab-controls {
      display: none;
    }

    .dropdown-controls {
      display: block;
    }

    @media (${breakpoint.mdMin}) {
      .tab-controls {
        display: flex;
      }

      .dropdown-controls {
        display: none;
      }
    }
  `}
`;
