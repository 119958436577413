/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { FooterNav } from 'interfaces/navigation/Footer';
import { SocialItem, SocialList } from 'components/SocialList';
import FooterDisclaimer from './FooterDisclaimer';
import FooterNavigation from './FooterNavigation';
import { FooterWrapper, FooterBottomWrapper } from './FooterStyles';

export interface FooterProps {
  footerNav: FooterNav;
  footerDisclaimer: string;
  socialList: SocialItem[];
}

const Footer = ({ footerNav, footerDisclaimer, socialList }: FooterProps) => (
  <FooterWrapper>
    <FooterNavigation footerNav={footerNav} />
    <FooterBottomWrapper>
      <FooterDisclaimer disclaimer={footerDisclaimer} />
      <SocialList items={socialList} />
    </FooterBottomWrapper>
  </FooterWrapper>
);

export default Footer;
