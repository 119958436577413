import * as S from 'components/TopicBasedNavigation/SubmenuV2/SubmenuStyles';

interface ExploreAllLinkProps {
  exploreAllUrl: string | undefined;
  isVisible?: boolean;
  isOutsideColumn?: boolean;
}
const ExploreAllLink = ({ exploreAllUrl, isVisible = true, isOutsideColumn }: ExploreAllLinkProps) => {
  if (!exploreAllUrl) return null;

  return (
    <S.ExploreAllLink
      href={exploreAllUrl}
      type='tertiaryBrandButton'
      size={{ default: 'large' }}
      $isVisible={isVisible}
      $isOutsideColumn={isOutsideColumn}
    >
      Explore all articles
    </S.ExploreAllLink>
  );
};

export default ExploreAllLink;
