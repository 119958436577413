type AdsEventType = 'rendered' | 'requested' | 'responseReceived' | 'loaded' | 'viewable' | 'visibilityChange';

const eventColor = (event: AdsEventType) => {
  if (['rendered', 'viewable'].includes(event)) {
    return '#00FF00';
  }

  if (['visibilityChange'].includes(event)) {
    return '#0066FF';
  }

  return '#FFD700';
};

const logAdsEvent = (slotId: string, event: AdsEventType, args?: string) => {
  const sincePageLoad = Math.round(performance.now()) / 1000;

  // eslint-disable-next-line no-console
  console.log(
    `%c[${sincePageLoad}s]%c - ${slotId} -> %c${event} ${args || ''}`,
    'background: #008080; color: #FFFFFF; font-weight: bold;',
    'color: #FFFFFF',
    `color: ${eventColor(event)}`,
  );
};

const logAdVisibilityTime = (slotId: string, time: number) => {
  const sincePageLoad = Math.round(performance.now()) / 1000;

  // eslint-disable-next-line no-console
  console.log(
    `%c[${sincePageLoad}s]%c - ${slotId} -> %cviewable for ${time}s`,
    'background: #008080; color: #FFFFFF; font-weight: bold;',
    'color: #FFFFFF',
    `color: ${eventColor('viewable')}`,
  );
};

const visibleAds: { [key: string]: number } = {};

const adVisibilityUpdate = (slotId: string, isVisible: boolean) => {
  if (isVisible) {
    visibleAds[slotId] = performance.now();
  } else {
    const time = Math.round(performance.now() - visibleAds[slotId]) / 1000;
    delete visibleAds[slotId];
    logAdVisibilityTime(slotId, time);
  }
};

/* eslint-disable import/prefer-default-export */
export const enableDebug = () => {
  googletag.pubads().addEventListener('slotRenderEnded', (e) => {
    logAdsEvent(e.slot.getSlotId().getDomId(), 'rendered');
  });
  googletag.pubads().addEventListener('slotRequested', (e) => {
    logAdsEvent(e.slot.getSlotId().getDomId(), 'requested');
  });
  googletag.pubads().addEventListener('slotResponseReceived', (e) => {
    logAdsEvent(e.slot.getSlotId().getDomId(), 'responseReceived');
  });
  googletag.pubads().addEventListener('slotOnload', (e) => {
    logAdsEvent(e.slot.getSlotId().getDomId(), 'loaded');
  });
  googletag.pubads().addEventListener('impressionViewable', (e) => {
    logAdsEvent(e.slot.getSlotId().getDomId(), 'viewable');
  });
  googletag.pubads().addEventListener('slotVisibilityChanged', (e) => {
    logAdsEvent(e.slot.getSlotId().getDomId(), 'visibilityChange', `${e.inViewPercentage}%`);
    adVisibilityUpdate(e.slot.getSlotId().getDomId(), e.inViewPercentage > 0);
  });
};
