import styled from 'styled-components';
import { breakpoint, font } from 'styles/globals';
import { Theme, getColor } from 'styles/theme';

export const Wrapper = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
`;

export const InsideWrapper = styled.div`
  width: 60%;
  margin: 48px 0;

  h1 {
    font-family: ${font.graphikCond};
    font-size: 24px;
    font-weight: 700;
    text-align: center;
      
    @media (${breakpoint.mdMin}) {
      font-size: 32px;
    }
      
    @media (${breakpoint.xlMin}) {
      font-size: 48px;
    }
  }
`;

export const Content = styled.div<{ theme: Theme }>`
  color: ${getColor('textSecondary')};
  font-family: ${font.graphikCond};
  font-size: 14px;
  font-weight: 300;
  line-height: 1.2;
  margin: 16px 0;

  @media (${breakpoint.mdMin}) {
    font-size: 24px;
    margin: 24px 0;
  }

  @media (${breakpoint.xlMin}) {
    font-size: 32px;
    margin: 48px 0;
  }
`;

export const Link = styled.a<{ theme: Theme }>`
  color: ${getColor('textLink')};
  display: inline-block;
  font-family: ${font.graphikCond};

  @media (${breakpoint.mdMin}) {
    font-size: 24px;
  }

  @media (${breakpoint.xlMin}) {
    font-size: 32px;
  }

  &:hover {
    color: ${getColor('textSecondary')};
    cursor: pointer;
  }
`;
