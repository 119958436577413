import CustomWindow from 'interfaces/Utils';
import { calculateBrowserWithAndHeight } from './browser';

declare let window: CustomWindow;

interface SlotPlacement {
  id: string;
  path?: string | undefined;
  sizes: [number, number][];
}

export function initApstag(pubID: string, bidTimeout: number) {
  // Initialize the Library
  window.apstag.init({
    adServer: 'googletag',
    bidTimeout: bidTimeout || 2e3,
    deals: true,
    pubID,
    videoAdServer: 'DFP',
  });
}

export function fetchVideoBids(fn: any) {
  window.apstag.fetchBids(
    {
      slots: [
        {
          mediaType: 'video',
          slotID: 'preroll',
        },
      ],
    },
    fn,
  );
}

export function fetchBidsAmazon(slotPlacement: SlotPlacement, fn: () => void) {
  const [width, height] = calculateBrowserWithAndHeight();
  const appSlot = {
    sizes: slotPlacement.sizes,
    slotID: slotPlacement.id,
    slotName: slotPlacement.path,
    slotParams: {
      height: height ? height.toString() : '',
      width: width ? width.toString() : '',
    },
  };
  window.apstag.fetchBids(
    {
      slots: [appSlot],
    },
    fn,
  );
}
