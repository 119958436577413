import { useEffect, useRef, useState } from 'react';
import Placeholder from 'components/Placeholder';
import WidgetWrapper from './TradingViewTickerStyles';

const TradingViewTicker = () => {
  const container = useRef<HTMLDivElement>(null);
  const scriptLoader = useRef(false);
  const [showSkeleton, setShowSkeleton] = useState(true);

  useEffect(() => {
    if (!scriptLoader.current) {
      const script = document.createElement('script');
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-tickers.js';
      script.type = 'text/javascript';
      script.async = true;
      script.innerHTML = `
    {
      "symbols": [
        {
          "description": "",
          "proName": "NASDAQ:IXIC"
        },
        {
          "description": "",
          "proName": "NYSE:NYA"
        },
        {
          "description": "",
          "proName": "FRED:DJIA"
        },
        {
          "description": "",
          "proName": "FXOPEN:SPXM"
        }
      ],
      "isTransparent": true,
      "showSymbolLogo": true,
      "colorTheme": "light",
      "locale": "en"
    }`;
      script.onerror = () => setShowSkeleton(false);
      container.current?.appendChild(script);
      scriptLoader.current = true;
      setShowSkeleton(false);
    }
  }, [showSkeleton]);

  return (
    <WidgetWrapper>
      <div
        className='tradingview-widget-container'
        ref={container}
      >
        {showSkeleton ? (
          <Placeholder size={{ height: 104 }} />
        ) : (
          <>
            <div className='tradingview-widget-container__widget' />
            <div className='tradingview-widget-copyright'>
              <a
                href='https://www.tradingview.com/'
                rel='noopener nofollow noreferrer'
                target='_blank'
                className='blue-text'
                aria-label='Go to the main page on TradingView website'
              >
                Track all markets on TradingView
              </a>
            </div>
          </>
        )}
      </div>
    </WidgetWrapper>
  );
};

export default TradingViewTicker;
