import styled, { css } from 'styled-components';
import { font, breakpoint } from 'styles/globals';
import { getColor, Theme } from 'styles/theme';

const typography = css`
  font-family: ${font.graphikCond};
  font-weight: 400;
  font-size: 16px;
  line-height: 19.2px;
`;

export const Container = styled.div<{ theme: Theme }>`
  padding: 8px 20px;
  border-bottom: 1px solid ${getColor('borderSecondary')};
  position: relative;
  z-index: -1;

  &.crypto {
    border-bottom: none;
  }

  &.landingPage {
    @media (${breakpoint.mdMin}) {
      border-bottom: none;
      padding: 8px 0 0;
    }
    @media (${breakpoint.lgMin}) {
      padding: 8px 0 0;
    }
    @media (${breakpoint.xxlMin}) {
      padding: 8px 0 0;
    }

    & > div {
      @media (${breakpoint.mdMin}) {
        border-bottom: 1px solid ${getColor('borderSecondary')};
        padding-bottom: 8px;
      }
    }
  }
`;

export const Wrapper = styled.div<{ theme: Theme }>`
  text-align: center;
  font-family: ${font.graphikCond};
  font-weight: 400;
  font-size: 16px;
  line-height: 19.2px;

  a {
    color: ${getColor('textLink')};
    text-decoration: none;

    &:hover {
      color: ${getColor('textSecondary')};
    }
  }

  @media (${breakpoint.mdMax}) {
    text-align: left;
  }
`;

export const ButtonAsLink = styled.button<{ theme: Theme }>`
  ${typography};
  border-bottom: 1px solid ${getColor('borderBlack')};
    
  &:hover {
    color: ${getColor('textLink')};
  }
    
  &:focus {
    color: ${getColor('textSecondary')};
  }
`;

export const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  ${typography};
`;

export const Dropdown = styled.div<{ theme: Theme }>`
  position: absolute;
  width: 100%;
  min-width: 230px;
  height: auto;
  top: 29px;
  right: 0;
  background-color: ${getColor('surfaceWhite')};
  z-index: 2;
  box-shadow: 0px 4px 4px 0px ${getColor('surfaceBlack')}19;
  padding: 4px 8px 8px 8px;
  text-align: left;

  border-radius: 4px;
  border: 1px solid ${getColor('borderSecondary')};

  @media screen and (min-width: 29.063rem) and (max-width: 42rem) {
    right: inherit;
  }

  @media (${breakpoint.smMax}) {
    right: 0;
  }
`;

export const DropdownHeader = styled.div`
  display: flex;
  justify-content: flex-end;

  .close {
    cursor: pointer;
    svg {
      width: 8px;
      height: 8px;
    }
  }
`;
