import * as S from './ImageTextWrapperStyles';

export const willFloat = (className?: string) => {
  if (!className) return false;
  return className.includes('wrap-image-left') || className.includes('wrap-image-right');
};

type Props = {
  children?: React.ReactElement | React.JSX.Element;
  className?: string;
  width?: string;
  height?: string;
};

const ImageTextWrapper = ({ children, className, width, height }: Props) => {
  const isFloating = willFloat(className);
  if (!children) return null;

  if (isFloating) {
    return (
      <S.ImageTextContainer
        $className={className}
        $width={width}
        $height={height}
      >
        {children}
      </S.ImageTextContainer>
    );
  }
  return children;
};

export default ImageTextWrapper;
