import styled from 'styled-components';
import { font, breakpoint } from 'styles/globals';
import { getColor, Theme } from 'styles/theme';

export const SideNavWrapper = styled.nav<{ theme: Theme }>`
  background-color: ${getColor('surfaceWhite')};
  height: auto;
  max-height: 100vh;
  display: flex;
  flex-direction: column;

  .menu-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 16px;

    .open {
      a:not(:only-child) {
        color: ${getColor('textLink')};
      }
      .arrow {
        border-color: ${getColor('textLink')};
      }
    }
    @media (${breakpoint.mdMin}) {
      margin-top: 32px;
      width: 40%;
    }
    @media (${breakpoint.lgMin}) {
      width: 50%;
    }
  }
`;

export const MenuLabel = styled.span<{ theme: Theme }>`
  font-family: ${font.graphikCond};
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  color: ${getColor('textSecondary')};
  margin-bottom: 8px;
`;

export const Wrapper = styled.div<{ theme: Theme }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-top: 24px;

  button {
    font-family: ${font.graphikCond};
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;

    & > label {
      &:hover {
        cursor: pointer;
      }
    }
  }
`;

export const SignInWrapper = styled.div<{ theme: Theme }>`
  margin: 24px 0 0;

  span {
    font-family: ${font.graphikCond};
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    color: ${getColor('neutralBlack')};
  }

  & .sign-in-button {
    color: ${getColor('textAccentSecondary')};
    margin-left: 8px;
  }

  @media (${breakpoint.mdMin}) {
    &.crypto {
      margin: 0 0 24px;
    }
  }
`;

export const MenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (${breakpoint.mdMin}) {
    gap: 0 48px;
  }

  & > div {
    flex: 1 1 0px;
  }
`;
