import styled from 'styled-components';
import { font, breakpoint } from 'styles/globals';
import { Theme, getColor, getBodyStyle } from 'styles/theme';
import { arrowDown } from 'styles/icons';

export const Title = styled.span<{ theme: Theme }>`
  line-height: 20px;
  font-weight: 600;
  color: ${getColor('textPrimary')};
  text-align: left;
  text-transform: uppercase;
`;

export const Arrow = styled.span<{ theme: Theme }>`
  ${arrowDown}
  border-width: 0 2.5px 2.5px 0;
  padding: 5px;
  border-color:  ${getColor('iconPrimary')};
`;

export const Toggle = styled.button<{ theme: Theme; $open: boolean; $noBorder: boolean; $noBrandColors: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: 16px 0 8px 0;
  width: 100%;
  border-bottom: 1px solid ${({ $noBorder, $open }) =>
    ($noBorder && 'transparent') || ($open ? getColor('borderAccentPrimary') : getColor('borderSecondary'))};

  ${Title} {
    color: ${({ $open, $noBrandColors }) =>
    ($open && !$noBrandColors ? getColor('textAccentPrimary') : getColor('textPrimary'))};
  }

  &:focus-visible {
    ${Title} {
      color:  ${({ $noBrandColors }) => !$noBrandColors && getColor('textAccentPrimary')};
    }

    .arrow {
      border-color: ${({ $noBrandColors }) =>
    (!$noBrandColors ? getColor('textAccentPrimary') : getColor('iconPrimary'))};
    }
  }

  .arrow {
    margin-top: ${({ $open }) => ($open ? '5px' : '')};
    transform: ${({ $open }) => ($open ? 'rotate(-135deg)' : '')};
    transition: 0.7s;
    border-color: ${({ $open, $noBrandColors }) =>
    ($open && !$noBrandColors ? getColor('iconAccentPrimary') : getColor('iconPrimary'))};
  }
`;

export const Content = styled.section<{ theme: Theme }>`
  line-height: 150%;
  letter-spacing: 0.25px;
  
  &[hidden] {
    visibility: hidden;
    height: 0;
  }
  
  &:not([hidden]) {
    visibility: visible;
  }
`;

export const Wrapper = styled.div<{ theme: Theme }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  
  ${Title}, ${Content} {
    font-family: ${font.graphikCond};
    font-size: 16px;
  }

  .ranking-franchise {
    ${getBodyStyle('graphikCompact', { default: 'default' }, 'regular')}
    line-height: 24px;
    letter-spacing: 0.5px;
  }
  
  .ranking-franchise.button {
    padding: 8px 0;
  }

  &:hover {
    ${Toggle} {
      ${Title} {
        color: ${getColor('textAccentPrimary')};
      }
      
      .arrow {
        border-color: ${getColor('iconAccentPrimary')};
      }
    }
  }

  @media(${breakpoint.mdMin}) {
    .ranking-franchise.title {
      font-size: 24px;
    }
  }
`;
