const ADS_TEST_IDS = {
  HP_IN_STREAM: 'hp-in-stream',
  IN_CONTENT: 'in-content',
  IN_STREAM: 'in-stream',
  LEADERBOARD: 'leaderboard',
  RIGHT_RAIL_FLEX: 'right-rail-flex',
  RIGHT_RAIL_FLEX_STICKY: 'right-rail-sticky',
};

export default ADS_TEST_IDS;
