/* eslint-disable no-debugger */
import React, { useState } from 'react';
import Script from 'next/script';
import { PageAdConfig } from 'interfaces/ads/Ad';
import { useAdContext } from 'services/Ad';
import CustomWindow from 'interfaces/Utils';
import { calculateBrowserWidth } from 'services/Ad/utils/browser';
import calcPubmaticUrl from 'services/Ad/utils/pubmatic';
import _debounce from 'lodash/debounce';

interface AdManagerProps {
  pageAdConfig: PageAdConfig;
  setAdContextValue: (value: any) => void;
}

declare let window: CustomWindow;

const AdManager: React.FC<AdManagerProps> = ({ pageAdConfig, setAdContextValue }) => {
  const [googletagInitialized, setGoogletagInitialized] = useState(false);
  const adContextValue = useAdContext(pageAdConfig, googletagInitialized);
  setAdContextValue(adContextValue);

  const pubmaticUrl = calcPubmaticUrl(162221);

  const CLPcheck =
    typeof window !== 'undefined' &&
    (window.location.search.includes('prx_t') || window.location.search.includes('ntv_adpz'));

  return (
    <>
      <Script
        id='pubmatic-js'
        src={pubmaticUrl}
        strategy='afterInteractive'
        onLoad={() => {
          window.owpbjs = window.owpbjs || {};

          if (window.owpbjs)
            window.owpbjs.setConfig({
              realTimeData: {
                auctionDelay: 50,
                dataProviders: [
                  {
                    name: 'permutive',
                    params: { acBidders: ['pubmatic', 'appnexus'] },
                    waitForIt: true,
                  },
                ],
              },
            });
        }}
      />
      <Script
        id='google-tag'
        src='https://securepubads.g.doubleclick.net/tag/js/gpt.js'
        strategy='afterInteractive'
        onLoad={() => {
          setGoogletagInitialized(true);
          googletag.cmd.push(() => {
            let stickyFlag = true;

            // All slots that are visible refresh together every 30 seconds.
            let visibleSlots: googletag.Slot[] = [];

            const debounceScrollFn = _debounce(onScroll, 200);

            function validateAdHeightOnScroll(leaderboardHeight: number, root: HTMLElement) {
              document.addEventListener('scroll', () => {
                debounceScrollFn(leaderboardHeight, root);
              });
            }

            function getLeaderboardHeight(clientWidth: number) {
              let leaderboardHeight = 110;
              const isTablet = clientWidth >= 768 && clientWidth < 1024;
              const isDesktop = clientWidth >= 1024;
              switch (true) {
                case isTablet:
                  leaderboardHeight = 160;
                  break;
                case isDesktop:
                  leaderboardHeight = 300;
                  break;
                default:
                  return 110;
              }

              return leaderboardHeight;
            }

            function refreshVisibleSlots() {
              googletag.pubads().refresh(visibleSlots);
              window.setTimeout(refreshVisibleSlots, 30000);
            }

            function onScroll(leaderboardHeight: number, root: HTMLElement) {
              const crownIframe = document.querySelector('.cc-height iframe') as HTMLElement;

              // Used to check for the crown ad when consent is rejected/accepted.
              if (crownIframe && crownIframe.clientHeight) {
                const currentHeight = crownIframe.clientHeight;
                if (window.scrollY === 0) root.style.setProperty('--ld-height', `${leaderboardHeight}px`);
                else root.style.setProperty('--ld-height', `${currentHeight}px`);
              } // set height to 0 when crown ad is not present for the navbar to be sticky to the top of page
              else root.style.setProperty('--ld-height', `${0}px`);
            }

            googletag.pubads().setTargeting('host', window.location.hostname);

            googletag.pubads().addEventListener('slotVisibilityChanged', (event) => {
              const { slot } = event;

              const slotIsNotIncluded = visibleSlots.every(
                (visibleSlot) => visibleSlot.getSlotElementId() !== slot.getSlotElementId(),
              );

              if (event.inViewPercentage >= 80 && slotIsNotIncluded) {
                visibleSlots.push(slot);
              } else if (event.inViewPercentage < 80 && !slotIsNotIncluded) {
                const removeSlotId = slot.getSlotElementId();

                visibleSlots = visibleSlots.filter((visibleSlot) => visibleSlot.getSlotElementId() !== removeSlotId);
              }
            });

            googletag.pubads().addEventListener('slotRequested', () => {
              if (document.getElementById('AdvancedSearch_InStream0')?.childNodes.length === 0) {
                googletag
                  .defineSlot(
                    '/21809533738/fortune/search/advancedsearch/instream',
                    [
                      [728, 90],
                      [300, 250],
                      [336, 280],
                    ],
                    'AdvancedSearch_InStream0',
                  )
                  ?.addService(googletag.pubads());
                googletag.pubads().refresh();
              }
            });

            googletag.pubads().addEventListener('slotRenderEnded', (e: googletag.events.SlotRenderEndedEvent) => {
              const { slot } = e;
              const SlotId = slot.getSlotElementId();
              const ClientWidth = calculateBrowserWidth();

              const parentLeader = document.getElementById('Leaderboard0')?.parentElement as HTMLElement;
              const root = document.querySelector(':root') as HTMLElement;

              // get the leaderboard height depending on client width
              const leaderboardHeight = getLeaderboardHeight(ClientWidth);

              // @ts-ignore: Object is possibly 'null'.
              const height = e.size[1];
              // @ts-ignore: Object is possibly 'null'.
              const width = e.size[0];

              const CheckCrown = (width === 970 && height === 90) || (width === 300 && height === 50);

              /** 
                There seems to be some new different logic in the crown. Which cause a slight delay for checking
                that class name. 
              **/
              setTimeout(() => {
                // Logic for crown ads. Should a refresh lead to a crown unit, remove it from refresh list
                if (SlotId === 'Leaderboard0' && parentLeader.classList.contains('cc-height')) {
                  const slotIsNotIncluded = visibleSlots.every(
                    (visibleSlot) => visibleSlot.getSlotElementId() !== slot.getSlotElementId(),
                  );

                  if (!slotIsNotIncluded)
                    visibleSlots = visibleSlots.filter((visibleSlot) => visibleSlot.getSlotElementId() !== SlotId);
                }

                /** These changes should only apply for crown leaderboard ads. We have to make sure that the Navigation bar is sticky
                    but below the crown ad which has dynamic height when you scroll down.
                **/
                if (SlotId === 'Leaderboard0' && parentLeader.classList.contains('cc-height')) {
                  root.style.setProperty('--ld-height', `${leaderboardHeight}px`);
                  validateAdHeightOnScroll(leaderboardHeight, root);
                }
              }, 3000);

              // These changes should only apply for the non crown leaderboard ads
              if (SlotId === 'Leaderboard0' && !parentLeader.classList.contains('cc-height')) {
                if (stickyFlag) {
                  root.style.setProperty('--ld-height', `${leaderboardHeight}px`);
                  // make the leaderboard sticky  immediately after load
                  parentLeader.classList.add('leaderboard--sticky');
                }

                setTimeout(() => {
                  stickyFlag = false;
                  if (ClientWidth > 820) {
                    // remove leaderboard sticky after 2500ms
                    parentLeader.classList.remove('leaderboard--sticky');
                    root.style.setProperty('--ld-height', '0px');
                  }
                }, 2500);

                if (stickyFlag && ClientWidth >= 576) {
                  setTimeout(() => {
                    if (parentLeader.classList.contains('cc-height') || CheckCrown)
                      document.querySelector('.rr-sticky-hub')?.classList.add('is-sticky-cc-height');
                    else document.querySelector('.rr-sticky-hub')?.classList.add('is-sticky');
                  }, 2500);
                }
              } else if (parentLeader.classList.contains('cc-height') || CheckCrown)
                document.querySelector('.rr-sticky-hub')?.classList.add('is-sticky-cc-height lg');
              else document.querySelector('.rr-sticky-hub')?.classList.add('is-sticky lg');
            });

            refreshVisibleSlots();

            // Bombora targeting to Nativo
            if (window.ntvConfig && window.ntvConfig.keyValues) {
              window.ntvConfig.keyValues.bombora = googletag.pubads().getTargeting('bmb').toString();
            }
          });

          // Nativo targeting
          const kvpMap: { [key: string]: string } = {};
          kvpMap.topics = pageAdConfig.topics ? pageAdConfig.topics.join(',') : '';
          window.ntvConfig = window.ntvConfig || {};
          window.ntvConfig.keyValues = kvpMap;
        }}
      />
      {CLPcheck && (
        <Script
          id='nativo-iframe'
          src='https://assets.promotedarticle.com/iframeInjection.js'
        />
      )}
      <Script
        id='nativo-js'
        src='https://s.ntv.io/serve/load.js'
        strategy='afterInteractive'
        data-ntv-set-no-auto-start
        {...(CLPcheck && { 'data-ntv-pub-injection': true })}
        onLoad={() => {
          if (typeof window !== 'undefined' && window.location.search.includes('prx_t')) window.PostRelease.Start(); // Run Nativo ads brand-studio pages
        }}
      />
      <Script id='amazon-ads-tag'>
        {
          '!function(a9,a,p,s,t,A,g){if(a[a9])return;function q(c,r){a[a9]._Q.push([c,r])}a[a9]={init:function(){q("i",arguments)},fetchBids:function() {q("f",arguments)},setDisplayBids:function(){},targetingKeys:function(){return[]},dpa:function(){q("di",arguments)},rpa:function(){q("ri",arguments)},upa:function(){q("ui",arguments)},_Q:[]};}("apstag",window);'
        }
      </Script>
    </>
  );
};

export default AdManager;
