import { FooterNav } from 'interfaces/navigation/Footer';
import Links from 'components/NavigationLinks';
import {
  FooterNavigationWrapper as Wrapper,
  FooterNavigationSection as Section,
  FooterNavigationHeading as Heading,
  FooterNavigationMenu as Menu,
} from './FooterStyles';

export interface FooterNavigationProps {
  footerNav: FooterNav;
}

const FooterNavigation = ({ footerNav }: FooterNavigationProps) => {
  const [
    rankings1,
    rankings2,
    section1,
    section2,
    section3,
    section4,
    section5,
    customerSupport,
    commercialServices,
    aboutUs,
  ] = footerNav;

  if (
    !rankings1 ||
    !rankings2 ||
    !section1 ||
    !section2 ||
    !section3 ||
    !section4 ||
    !section5 ||
    !customerSupport ||
    !commercialServices ||
    !aboutUs
  ) {
    return null;
  }

  return (
    <Wrapper>
      <Section className='rankings'>
        <Heading>Rankings</Heading>
        <Menu>
          <Links navMenu={rankings1} />
          <Links navMenu={rankings2} />
        </Menu>
      </Section>
      <Section className='sections'>
        <Heading>Sections</Heading>
        <Menu>
          <Links navMenu={section1} />
          <Links navMenu={section2} />
          <Links navMenu={section3} />
          <Links navMenu={section4} />
          <Links navMenu={section5} />
        </Menu>
      </Section>
      <Section className='customer'>
        <Heading>Customer Support</Heading>
        <Menu>
          <Links navMenu={customerSupport} />
        </Menu>
      </Section>
      <Section className='commercial'>
        <Heading>Commercial Services</Heading>
        <Menu>
          <Links navMenu={commercialServices} />
        </Menu>
      </Section>
      <Section className='about'>
        <Heading>About Us</Heading>
        <Menu>
          <Links navMenu={aboutUs} />
        </Menu>
      </Section>
    </Wrapper>
  );
};

export default FooterNavigation;
