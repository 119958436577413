import { useContext, useEffect, useRef } from 'react';
import { UserPaywallContext } from 'providers/PaywallProvider';

interface PaywallProps {
  children: JSX.Element;
}

const Paywall = ({ children }: PaywallProps) => {
  const {
    paywallState: { paywallCheck },
  } = useContext(UserPaywallContext);
  const paywallRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (paywallCheck === 'paywalled' && paywallRef.current) {
      paywallRef.current.classList.add('paywallActive');
      (paywallRef.current.previousSibling as HTMLDivElement).classList.add('paywallFade');
    } else if (paywallCheck !== 'paywalled' && paywallRef.current) {
      paywallRef.current.classList.remove('paywallActive');
      (paywallRef.current.previousSibling as HTMLDivElement).classList.remove('paywallFade');
    }
  }, [paywallCheck]);

  return (
    <div
      ref={paywallRef}
      className='paywall'
    >
      {children}
    </div>
  );
};

export default Paywall;
