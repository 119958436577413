import { Theme, getColor } from 'styles/theme';
import styled, { css } from 'styled-components';
import { font, breakpoint } from 'styles/globals';
import orderBreakpoints from 'utils/miscUtils/orderBreakpoints';
import { getIcon } from 'styles/icons';
import { ButtonProps, ButtonSizeStyling, ButtonSizes } from './types';

const basicButtonStyling = css<{ theme: Theme }>`
  display: inline-flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  font-family: ${font.graphikCond};
  font-weight: 600;
  text-align: center;
  line-height: 150%;
  letter-spacing: 0.5px;
  box-sizing: border-box;

  &::after {
    ${getIcon('next-arrow', 'small')}
  }
`;

export const buttonSizes: ButtonSizes = {
  general: {
    large: css`
      padding: 16px 32px;
      font-size: 16px;
    `,
    small: css`
      padding: 12px 16px;
      font-size: 14px;
      min-height: 48px;
    `,
  },
  tertiary: {
    large: css`
      font-size: 16px;
      margin: 16px 32px;
      &::before {
        content: ' ';
        position: absolute;
        inset: -16px -32px;
      }
    `,
    small: css`
      font-size: 16px;
    `,
  },
};

export const primaryButton = css<{ theme: Theme }>`
  ${basicButtonStyling}
  background-color: ${getColor('surfaceBlack')};
  color: ${getColor('textWhite')};

  &:hover,
  &:focus-visible {
    background-color: ${getColor('neutralDarkGray')};
    text-decoration: none;
  }

  &:focus-visible,
  &:active {
    box-shadow: 0 0 5px 0 ${getColor('textLink')};
    outline: none;
  }

  &:active {
    background-color: ${getColor('surfaceBlack')};
  }
  
  &::after {
    margin-right: 5px;
  }
`;

export const secondaryButton = css<{ theme: Theme }>`
  ${basicButtonStyling}
  color: ${getColor('textPrimary')};
  background-color: ${getColor('surfaceWhite')};
  border: 1px solid ${getColor('borderBlack')};

  &:hover,
  &:focus {
    background-color: ${getColor('surfaceSecondary')};
    text-decoration: none;
  }

  &:focus,
  &:active {
    box-shadow: 0 0 5px 0 ${getColor('textLink')};
    outline: none;
  }

  &:active {
    background-color: ${getColor('surfaceWhite')};
  }
  
  &::after {
    margin-right: 5px;
  }
`;

export const tertiaryButton = css<{ theme: Theme }>`
  ${basicButtonStyling}
  color: ${getColor('textLink')};
  background-color: transparent;
  border: 1px solid transparent;
  line-height: 120%;
  position: relative;

  &:hover,
  &:focus-visible {
    text-decoration: underline;
  }

  &:focus-visible {
    border: 1px solid ${getColor('textLink')};
    box-shadow: none;
    outline: none;
  }

  &:active {
    box-shadow: none;
    border: 1px solid transparent;
    color: ${getColor('neutralDarkGray')};

    &::after {
      border-color: ${getColor('neutralDarkGray')};
    }
  }

  &::after {
    border-color: ${getColor('textLink')};
  }
`;

export const primaryBrandButton = css<{ theme: Theme }>`
  ${basicButtonStyling}
  background-color: ${getColor('surfaceBrand')};
  color: ${getColor('textWhite')};

  &:hover,
  &:focus {
    background-color: ${getColor('shadeBrandPrimary900')};
    text-decoration: none;
  }

  &:active,
  &:focus {
    box-shadow: 0 0 5px 0 ${getColor('textLink')};
    outline: none;
  }
`;

export const secondaryBrandButton = css<{ theme: Theme }>`
  ${basicButtonStyling}
  color: ${getColor('textAccentPrimary')};
  background-color: ${getColor('surfaceWhite')};
  border: 1px solid ${getColor('borderAccentPrimary')};

  &:hover,
  &:focus {
    background-color: ${getColor('surfaceSecondary')};
    text-decoration: none;
  }

  &:active,
  &:focus {
    box-shadow: 0 0 5px 0 ${getColor('textLink')};
    outline: none;
  }

  &:active {
    background-color: ${getColor('surfaceWhite')};
  }
`;

export const tertiaryBrandButton = css<{ theme: Theme }>`
  ${basicButtonStyling}
  color: ${getColor('textAccentPrimary')};
  background-color: transparent;
  border: 1px solid transparent;
  position: relative;
  line-height: 120%;

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  &:focus-visible {
    border: 1px solid ${getColor('borderAccentPrimary')};
    box-shadow: none;
    outline: none;
  }

  &:active {
    background-color: ${getColor('neutralWhite')};
    box-shadow: none;
    border: 1px solid transparent;
    color: ${getColor('neutralDarkGray')};
    outline: none;

    &::after {
      border-color: ${getColor('neutralDarkGray')};
    }
  }

  &::after {
    border-color: ${getColor('textAccentPrimary')};
  }
`;

export const tertiaryBlackButton = css<{ theme: Theme }>`
  ${basicButtonStyling}
  color: ${getColor('textPrimary')};
  background-color: transparent;
  border: 1px solid transparent;
  line-height: 120%;
  position: relative;

  &:hover,
  &:focus {
    color: ${getColor('textLink')};
    text-decoration: underline;
    &::after,
    &::before {
      border-color: ${getColor('textLink')};
    }
  }

  &:focus {
    border: 1px solid ${getColor('textLink')};
    box-shadow: none;
    outline: none;
  }

  &:active {
    box-shadow: none;
    border: 1px solid transparent;
    color: ${getColor('neutralDarkGray')};
    outline: none;

    &::after,
    &::before {
      border-color: ${getColor('neutralDarkGray')};
    }
  }

  &::after,
  &::before {
    border-color: ${getColor('iconPrimary')};
  }
`;

export const accentButton = css<{ theme: Theme }>`
  ${basicButtonStyling}
  background-color: ${getColor('accentSecondary')};
  color: ${getColor('textWhite')};
  line-height: 120%;

  &:hover {
    background-color: ${getColor('shadeAccentPrimary700')};
    text-decoration: none;
  }

  &:focus, 
  &:active {
    box-shadow: 0 0 5px 0 ${getColor('textLink')};
    outline: none;
  }
`;

export const whiteButton = css<{ theme: Theme }>`
  background-color: ${getColor('surfaceWhite')};
  color: ${getColor('textLink')};
  text-decoration: underline;

  &:active {
    background-color: ${getColor('surfaceTertiary')};
    color: ${getColor('textPrimary')};
  }

  &:focus,
  &:active {
    box-shadow: 0 0 5px 0 ${getColor('textLink')};
  }
`;

export const disabledStyle = css<{ theme: Theme }>`
  &:disabled {
    background-color: ${getColor('neutralLightGray3')};
    border-color: ${getColor('borderSecondary')};
    color: ${getColor('neutralDarkGray')};
    cursor: not-allowed;

    &:hover,
    &:focus,
    &:active {
      color: ${getColor('neutralDarkGray')};
      background-color: ${getColor('neutralLightGray3')};
      border-color: ${getColor('borderSecondary')};
      outline: none;
      box-shadow: none;
      text-decoration: none;

      &::after {
        border-color: ${getColor('neutralDarkGray')};
      }
    }

    &::after {
      border-color: ${getColor('neutralDarkGray')};
    }
  }
`;

export const disabledTertiaryStyle = css<{ theme: Theme }>`
  &:disabled {
    color: ${getColor('neutralDarkGray')};
    box-shadow: none;
    border: 1px solid transparent;
    cursor: not-allowed;

    &:hover,
    &:focus,
    &:active {
      color: ${getColor('neutralDarkGray')};
      border: 1px solid transparent;
      box-shadow: none;
      text-decoration: none;
      outline: none;

      &::after {
        border-color: ${getColor('neutralDarkGray')};
      }
    }

    &::after {
      border-color: ${getColor('neutralDarkGray')};
    }
  }
`;

export const Button = styled.button<{
  $variant?: ButtonProps['variant'];
  disabled?: ButtonProps['disabled'];
  $size?: { default: keyof ButtonSizeStyling; [key: string]: keyof ButtonSizeStyling };
  theme: Theme;
  $hasRightArrow?: boolean;
  $hasLeftArrow?: boolean;
}>`
  ${({ $variant }) => {
    switch ($variant) {
      case 'primary':
        return primaryButton;
      case 'secondary':
        return secondaryButton;
      case 'tertiary':
        return tertiaryButton;
      case 'primaryBrand':
        return primaryBrandButton;
      case 'secondaryBrand':
        return secondaryBrandButton;
      case 'tertiaryBrand':
        return tertiaryBrandButton;
      case 'accent':
        return accentButton;
      case 'tertiaryBlack':
        return tertiaryBlackButton;
      case 'white':
        return whiteButton;
      default:
        return null;
    }
  }}

  ${({ $size, $variant }) => {
    const { default: defaultSize, ...customSizes } = $size || { default: 'small' };
    const breakpoints = orderBreakpoints(customSizes) as { [key: string]: keyof ButtonSizeStyling };
    const isTertiary = $variant && $variant.indexOf('tertiary') > -1;
    const sizesStyling = buttonSizes[isTertiary ? 'tertiary' : 'general'];

    return css`
        ${sizesStyling[defaultSize]};
        ${Object.keys(breakpoints).map(
    (bkp: string) => css`
            @media (${breakpoint[bkp]}) {
              ${sizesStyling[breakpoints[bkp]]};
            }
          `,
  )}`;
  }};

  ${({ $variant }) => {
    switch ($variant) {
      case 'primary':
      case 'secondary':
      case 'primaryBrand':
      case 'secondaryBrand':
      case 'accent':
      case 'white':
        return disabledStyle;
      case 'tertiary':
      case 'tertiaryBrand':
      case 'tertiaryBlack':
        return disabledTertiaryStyle;
      default:
        return null;
    }
  }}

  ${({ $hasRightArrow }) =>
    !$hasRightArrow &&
    css`
    &::after {
      display: none;
    }
    &:hover::after,
    &:focus::after,
    &:active::after {
      display: none;
    }
  `};
  ${({ $hasLeftArrow }) =>
    $hasLeftArrow &&
    css<{ theme: Theme }>`
    &::before {
      ${getIcon('prev-arrow', 'small')}
    }

    &:active {
      &::before {
        border-color: ${getColor('neutralDarkGray')};
      }
    }

    &::before {
      border-color: ${getColor('textLink')};
    }
  `};

  text-transform: ${({ $variant }) => ($variant?.indexOf('tertiary') === -1 ? 'uppercase' : 'none')};
`;

// Used for importing button styling in other stylesheets
export const primaryButtonLarge = css<{ theme: Theme }>`
  ${primaryButton}
  ${buttonSizes.general.large}
  ${disabledStyle}
`;

export const primaryButtonSmall = css<{ theme: Theme }>`
  ${primaryButton}
  ${buttonSizes.general.small}
  ${disabledStyle}
`;

export const secondaryButtonLarge = css<{ theme: Theme }>`
  ${secondaryButton}
  ${buttonSizes.general.large}
  ${disabledStyle}
`;

export const secondaryButtonSmall = css<{ theme: Theme }>`
  ${secondaryButton}
  ${buttonSizes.general.small}
  ${disabledStyle}
`;

export const tertiaryButtonLarge = css<{ theme: Theme }>`
  ${tertiaryButton}
  ${buttonSizes.tertiary.large}
  ${disabledTertiaryStyle}
`;

export const tertiaryButtonSmall = css<{ theme: Theme }>`
  ${tertiaryButton}
  ${buttonSizes.tertiary.small}
  ${disabledTertiaryStyle}
`;

export const primaryBrandButtonLarge = css<{ theme: Theme }>`
  ${primaryBrandButton}
  ${buttonSizes.general.large}
  ${disabledStyle}
`;

export const primaryBrandButtonSmall = css<{ theme: Theme }>`
  ${primaryBrandButton}
  ${buttonSizes.general.small}
  ${disabledStyle}
`;

export const secondaryBrandButtonLarge = css<{ theme: Theme }>`
  ${secondaryBrandButton}
  ${buttonSizes.general.large}
  ${disabledStyle}
`;

export const secondaryBrandButtonSmall = css<{ theme: Theme }>`
  ${secondaryBrandButton}
  ${buttonSizes.general.small}
  ${disabledStyle}
`;

export const tertiaryBrandButtonLarge = css<{ theme: Theme }>`
  ${tertiaryBrandButton}
  ${buttonSizes.tertiary.large}
  ${disabledTertiaryStyle}
`;

export const tertiaryBrandButtonSmall = css<{ theme: Theme }>`
  ${tertiaryBrandButton}
  ${buttonSizes.tertiary.small}
  ${disabledTertiaryStyle}
`;

export const tertiaryBlackButtonLarge = css<{ theme: Theme }>`
  ${tertiaryBlackButton}
  ${buttonSizes.tertiary.large}
  ${disabledTertiaryStyle}
`;

export const tertiaryBlackButtonSmall = css<{ theme: Theme }>`
  ${tertiaryBlackButton}
  ${buttonSizes.tertiary.small}
  ${disabledTertiaryStyle}
`;

export const accentButtonSmall = css<{ theme: Theme }>`
  ${accentButton}
  ${buttonSizes.general.small}
  ${disabledStyle}
`;

export const accentButtonLarge = css<{ theme: Theme }>`
  ${accentButton}
  ${buttonSizes.general.large}
  ${disabledStyle}
`;
