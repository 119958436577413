import * as yup from 'yup';
import isEmail from 'validator/lib/isEmail';
import { Option } from 'lib/personalizationApi/entities/MyAccountDataResponse';

export const maxLengths = {
  emailInput: 254,
  linkedInInput: 100,
  phoneInput: 20,
  stringInput: 100,
  stringTextArea: 3200,
  zipCodeInput: 20,
};

export const nameSchema = (fieldName: string) =>
  yup
    .string()
    .trim()
    .nullable()
    .transform((value: string) => (value === '' ? null : value))
    .min(2, `${fieldName} should contain at least 2 characters`)
    .max(maxLengths.stringInput, `${fieldName} maximum length can be ${maxLengths.stringInput} characters`)
    .matches(
      /^[\wÀ-ÖØ-öø-ÿ]+?(?:\.?,?'?’?`?-? ?[\wÀ-ÖØ-öø-ÿ]+\.?)*$/u,
      `${fieldName} should contain characters from A to Z`,
    );

export const emailSchema = yup
  .string()
  .email('Invalid email format')
  .required('Email is a required field')
  .max(maxLengths.emailInput, `Email maximum length can be ${maxLengths.emailInput} characters`)
  .test(
    'is-valid',
    () => 'Email entered is invalid',
    (value) =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      (value ? isEmail(value) : new yup.ValidationError('Email entered is invalid.')),
  );

export const selectSchema = (options: Option[]) =>
  yup
    .string()
    .nullable()
    .transform((value: Option | string) => (value === '' ? null : (value as Option)?.value))
    .oneOf(options.map((obj) => obj.value));

export const selectSchemaCountry = (options: Option[]) =>
  yup
    .string()
    .nonNullable('Country is a required field')
    .transform((value: Option | string) => (value === '' ? null : (value as Option)?.value))
    .oneOf(options.map((obj) => obj.value));
