import styled from 'styled-components';
import { Theme } from 'styles/theme';
import { getIcon } from 'styles/icons';

export const NextArrowWrapper = styled.button<{ theme: Theme }>`
  width: 30px;
  height: 35px;

  &.slick-next::before {
    display: none;
  }
    
  &.slick-next {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${getIcon('arrow-states')}
`;

export const NextArrow = styled.span<{ theme: Theme }>`
  ${getIcon('next-arrow')};
`;
