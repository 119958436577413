const PERSONALIZATION_API_HOST = process.env.PERSONALIZATION_API_HOST || 'default';
const PERSONALIZATION_API_KEY = process.env.PERSONALIZATION_API_KEY || 'default';
const PERSONALIZATION_ITERABLE_FAVORITES_ID = process.env.PERSONALIZATION_ITERABLE_FAVORITES_ID as string;
const PERSONALIZATION_HASH_SECURITY_KEY = process.env.PERSONALIZATION_HASH_SECURITY_KEY as string;

// eslint-disable-next-line max-len
const GET_USERS_FAVORITES_URL = `${PERSONALIZATION_API_HOST}${process.env.PERSONALIZATION_GET_CURRENT_USER_PATH}?pianoId=`;

const GET_MY_ACCOUNT_DATA_URL = `${PERSONALIZATION_API_HOST}/getMyAccountData`;

const PIANO_RESET_PASSWORD_URL = `${PERSONALIZATION_API_HOST}/sendPianoPasswordReset`;

const UPDATE_USER_FAVORITES_URL = `${PERSONALIZATION_API_HOST}/user`;

const UPDATE_PIANO_SUBSCRIPTION_URL = `${PERSONALIZATION_API_HOST}/updatePianoSubscription`;

const UPDATE_USER_PROFILE_URL = `${PERSONALIZATION_API_HOST}/updateUserProfile`;

const FIND_USER_IN_PIANO = `${PERSONALIZATION_API_HOST}/findUserInPiano`;

const ITERABLE_USER_URL = `${PERSONALIZATION_API_HOST}${process.env.PERSONALIZATION_GET_OPT_IN_USER_PATH}?email=`;

export {
  PERSONALIZATION_API_HOST,
  PERSONALIZATION_API_KEY,
  GET_USERS_FAVORITES_URL,
  GET_MY_ACCOUNT_DATA_URL,
  PIANO_RESET_PASSWORD_URL,
  UPDATE_USER_FAVORITES_URL,
  UPDATE_PIANO_SUBSCRIPTION_URL,
  UPDATE_USER_PROFILE_URL,
  FIND_USER_IN_PIANO,
  ITERABLE_USER_URL,
  PERSONALIZATION_ITERABLE_FAVORITES_ID,
  PERSONALIZATION_HASH_SECURITY_KEY,
};
