import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { getGtmPageStatusEvent, pushToDataLayer } from './functions';

const InitPageStatus = () => {
  const router = useRouter();

  useEffect(() => {
    pushToDataLayer(getGtmPageStatusEvent(false));
  }, [router.asPath]);

  return null;
};

export default InitPageStatus;
