import { BreakpointValues } from 'styles/theme/types';

export default function orderBreakpoints(obj: { [key in BreakpointValues]?: any }) {
  try {
    const sortedObj = {} as { [key in BreakpointValues]?: any };
    const objProperties = Object.keys(obj);
    const breakpointsOrder = ['sm', 'mm', 'md', 'lg', 'hybrid', 'xl', 'xxl'];
    (
      [
        ...breakpointsOrder.map((val) => `${val}Min`),
        ...breakpointsOrder.map((val) => `${val}Max`),
      ] as BreakpointValues[]
    ).forEach((key) => {
      if (objProperties.includes(key)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        sortedObj[key] = obj[key];
      }
    });
    return sortedObj;
  } catch (error) {
    return obj;
  }
}
