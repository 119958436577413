const EVENTS = {
  PIANO_LOGIN: 'piano:loggedIn',
  PIANO_LOGOUT: 'piano:loggedOut',
};

const DIGITAL_SUBSCRIPTION = 'Digital Subscription';
const PREMIUM_SUBSCRIPTION = 'Premium Subscription';

interface ProductProps {
  [index: string]: string;
}

const PRODUCTS: ProductProps = {
  // Prod
  BR4DOBOZ: PREMIUM_SUBSCRIPTION,

  // Dev
  BRBTITTG: PREMIUM_SUBSCRIPTION,

  BRD4YXL2: DIGITAL_SUBSCRIPTION,
  // Dev
  BRYRDXRN: DIGITAL_SUBSCRIPTION, // Prod
};

export { EVENTS, PRODUCTS };
