import styled, { css } from 'styled-components';
import { getBodyStyle, getColor, Theme } from 'styles/theme';
import { breakpoint, font } from 'styles/globals';
import { brandGrayLink } from 'components/Globals/Base/Link/LinkStyles';

const footerWrapper = css<{ theme: Theme }>`
  font-family: ${font.graphikCond};
  border-top: 1px solid ${getColor('borderBlack')};
  background-color: ${getColor('backgroundPrimary')};
  gap: 24px;
  margin-top: 80px;
`;

const linksList = css`
  display: flex;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
`;

export const FooterWrapperMobileAndTablet = styled.footer<{ theme: Theme }>`
  ${footerWrapper};
  padding: 20px;
  display: flex;
  flex-direction: column;
    
  nav {
    // accordion buttons  
    button {
      padding: 8px 0;
    }
      
    ul {
      ${linksList};
      flex-direction: column;
      gap: 16px;
      padding: 8px 0 24px 0;
    }
        
    div:last-of-type {
      ul {
        padding: 8px 0 0 0;
      }
    }
  }

  @media (${breakpoint.mdMin}) {
    padding: 20px 32px;
  }

  @media (${breakpoint.lgMin}) {
    display: none;
  }
`;

export const FooterWrapperDesktop = styled.footer<{ theme: Theme }>`
  display: none;
  ${footerWrapper};
  padding: 20px var(--Grid-Margin);
    
  nav {
    display: flex;
    flex-direction: column;
    gap: 16px;  
  }

  @media (${breakpoint.lgMin}) {
    display: flex;
    flex-direction: column;
  }
`;

const sectionStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FooterDesktopSectionWrapper = styled.div<{ theme: Theme }>`
  ${sectionStyle};
  padding-bottom: 16px;
  gap: 8px;
  border-bottom: 1px solid ${getColor('borderSecondary')};
    
  .section-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-transform: uppercase;
  }
    
  ul {
    ${linksList};
    gap: 32px;
  }
`;

const socialIconsList = css<{ theme: Theme }>`
  ul {
    margin: 0 0 4px 0;
    order: unset; // the SocialList component has an order set that needs to be reset
    a {
      border-color: ${getColor('borderAccentPrimary')};
      svg {
        fill: ${getColor('iconAccentPrimary')};
      }
    }
    a:hover, a:focus {
      border-color: ${getColor('shadeBrandPrimary900')};
      svg {
        fill: ${getColor('shadeBrandPrimary900')};
      }
    }
    a:active {
      border-color: ${getColor('borderTertiary')};
      svg {
        fill: ${getColor('iconSecondary')};
      }
    }
  }
`;

export const FooterBottomWrapper = styled.div<{ theme: Theme }>`
  gap: 24px;
  ${sectionStyle};
  ${socialIconsList};
`;

export const FooterDisclaimer = styled.div<{ theme: Theme }>`
  ${getBodyStyle('graphikCond', { default: 'xsmall' })};
  line-height: 18px;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${getColor('textSecondary')};
  order: unset;
    
  a {
    ${brandGrayLink}; 
    text-decoration: underline;
    &:hover, &:focus {
      text-decoration: underline;
    }
  }
`;
