import { useContext, useEffect, useRef } from 'react';
import { UserPaywallContext } from 'providers/PaywallProvider';

interface RegwallProps {
  children: JSX.Element;
}

const Regwall = ({ children }: RegwallProps) => {
  const {
    paywallState: { regwallCheck },
  } = useContext(UserPaywallContext);
  const regwallRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (regwallCheck && regwallRef.current) {
      regwallRef.current.classList.add('paywallActive');
    } else if (! regwallCheck && regwallRef.current) {
      regwallRef.current.classList.remove('paywallActive');
    }
  }, [regwallCheck]);

  return (
    <div
      ref={regwallRef}
      className='regwall'
    >
      {children}
    </div>
  );
};

export default Regwall;
