import { AdConfig, AdPlacementConfig, AdSlotConfig, PlacementName } from 'interfaces/ads/Ad';
import { getQueryVariable } from 'utils/stringUtils';
import { fetchBidsAmazon, initApstag } from './utils/apstag';
import { calculateBrowserWidth } from './utils/browser';
import { enableDebug } from './utils/debug';
import {
  configureAds,
  configureTargeting,
  defineSlot,
  displayAdSlot,
  gptQueue,
  refreshAdSlots,
  refreshAdSlotsAmazon,
  registerAdSlotsRenderListener,
} from './utils/googletag';

export default class AdLibrary {
  private static instance: AdLibrary; // eslint-disable-line

  private config!: AdConfig;

  private slots: { [id: string]: googletag.Slot };

  private listeners: { [id: string]: ((event: any) => void) | null };

  private initialized: boolean;

  static getInstance(): AdLibrary {
    if (!this.instance) {
      this.instance = new AdLibrary();
    }

    return this.instance;
  }

  private constructor() {
    this.initialized = false;
    this.slots = {};
    this.listeners = {};
  }

  async init(config: AdConfig) {
    if (this.initialized) {
      return;
    }
    this.initialized = true;
    this.config = config;
    this.loadAmazon();
    await Promise.all([gptQueue(this.loadGPT.bind(this), () => {})]);
    if (getQueryVariable('adsDebug') !== '') {
      enableDebug();
    }
    this.registerAdSlotsRenderListener();
  }

  loadAmazon() {
    const { amazon } = this.config;
    if (amazon.enabled) {
      initApstag(amazon.pubID, amazon.bidTimeout);
    }
  }

  loadGPT() {
    this.defineSlots();
    configureTargeting(this.config.targeting);
    configureAds(this.config);
    this.displayAdSlots();
  }

  buildSlotPlacement(slotConfig: AdSlotConfig, browserWidth = calculateBrowserWidth()) {
    const placement = this.config.placements.find((pl) => pl.name === slotConfig.placementName);
    const slotPlacement = { ...placement, ...slotConfig };
    // sizes is required for define slot, but we are going to always be using sizeMappings
    let sizes: [number, number][] = [];
    const sizeMappings = slotPlacement.sizeMappings || [];
    for (let i = 0; i < sizeMappings.length; i += 1) {
      if (sizeMappings[i].size && sizeMappings[i].size.length > 0 && browserWidth >= sizeMappings[i].viewport.size[0]) {
        sizes = sizeMappings[i].size;
      }
    }
    return { ...slotPlacement, ...{ sizes } };
  }

  defineSlot(slotConfig: AdSlotConfig, browserWidth = calculateBrowserWidth()) {
    const slotPlacement = this.buildSlotPlacement(slotConfig, browserWidth);
    const { id, index, path, placementName, sizeMappings, sizes } = slotPlacement;

    const numPath = this.defineSlotPath(placementName, path, index);

    const slot = defineSlot(numPath || this.config.path, sizes, id, sizeMappings, {
      index: `${index}`,
      placement_name: placementName,
    });

    if (slot) {
      this.slots[id] = slot;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  defineSlotPath(placementName: string, path: string | undefined, index: number): string | undefined {
    const numPath = [
      'InStream',
      'SimpleInStream',
      'Homepage-InStream',
      'RightRailFlex',
      'RightRailFlex_Articles',
      'InContent',
    ].includes(placementName) ?
      `${path}/${index}` :
      path;

    return numPath;
  }

  defineSlots() {
    const browserWidth = calculateBrowserWidth();
    this.config.slots.forEach((slotConfig) => {
      this.defineSlot(slotConfig, browserWidth);
    });
  }

  displayAdSlots() {
    this.config.slots.forEach(({ id }) => {
      displayAdSlot(id);
    });
  }

  displayAdSlot(slotConfig: AdSlotConfig) {
    const { slots } = this;
    const { id, placementName } = slotConfig;

    if (placementName && !slots[id]) {
      this.defineSlot(slotConfig);
    }

    this.refreshSlot(slotConfig);
  }

  refreshSlot(slotConfig: AdSlotConfig) {
    if (this.config.amazon.enabled) {
      const slotPlacement = this.buildSlotPlacement(slotConfig);
      fetchBidsAmazon(slotPlacement, () => {
        refreshAdSlotsAmazon([this.slots[slotConfig.id]]);
      });
    } else {
      refreshAdSlots([this.slots[slotConfig.id]]);
    }
  }

  getPlacementConfig = (placementName: PlacementName): AdPlacementConfig => {
    const config = this.config.placements.find((placementConfig) => placementConfig.name === placementName);

    if (!config) {
      throw new Error(`Placement ${placementName} is not configured!`);
    }

    return config;
  };

  registerAdSlotsRenderListener = () => {
    registerAdSlotsRenderListener((event) => {
      const slotId = event.slot.getSlotId().getDomId();
      const listener = this.listeners[slotId];
      if (listener) {
        listener(event);
      }
    });
  };

  registerAdSlotRenderCallback = (id: string, callback: (event: any) => void) => {
    this.listeners[id] = callback;
  };
}
