import styled from 'styled-components';

const PaywallSelector = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  width: 100%;
  height: 600px;
  pointer-events: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;

  & iframe {
    pointer-events: auto;
  }
`;

export default PaywallSelector;
