/* eslint-disable import/prefer-default-export */

import styled from 'styled-components';
import { getColor, Theme } from 'styles/theme';

export const UICrashFallbackContainer = styled.div<{ theme: Theme }>`
  background-color: ${getColor('backgroundSecondary')};
  color: ${getColor('textSecondary')};
  padding: 12px;
  gap: 8px;
  border-radius: 2px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  & b {
    line-height: 100%;
    font-size: 150%;
  };
`;
