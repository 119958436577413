import styled from 'styled-components';
import { breakpoint } from 'styles/globals';
import { Theme, getColor } from 'styles/theme';

export const DrawerToggle = styled.input`
  &.drawer-toggle {
    display: none;

  &:checked {
    & ~ .drawer-menu {
      visibility: visible;
      left: 0;
    }

    & ~ .overlay {
      visibility: visible;
      opacity: 0.5;
    }
  }
}
`;

export const DrawerToggleButton = styled.label<{ theme: Theme }>`
  &.hamburger-toggle {
    display: block;
    width: 30px;
    height: 23px;
    cursor: pointer;
    margin-right: 24px;
    position: relative;
    margin-bottom: 10px;

    @media (${breakpoint.lgMin}) {
		  margin-right: 48px;	
	  } 
    
    svg {
      transition: all .2s ease-in-out;
      width: 30px;
      height: 30px;
    }

    &:hover {
      svg{
        fill: ${getColor('accentPrimary')};
        transition: all .2s ease-in-out;
      }
    }

    &:focus, &:active {
      svg {
        fill: ${getColor('iconSecondary')};
      }
    }
  }

  &.close-menu {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    cursor: pointer;
    position: relative;

    svg {
      width: 33px;
      height: 40px;
      transition: all .2s ease-in-out;
        &:hover {
        transition: all .2s ease-in-out;
         color: ${getColor('accentPrimary')};
        }
      &:focus, &:active {
        color: ${getColor('iconSecondary')};
      }

      @media (${breakpoint.mdMax}) {
      width: 27px;
      height: 23px;
	  }
    }
  }
`;
