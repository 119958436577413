import { useEffect } from 'react';
import { getGtmPageStatusEvent, pushToDataLayer } from 'services/Gtm/functions';
import Heading from 'styles/typography/Heading';
import { InsideWrapper, Wrapper, Content, Link } from './Error500Styles';

const Error500 = () => {
  useEffect(() => {
    pushToDataLayer(getGtmPageStatusEvent(true, 500));
  }, []);

  return (
    <Wrapper>
      <InsideWrapper>
        <Heading
          as='h1'
          $fontFamily='graphikCond'
          $size={{ default: 'level1' }}
        >
          We could not locate the page you requested.
        </Heading>
        <Content>
          Our apologies. It may have expired or there could be a typo. We have been notified of the problem, and are
          working to resolve it. You can try refreshing the page or find what you need on our home page.
        </Content>
        <Link href='/'>Return to homepage</Link>
      </InsideWrapper>
    </Wrapper>
  );
};

export default Error500;
