import { PageAdConfig } from 'interfaces/ads/Ad';
import { ADS_ID, ADS_NAME } from 'services/Ad/config';

/**
 * Create a DFP ad path from an array of strings.
 *
 * @param {array} parts Strings to include as parts of ad path.
 */
const createPath = (parts: Array<string | number>): string => {
  // Create the path for this ad using channel, content type, and placement.
  let partEmpty = false;

  return parts
    .filter((pathPart: string | number) => {
      // Don't add anything else to the accumulator if any previous path part is empty.
      if (!pathPart || partEmpty) {
        partEmpty = true;
        return false;
      }

      return true;
    })
    .join('/');
};

export const createDefaultPath = (pageAdConfig: PageAdConfig, placementName: string) =>
  createPath([
    ADS_ID,
    pageAdConfig.defaultPath || ADS_NAME,
    pageAdConfig.invertChAndCtypeInPath ? pageAdConfig.ctype : pageAdConfig.ch,
    pageAdConfig.invertChAndCtypeInPath ? pageAdConfig.ch : pageAdConfig.ctype,
    placementName.toLowerCase(),
  ]);

// All company pages require a modiification for all the different kinds of ad.
// This is because the company page is a different path than the rest of the site
export const createLeaderboardPath = (pageAdConfig: PageAdConfig) => {
  const leaderboardPath =
    pageAdConfig.defaultPath === 'fortune/mobile' || pageAdConfig.defaultPath === 'fortune.well/mobile' ?
      'adhesion' :
      'leaderboard';

  const companyPath = pageAdConfig.ctype === 'company' ? 'company' : pageAdConfig.ch;
  return createPath([ADS_ID, pageAdConfig.defaultPath, leaderboardPath, companyPath]);
};

export const createRightRailPath = (pageAdConfig: PageAdConfig, placementName: string) => {
  const RRPath = placementName === 'RightRailFlex' ? 'rail_300x250' : 'rightrailflex';
  const companyPath = pageAdConfig.ctype === 'company' ? 'company' : pageAdConfig.ch;
  return createPath([ADS_ID, pageAdConfig.defaultPath, RRPath, companyPath]);
};

export const createRightRailArticlePath = (pageAdConfig: PageAdConfig, placementName: string) => {
  const RRArticlePath = placementName === 'RightRailFlex_Articles' ? 'rail_300xflex' : 'rightrailflex_articles';
  const companyPath = pageAdConfig.ctype === 'company' ? 'company' : pageAdConfig.ch;
  return createPath([ADS_ID, pageAdConfig.defaultPath, RRArticlePath, companyPath]);
};

export const createInstreamPath = (pageAdConfig: PageAdConfig, placementName: string) => {
  let inStreamAdType =
    placementName === 'InStream' || placementName === 'Homepage-InStream' || placementName === 'InContent' ?
      'incontent' :
      'below_article';

  if (pageAdConfig.defaultPath === 'fortune/desktop' && placementName === 'InContent') {
    inStreamAdType = 'incontent-970';
  }

  const pagePath = ['people', 'company'].includes(pageAdConfig.ctype) ? pageAdConfig.ctype : pageAdConfig.ch;

  return createPath([ADS_ID, pageAdConfig.defaultPath || ADS_NAME, inStreamAdType, pagePath]);
};

export const createAdTagPath = (channel: string, pathname: string) => {
  const handleChannel = () => {
    let ch = '';
    if (channel) {
      ch = channel.toLowerCase();
    } else if (pathname === '/fortune500/') {
      ch = 'fortune500';
    }
    return ch;
  };

  const verticals =
    pathname.match('/europe/') ||
    pathname.match('/asia/') ||
    pathname.match('/crypto/') ||
    pathname.match('/well/') ||
    pathname.match('/recommends/');

  const adTagPath = createPath([
    verticals ? `fortune.${verticals[0].replaceAll('/', '')}` : 'fortune',
    handleChannel(),
  ]);

  return adTagPath;
};
