import { Tinypass } from './entities/Tinypass';

function passwordReset(tp: Tinypass) {
  if (! tp.user.isUserValid()) {
    const tokenMatch =
      // eslint-disable-next-line no-restricted-globals
      location.search.match(/reset_token=([A-Za-z0-9]+)/);
    if (tokenMatch) {
      const token = tokenMatch[1];
      tp.pianoId.show({
        loggedIn: () => {
          // eslint-disable-next-line no-restricted-globals
          window.location.reload();
        },
        resetPasswordToken: token,
      });
    }
  }
}

export default passwordReset;
