import cookies from 'js-cookie';

const CookieService = (() => ({
  get: (name: string) => cookies.get(name),
  remove: (name: string, options: Object) => {
    cookies.set(name, '', {
      ...options,
      expires: Date.now(),
      path: '/',
    });
  },
  set: (name: string, value: string, options: Object) => {
    cookies.set(name, value, {
      ...options,
      path: '/',
    });
  },
}))();

export default CookieService;
