/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

const floatDir = (className?: string) => {
  if (!className) return 'none';
  if (className.includes('wrap-image-left')) return 'left';
  return 'right';
};

const marginDir = (className?: string) => {
  if (!className) return '0';
  if (className.includes('wrap-image-left')) return '16px 16px 4px 0';
  return '16px 0 0px 16px';
};

export const ImageTextContainer = styled.span<{ $className?: string; $width?: string; $height?: string }>`
    float: ${({ $className }) => floatDir($className)};
    width: ${({ $width }) => `${$width}px`};
    height: ${({ $height }) => `${$height}px`};
    margin: ${({ $className }) => marginDir($className)};
`;
