import styled from 'styled-components';
import { breakpoint } from 'styles/globals';
import { getColor, Theme } from 'styles/theme';

export const Wrapper = styled.div<{ theme: Theme }>`
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: ${getColor('surfaceWhite')};
  z-index: 5;

  @media(${breakpoint.lgMin}) {
    background-color: transparent;
  }
`;

export const ProgressBar = styled.div<{ theme: Theme; $percentage: number }>`
  height: 100%;
  width: ${({ $percentage }) => $percentage}%;
  background-color: ${getColor('surfaceBrand')}; 
  transition: width 0.2s ease; 
`;
