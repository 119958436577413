/* eslint-disable import/prefer-default-export */
import Script from 'next/script';

const PushlyScript: React.FC = () => (
  <>
    <Script
      src={`https://cdn.p-n.io/pushly-sdk.min.js?domain_key=${process.env.NEXT_PUBLIC_PUSHLY_DOMAIN_KEY}`}
      async
    />
    <Script
      async
      id='pushly-sdk-01'
      dangerouslySetInnerHTML={{
        __html: `
          window.PushlySDK = window.PushlySDK || [];
          function pushly() { window.PushlySDK.push(arguments) }
          pushly('load', {
            domainKey: '${process.env.NEXT_PUBLIC_PUSHLY_DOMAIN_KEY}',
            sw: '/assets/service-workers/worker.js'
          });
        `,
      }}
    />
  </>
);

export { PushlyScript };
