import * as S from './BackToFortuneStyles';

interface BackToFortuneProps {
  isInDrawerMenu?: boolean;
}

const BackToFortune = ({ isInDrawerMenu = false }: BackToFortuneProps) => (
  <S.Wrapper $isInDrawerMenu={isInDrawerMenu}>
    <S.Button href='/'>Go to Fortune.com</S.Button>
  </S.Wrapper>
);

export default BackToFortune;
