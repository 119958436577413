/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import getCommonTestId from 'constants/testsIds/common';

export const Logo = () => (
  <svg id='a' className='logo' aria-hidden='true' data-cy={getCommonTestId('FORTUNE_LOGO')} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 381.65 96.68'>
    <g id='b'>
      <polygon points='342.81 1.33 342.81 95.35 381.65 95.35 381.65 79.43 358.74 79.43 358.74 55.04 373.51 55.04 373.51 39.12 358.74 39.12 358.74 17.25 381.65 17.25 381.65 1.33 342.81 1.33'/><polygon points='311.29 1.33 311.29 52.6 295.18 1.33 279.25 1.33 279.25 95.35 295.18 95.35 295.18 49.29 309.58 95.35 327.22 95.35 327.22 1.33 311.29 1.33'/>
      <path d='M248.25,1.33V74.57c0,3.41-2.77,6.18-6.18,6.18s-6.18-2.77-6.18-6.18V1.33h-15.93V74.57c0,12.21,9.9,22.11,22.11,22.11s22.11-9.9,22.11-22.11V1.33h-15.93Z'/>
      <polygon points='206.83 1.33 162.7 1.33 162.7 17.25 176.8 17.25 176.8 95.35 192.73 95.35 192.73 17.25 206.83 17.25 206.83 1.33'/>
      <path d='M155.4,95.35h-16.46l-11.77-44.65s10.13-1.82,10.13-12.6v-14.69c0-3.41-2.76-6.17-6.16-6.17h-6.12V95.35h-15.93V1.33h22.04c11.88,0,21.54,9.38,22.05,21.12h.05v15.37h-.05c0,9.28-3.04,15.97-8.59,20.23l10.82,37.3Z'/>
      <path d='M49.13,22.11v52.47c0,12.21,9.9,22.11,22.11,22.11s22.11-9.9,22.11-22.11V22.1c0-12.21-9.89-22.1-22.1-22.1h0c-12.21,0-22.11,9.9-22.11,22.11m15.93,52.47V22.11c0-3.41,2.77-6.18,6.18-6.18h.02c3.4,0,6.16,2.76,6.16,6.16v52.48c0,3.41-2.77,6.18-6.18,6.18s-6.18-2.77-6.18-6.18'/>
      <polygon points='0 1.33 0 95.35 15.93 95.35 15.93 55.04 30.69 55.04 30.69 39.12 15.93 39.12 15.93 17.25 38.83 17.25 38.83 1.33 0 1.33'/>
    </g>
  </svg>
);
