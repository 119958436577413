import CookieService from 'utils/miscUtils/CookieService';

interface TrackResponse {
  token: string;
}

class IterableCookie {
  email: string;

  cookieName: string;

  constructor(email: string) {
    this.email = email;
    this.cookieName = 'iterable_jwt';
  }

  private request = async () => {
    const body = Buffer.from(this.email).toString('base64');
    const host = `${process.env.PAYMENT_API_URL || 'default'}${process.env.PAYMENT_API_TRACK_PATH || 'default'}`;

    const response = await fetch(host, {
      body,
      method: 'POST',
    });

    if (response.ok) {
      const data = (await response.json()) as TrackResponse;

      return data.token;
    }

    if (!response.ok) {
      throw new Error(await response.text());
    }

    return null;
  };

  set = (token: string): void => {
    const inTwoMinutes = new Date(new Date().getTime() + 2 * 60 * 1000);

    CookieService.set(this.cookieName, token, {
      expires: inTwoMinutes,
    });
  };

  get = async (): Promise<string> => {
    let token = CookieService.get(this.cookieName);

    if (!token) {
      const response = await this.request();

      if (response) {
        this.set(response);
        token = response;
      }
    }

    return token ?? 'failed';
  };
}

export default IterableCookie;
