import { AdConfig, AdSizeMappinConfig, AdTargetingConfig, ProgrammaticAdInfo } from 'interfaces/ads/Ad';
import CustomWindow from 'interfaces/Utils';
import { getQueryVariable } from 'utils/stringUtils';

declare let window: CustomWindow;

export function defineSlot(
  path: string,
  sizes: googletag.GeneralSize,
  id: string,
  sizeMappings: AdSizeMappinConfig[] | undefined,
  targeting: { [key: string]: string } = {},
) {
  if (googletag.defineSlot) {
    const slot = googletag.defineSlot(path, sizes, id);

    if (slot) {
      // https://developers.google.com/doubleclick-gpt/reference#googletag.SizeMappingBuilder
      // this overrides sizes defined above with responsive sizes
      const sizeMappingBuilder = googletag.sizeMapping();
      sizeMappings?.forEach((sm) => {
        const { viewport, size } = sm;
        sizeMappingBuilder.addSize(viewport.size, size);
      });
      const slotSizeMapping = sizeMappingBuilder.build();
      slot.defineSizeMapping(slotSizeMapping);

      slot.addService(googletag.pubads());
      // slot level targeting
      Object.keys(targeting).forEach((key) => {
        slot.setTargeting(key, targeting[key]);
      });
      return slot;
    }
  }

  return undefined;
}

export function configureTargeting(targeting: AdTargetingConfig) {
  const pubads = googletag.pubads();

  // to preserve bmb targeting value before clearing
  const bmbValue = pubads.getTargeting('bmb');

  // clear any targeting from previous page
  pubads.clearTargeting();

  // page level targeting
  Object.keys(targeting).forEach((key) => {
    if (targeting[key]) {
      pubads.setTargeting(key, targeting[key]);
    }
  });

  // restore bmb targeting value
  pubads.setTargeting('bmb', bmbValue);

  // testing targeting
  // Use dfpkey=wctest for regular ad
  // Use dfpkey=crown_test_dev for billboard ad
  const test = getQueryVariable('dfpkey');
  if (test !== '') {
    pubads.setTargeting('test', test);
  }
  if (process.env.STORYBOOK) {
    pubads.setTargeting('test', 'wctest');
  }
}

export function displayAdSlot(id: string) {
  googletag.display(id);
}

export function refreshAdSlotsAmazon(slots: googletag.Slot[]) {
  googletag.cmd.push(() => {
    window.apstag.setDisplayBids();
    googletag.pubads().refresh(slots, { changeCorrelator: false });
  });
}

export function refreshAdSlots(slots: googletag.Slot[]) {
  googletag.cmd.push(() => {
    googletag.pubads().refresh(slots, { changeCorrelator: false });
  });
}

export function configureAds(config: AdConfig) {
  const pubads = googletag.pubads();

  pubads.disableInitialLoad();
  pubads.enableSingleRequest();

  const lazyLoad = config.lazyLoad || false;
  if (lazyLoad && lazyLoad.enabled) {
    pubads.enableLazyLoad({
      fetchMarginPercent: lazyLoad.fetchMarginPercent || 100,
      mobileScaling: lazyLoad.mobileScaling || 2.0,
      renderMarginPercent: lazyLoad.renderMarginPercent || 100,
    });
  }
  if (config.nonPersonalAds) {
    // pubads.setRequestNonPersonalizedAds(1);
    pubads.setPrivacySettings({ nonPersonalizedAds: true });
  }

  googletag.enableServices();
}

export function gptQueue(fn: () => void, onError: (err: unknown) => void) {
  return promiseQueue(googletag.cmd, fn, onError);
}

export function unloadGPT() {
  if (googletag.destroySlots) googletag.destroySlots();
}

function promiseQueue(queue: googletag.CommandArray, fn: () => void, onError: (err: unknown) => void) {
  return new Promise((resolve) =>
    // eslint-disable-next-line no-promise-executor-return
    queue.push(() => {
      try {
        fn();
        resolve(undefined);
      } catch (error) {
        onError(error);
      }
    }));
}

export function registerAdSlotsRenderListener(callback: (event: googletag.events.SlotRenderEndedEvent) => void) {
  googletag.pubads().addEventListener('slotRenderEnded', (event) => {
    callback(event as ProgrammaticAdInfo);
  });
}
