const PAYMENT_API_URL = process.env.PAYMENT_API_URL || 'default';
const PAYMENT_API_HASH_SECURITY_KEY = process.env.PAYMENT_API_HASH_SECURITY_KEY as string;

const PAYMENT_DIMENSIONS_PATH = process.env.PAYMENT_API_DIMENSIONS_PATH || 'default';
const PAYMENT_DIMENSIONS_URL = `${PAYMENT_API_URL}${PAYMENT_DIMENSIONS_PATH}`;

const PAYMENT_GEO_PATH = process.env.PAYMENT_API_GEO_PATH || 'default';
const PAYMENT_GEO_URL = `${PAYMENT_API_URL}${PAYMENT_GEO_PATH}?pianoUID=`;

export { PAYMENT_API_HASH_SECURITY_KEY, PAYMENT_API_URL, PAYMENT_DIMENSIONS_URL, PAYMENT_GEO_URL };
