import getCommonTestId from 'constants/testsIds/common';
import { BaseAdConfig } from 'interfaces/ads/Ad';
import { RightRailSimpleAd } from './Styles';
import AdSlotToggle from './AdSlotToggle';

const RightRailSimple = ({ id, className, page, dataCy = getCommonTestId('RIGHT_RAIL_AD') }: BaseAdConfig) => (
  <RightRailSimpleAd
    className={`${className || ''}`}
    $page={page}
    data-cy={dataCy}
  >
    <AdSlotToggle
      placementName='RightRailFlex'
      index={id + 1}
      slotId={`RightRailFlex${id}`}
    />
  </RightRailSimpleAd>
);

export default RightRailSimple;
