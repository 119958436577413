import Link from 'components/Globals/Base/Link';
import { NavLink, NavMenu } from 'interfaces/navigation/Footer';
import { FooterNavigationMenuWrapper, MenuItemWrapper } from './NavigationLinksStyle';

export interface NavigationLinksProps {
  navMenu: NavMenu;
}

const NavigationLinks = (props: NavigationLinksProps) => {
  const {
    navMenu: { links },
  } = props;

  return (
    <FooterNavigationMenuWrapper>
      {links.map((link: NavLink) => (
        <MenuItemWrapper key={link.label}>
          <Link
            href={link.url}
            trackingData={{
              contentPlacementCD: 'footer',
              eventAction: 'footer links',
              eventCategory: 'navigation',
              eventLabel: link.label,
            }}
            ariaLabel={`footer link to ${link.label}`}
          >
            {link.label}
          </Link>
        </MenuItemWrapper>
      ))}
    </FooterNavigationMenuWrapper>
  );
};

export default NavigationLinks;
