import styled from 'styled-components';
import { breakpoint } from 'styles/globals';
import { getColor, Theme } from 'styles/theme';

export const SlideNumber = styled.div`
  display: flex;
  justify-content: right;

  .maximize {
    cursor: pointer;
    margin-left: 4px;
  }
`;

export const GalleryWrapper = styled.div`
  padding: 0 20px;
  margin: 0 auto 16px;
  max-width: 800px;
  @media (${breakpoint.mdMin}) {
    margin: 0 auto 24px;
  }

  .slick-prev, .slick-next {
    top: 40%;

    &.slick-disabled {
      opacity: 0.3;
    }
  }

  .slick-prev {
    left: -35px;
  }


  .gallery {
    padding: 0 24px;
  }
`;

export const FullScreenGalleryWrapper = styled.div<{ theme: Theme }>`
  position: fixed;
  z-index: 10000;
  background-color: ${getColor('backgroundTertiary')}D9;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${SlideNumber} {
    color: ${getColor('textWhite')};
    max-width: 650px;
    margin: 0 auto;
    
    .maximize {
      display: none;
    }
  }

  .gallery {
    margin: 0 auto 16px;
    max-width: 800px;
    width: calc(100% - 48px);
    padding: 0 24px;
    color: ${getColor('textWhite')};
    @media (${breakpoint.mdMin}) {
      margin: 0 auto 24px;
    }
  }

  .slick-prev, .slick-next {
    top: 40%;

    span {
      border-color: ${getColor('textWhite')};
    }

    &.slick-disabled {
      opacity: 0.3;
    }

    &:before {
      content: '';
    }
  }

  .slick-prev {
    left: -35px;
  }
  
  .close {
    position: absolute;
    cursor: pointer;
    right: 28px;
    top: 5vh;
    width: 28px;
    svg {
      fill: ${getColor('textWhite')};
    }
  }
`;
