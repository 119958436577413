import React from 'react';
import { BaseAdConfig } from 'interfaces/ads/Ad';
import getCommonTestId from 'constants/testsIds/common';
import { RightRailArticlesStickyContainer, RightRailArticlesStickyAd } from './Styles';
import AdSlotToggle from './AdSlotToggle';

const RightRailFlexArticles: React.FC<BaseAdConfig> = ({ id, stickyTop, dataCy = '' }: BaseAdConfig) => (
  <RightRailArticlesStickyContainer data-cy={getCommonTestId('RIGHT_RAIL_STICKY_AD')}>
    <RightRailArticlesStickyAd
      $stickyTop={stickyTop}
      data-cy={dataCy}
    >
      <AdSlotToggle
        placementName='RightRailFlex_Articles'
        index={id + 1}
        slotId={`RightRailFlex_Articles${id}`}
      />
    </RightRailArticlesStickyAd>
  </RightRailArticlesStickyContainer>
);

export default RightRailFlexArticles;
