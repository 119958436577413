import React, { useContext } from 'react';
import { AdContext } from 'services/Ad';
import { PageType } from 'interfaces/content/articles/Post';
import { AdSlotInterface } from 'interfaces/ads/AdSlot';
import { PlacementName } from 'interfaces/ads/Ad';
import { IStyledComponent } from 'styled-components';
import AdSlot from './AdSlot';

export interface AdSlotToggleProps {
  component?: IStyledComponent<'web', {}>;
  // this is optional for now because we are enabling in-house ads only for articles; should transform into a
  // mandatory property in the future
  pageType?: PageType;
  placementName: PlacementName;
  index: number;
  slotId: string;
  dataCy?: string;
  delay?: number;
  root?: string;
  hideGAM?: boolean;
  personalized?: boolean;
}

const AdSlotToggle: React.FC<AdSlotToggleProps> = ({
  component,
  pageType = PageType.NO_TYPE,
  placementName,
  index,
  slotId,
  dataCy = '',
  delay = 0,
  hideGAM,
  personalized,
}: AdSlotInterface) => {
  const adContext = useContext(AdContext);

  const { adsEnabled } = adContext;

  return adsEnabled ? (
    <AdSlot
      adContext={adContext}
      component={component}
      pageType={pageType}
      placementName={placementName}
      index={index}
      slotId={slotId}
      delay={delay}
      dataCy={dataCy}
      hideGAM={hideGAM}
      personalized={personalized}
    />
  ) : null;
};

export default AdSlotToggle;
