/* eslint-disable camelcase */
import { PAYMENT_DIMENSIONS_URL } from 'lib/paymentApi/constants';

interface DimensionsResponse {
  payment_method: string;
  billing_country: string;
}

const paymentDimensions = async (rid: string, uid: string): Promise<DimensionsResponse> =>
  fetch(`${PAYMENT_DIMENSIONS_URL}?rid=${rid}&uid=${uid}`)
    .then((res) => {
      if (!res.ok) {
        throw new Error('Unauthorized');
      } else {
        return res.json();
      }
    })
    .then((data: DimensionsResponse) => data);

export default paymentDimensions;
