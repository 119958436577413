import * as S from 'components/TopicBasedNavigation/SubmenuV2/SubmenuStyles';
import SubMenuItem from 'components/TopicBasedNavigation/SubmenuV2/SubmenuItem';
import TopicItem, { TopicSubmenuType } from 'components/TopicBasedNavigation/SubmenuV2/TopicItem';
import getTrackingData from 'components/TopicBasedNavigation/SubmenuV2/utils';
import { MenuItemProps } from 'interfaces/navigation/Header';
import { Dispatch, SetStateAction } from 'react';
import ExploreAllLink from 'components/TopicBasedNavigation/SubmenuV2/ExploreAllLink';

interface SubCategoryItemProps {
  currentUrl: string;
  item: MenuItemProps;
  parentLabels: string[];
  activeSubcategoryID: string | undefined;
  setActiveSubCategoryID: Dispatch<SetStateAction<string | undefined>>;
}

const SubCategoryItem = ({
  item,
  parentLabels,
  activeSubcategoryID,
  setActiveSubCategoryID,
  currentUrl,
}: SubCategoryItemProps) => {
  const isActive = item.id === activeSubcategoryID;
  const columns = item.subMenu;
  const hasLastColumn = columns?.length === 3;
  const exploreAllUrl = item.url;
  const labels = [...parentLabels, item.label];
  const TOP_PICKS_COLUMN_NAME = 'toppicks';

  return (
    <>
      <SubMenuItem
        item={item}
        type='subCategory'
        trackingData={getTrackingData(labels)}
        onMouseEnter={() => setActiveSubCategoryID(item.id)}
        onFocus={() => setActiveSubCategoryID(item.id)}
        isActive={isActive}
        isSelected={currentUrl.includes(item.url)}
      />
      <S.RightMenu $isVisible={isActive}>
        {columns?.map((column, columnNr) => {
          const topicsList = column.subMenu;
          const isLastColumn = columnNr === 2;
          const showTopPicks = column.label === TOP_PICKS_COLUMN_NAME;

          return (
            <S.Column key={column.id}>
              {topicsList?.map((topic) => (
                <TopicItem
                  key={topic.id}
                  item={topic}
                  submenuType={showTopPicks ? TopicSubmenuType.TOP_PICKS : TopicSubmenuType.ARTICLES}
                  parentLabels={labels}
                  isSelected={currentUrl.includes(topic.url)}
                />
              ))}
              {isLastColumn && <ExploreAllLink exploreAllUrl={exploreAllUrl} />}
            </S.Column>
          );
        })}
      </S.RightMenu>
      {!hasLastColumn && (
        <ExploreAllLink
          exploreAllUrl={exploreAllUrl}
          isVisible={isActive}
          isOutsideColumn
        />
      )}
    </>
  );
};

export default SubCategoryItem;
