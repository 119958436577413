import styled, { css, RuleSet } from 'styled-components';
import { breakpoint } from 'styles/globals';
import { Theme } from 'styles/theme';
import { BreakpointValues, FontFamilyAndSize, FontSizes, ThemeProps } from 'styles/theme/types';
import orderBreakpoints from 'utils/miscUtils/orderBreakpoints';

type Props = FontFamilyAndSize & { theme: Theme };

const getHeading = (theme: Props['theme'], size: Props['$size'], fontFamily: Props['$fontFamily']): RuleSet => {
  const { defaultHeadingStyles, headingFonts } = theme.typography.headings;
  const fontFamilyVariants = headingFonts[fontFamily];
  const fontFamilyCustomStyles = fontFamilyVariants?.custom;
  const { default: defaultSize, ...customSizes } = size || {};
  const breakpoints = orderBreakpoints(customSizes);
  return css`
    ${defaultHeadingStyles}
    ${fontFamilyCustomStyles}
    font-size: ${fontFamilyVariants?.sizes[defaultSize]};
    ${Object.keys(breakpoints).map(
    (bkp: string) => css`
        @media (${breakpoint[bkp]}) {
          font-size: ${fontFamilyVariants?.sizes[breakpoints[bkp as BreakpointValues] as keyof FontSizes]};
        }
    `,
  )}
  `;
};
export const getHeadingStyle =
  (size: Props['$size'], fontFamily: Props['$fontFamily']) =>
    ({ theme }: { theme: ThemeProps }) =>
      getHeading(theme, size, fontFamily);

export const Heading = styled.h2<Props>`
  ${({ theme, $size, $fontFamily }) => getHeading(theme, $size, $fontFamily)};
`;
export default Heading;
