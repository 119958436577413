import { EventData } from 'services/Gtm/entities';

const getTrackingData = (labels: string[]): EventData => {
  const level = labels.length - 1;
  return {
    contentPlacementCD: 'topic navigation',
    eventAction: `topic navigation - level ${level}`,
    eventCategory: 'navigation',
    eventLabel: labels.join(' > '),
  };
};

export default getTrackingData;
