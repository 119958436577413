import RawHtml from 'utils/miscUtils/rawHtml';
import { FooterDisclaimerWrapper } from './FooterStyles';

export interface FooterDisclaimerProps {
  disclaimer: string;
}

const FooterDisclaimer = (props: FooterDisclaimerProps) => {
  const { disclaimer } = props;

  return <FooterDisclaimerWrapper>{RawHtml({ html: disclaimer })}</FooterDisclaimerWrapper>;
};

export default FooterDisclaimer;
