import { useRef, ReactElement, ChangeEvent, ReactNode, KeyboardEvent } from 'react';
import { EventData } from 'services/Gtm/entities';
import { trackEvent } from 'services/Gtm/functions';
import * as S from './CheckboxStyles';

export interface CheckboxProps {
  name: string;
  value?: boolean;
  onChange: (value: boolean, type?: string, name?: string) => void;
  required?: boolean;
  disabled?: boolean;
  children?: ReactElement | ReactElement[] | string | ReactNode;
  className?: string;
  trackingData?: EventData;
  dataCy?: string;
  defaultChecked?: boolean;
  hasBrandColor?: boolean;
}

const Checkbox = ({
  name,
  value,
  onChange,
  required = false,
  disabled = false,
  children,
  className = '',
  trackingData,
  dataCy,
  defaultChecked,
  hasBrandColor = false,
}: CheckboxProps) => {
  const checkboxRef = useRef<HTMLInputElement>(null);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (trackEvent && trackingData) trackEvent(trackingData);
    onChange(event.target.checked);
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && checkboxRef.current) {
      checkboxRef.current.click();
    }
  };

  return (
    <S.CheckboxWrapper
      data-cy={dataCy}
      className={`${className} ${disabled ? 'disabled' : ''}`}
      onKeyPress={handleKeyPress}
      tabIndex={0}
    >
      {children && (
        <S.Label
          htmlFor={name}
          className='label'
        >
          <S.CheckboxInput
            ref={checkboxRef}
            checked={value}
            defaultChecked={defaultChecked}
            type='checkbox'
            id={name}
            onChange={handleChange}
            disabled={disabled}
            $hasBrandColor={hasBrandColor}
          />
          <S.Checkmark className={`check-mark ${disabled ? 'disabled' : ''}`} />
          {children} {required && <span className='required'>*</span>}
        </S.Label>
      )}
    </S.CheckboxWrapper>
  );
};

export default Checkbox;
