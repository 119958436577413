/* eslint-disable import/prefer-default-export */
import Script from 'next/script';
import { useRouter } from 'next/router';
import CustomWindow from 'interfaces/Utils';
import { useEffect, useState } from 'react';

declare let window: CustomWindow;

const QuerylyScript: React.FC = () => {
  const router = useRouter();
  const isAdvancedSearch = router.pathname === '/advanced-search';
  const [loaded, setLoaded] = useState(false);
  const [advancedSearchScript, setAdvancedSearchScript] = useState(false);

  useEffect(() => {
    if (loaded && isAdvancedSearch) {
      setAdvancedSearchScript(true);
    }
  }, [loaded, isAdvancedSearch]);

  return (
    <>
      <Script
        src='https://www.queryly.com/js/queryly.v4.min.js'
        strategy='afterInteractive'
        onLoad={() => {
          window.queryly.init(process.env.QUERYLY_ID!, document.querySelectorAll('#content,header,#root,footer'), {
            uiversion: 1,
          });
          setLoaded(true);
        }}
      />
      {advancedSearchScript ? <Script src='https://www.queryly.com/js/fortune-advanced-search.js' /> : null}
    </>
  );
};

export { QuerylyScript };
