import styled from 'styled-components';
import { breakpoint } from 'styles/globals';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;

 .video-placeholder {
  @media (${breakpoint.mdMin}) {
    min-height: 390px;
  }
 }
`;

export default Wrapper;
