import { ConversionResponse } from 'services/Piano/entities/PianoCallbackParams';
import paymentDimensions from 'paymentApi/paymentDimensions';
import { Subscriber } from 'services/Subscriber';
import CustomWindow from 'interfaces/Utils';
import { DataLayerObject, DataLayerPageStatus, EventData, PageViewDataLayerObject, PushableObject } from './entities';

declare let window: CustomWindow;

const pushToDataLayer = (obj: PushableObject) => {
  window.dataLayer.push(obj);
};

const trackEvent = (data: EventData) => {
  const trackingData: DataLayerObject = {
    event: 'eventTracker',
    ...data,
    eventAction: data.eventAction?.toLowerCase() || '',
    eventLabel: data.eventLabel?.toLowerCase() || '',
    nonInteraction: 0,
  };

  if (data.eventCategory) {
    trackingData.eventCategory = data.eventCategory?.toLowerCase() || '';
  }

  if (data.contentPlacementCD) {
    trackingData.contentPlacementCD = data.contentPlacementCD?.toLowerCase() || '';
  }

  if (data.CompanyCD) {
    trackingData.CompanyCD = data.CompanyCD?.toLowerCase() || '';
  }

  if (data.rankingTitleCD) {
    trackingData.rankingTitleCD = data.rankingTitleCD?.toLowerCase() || '';
  }

  if (data.userIDCD) {
    trackingData.userIDCD = data.userIDCD?.toLowerCase() || '';
  }

  const pageViewData = window.dataLayer.find(
    (item) => (item as PageViewDataLayerObject).event === 'fortune.historyChange',
  );
  const cmsCategoryCD = (pageViewData as PageViewDataLayerObject)?.cmsCategoryCD?.toLowerCase() || '';

  pushToDataLayer({ ...trackingData, cmsCategoryCD });
};

const GTMPageView = (data: PageViewDataLayerObject) => {
  const trackingData: PageViewDataLayerObject = {
    event: 'fortune.historyChange',
    ...data,
  };
  pushToDataLayer(trackingData);
};

export const getGtmPageStatusEvent = (
  isError: boolean,
  errorCode?: number,
  errorDetail?: string,
): DataLayerPageStatus => ({
  errorCode: errorCode ?? 0,
  errorDetail: errorDetail ?? '',
  event: `Page Status - ${isError ? `Error (${errorCode})` : 'Init'}`,
  isError,
});

const gtmPurchaseTracker = async ({
  rid,
  uid,
  termConversionId,
  chargeAmount,
  chargeCurrency,
  termId,
}: ConversionResponse) => {
  pushToDataLayer({ ecommerce: null }); // Clear the previous ecommerce object.

  const data = await paymentDimensions(rid, uid);

  pushToDataLayer({
    billingCountryCD: data.billing_country,
    ecommerce: {
      purchase: {
        actionField: {
          affiliation: 'Fortune Media',
          currency: chargeCurrency,
          id: termConversionId,
          revenue: chargeAmount,
        },
        products: [
          {
            category: 'Fortune Subscription',
            currency: chargeCurrency,
            id: termConversionId,
            name: termId,
            price: chargeAmount,
          },
        ],
      },
    },
    event: 'eec.purchase',
    paymentMethodCD: data.payment_method,
  });
};

const subscriberPush = (subscriber: Subscriber) => {
  const digiohObj = {
    user: {
      accessLevel: null,
      userLoggedIn: !!subscriber.isLoggedIn,
    },
  };
  pushToDataLayer(digiohObj);
};

const userPush = (isloggedin: boolean) => {
  const user = { loginStatusCD: isloggedin ? 'logged-in' : 'guest' };
  pushToDataLayer(user);
};

export { trackEvent, pushToDataLayer, GTMPageView, gtmPurchaseTracker, subscriberPush, userPush };
