import { styled } from 'styled-components';
import { color, font } from 'styles/globals';

export const StickyFooterFormContainer = styled.div`
  font-family: ${font.graphikCond};

  a {
    text-decoration: underline;
  }

  button {
    margin: 8px;
  }

  & .consent {
    font-size: 12px;
    line-height: 14px;
    padding: 8px 48px;
  }

  & .marketing-consent {

    label > span.check-mark {
      border: 3px solid ${color.secondaryLightGray};
      height: 24px;
      min-width: 24px;

      &::after {
        top: 2px;
        left: 6px;
      }
    }

    p {
      text-align: left;
      font-size: 12px;
      line-height: 14px;
    }
  }
`;

export const InputWrapper = styled.div`
  margin: 8px 16px;

  & .email-input {
    height: 40px;
    width: 100%;
  }
`;
