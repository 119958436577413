import CookieService from 'utils/miscUtils/CookieService';
import { GET_MY_ACCOUNT_DATA_URL, PERSONALIZATION_API_KEY } from './constants';
import { MyAccountDataResponse } from './entities/MyAccountDataResponse';

const getMyAccountData = async (pianoId: string) => {
  const urlWithHash = `${GET_MY_ACCOUNT_DATA_URL}?pianoUid=${pianoId}`;
  const pianoCookie = CookieService.get('__utp') || '';
  const response = await fetch(urlWithHash, {
    headers: {
      Authorization: pianoCookie,
      'x-api-key': PERSONALIZATION_API_KEY,
    },
    method: 'GET',
  });

  if (!response.ok) throw new Error(`Request failed with status ${response.status}`);

  return (await response.json()) as MyAccountDataResponse;
};

export default getMyAccountData;
