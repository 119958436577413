/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState, useMemo, createContext } from 'react';

interface NoticeProps {
  children: JSX.Element;
}

interface NoticeContextProps {
  notice?: {
    type?: 'success' | 'warning' | 'error';
    message?: string;
  };
  setNotification?: (type: string, message: string) => void;
  hideNotification?: () => void;
  noticeVisible?: boolean;
  setNoticeVisible?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NoticeContext = createContext<NoticeContextProps | undefined>(undefined);

const NoticeProvider: React.FC<NoticeProps> = ({ children }) => {
  const [notice, setNotice] = useState({});
  const [noticeVisible, setNoticeVisible] = useState(false);
  const [notifType, setNotifType] = useState('');

  const setNotification = (type: string, message: string) => {
    if (!noticeVisible) {
      setNoticeVisible(true);
      setNotice({ message, type });
      setNotifType(type);
    }
  };

  const hideNotification = () => {
    setNotice({});
    setNoticeVisible(false);
  };

  useEffect(() => {
    if (noticeVisible && notifType !== 'error') {
      const timer = setTimeout(() => hideNotification(), 5000);
      return () => clearTimeout(timer);
    }
    return () => {};
  }, [noticeVisible]);

  const memoNoticeValue = useMemo(
    () => ({
      hideNotification,
      notice,
      noticeVisible,
      setNoticeVisible,
      setNotification,
    }),
    [notice, noticeVisible],
  );

  return <NoticeContext.Provider value={memoNoticeValue}>{children}</NoticeContext.Provider>;
};

export default NoticeProvider;
