import { MenuItemProps } from 'interfaces/navigation/Header';
import { EventData } from 'services/Gtm/entities';
import cx from 'classnames';
import * as S from './SubmenuStyles';

type SubMenuItemProps = {
  item: MenuItemProps;
  isActive?: boolean;
  onMouseEnter?: () => void;
  type: 'subCategory' | 'topic' | 'article';
  trackingData: EventData;
  onFocus?: () => void;
  isSelected?: boolean;
};

const SubMenuItem = (props: SubMenuItemProps) => {
  const { item, onMouseEnter, type, isActive, isSelected, trackingData, onFocus } = props;
  if (!item.label) return null;

  const variant = type === 'article' ? 'small' : 'big';

  return (
    <S.ItemWrapper className={cx(type, { isSelected })}>
      {item.url ? (
        <S.ItemLink
          href={item.url}
          ariaLabel={`Go to ${item.label}`}
          type='primary'
          onMouseEnter={onMouseEnter}
          trackingData={trackingData}
          onFocus={onFocus}
          $variant={variant}
          $isActive={isActive}
        >
          {item.label}
        </S.ItemLink>
      ) : (
        <S.ItemNoLink
          $variant={variant}
          $isActive={isActive}
          onMouseEnter={onMouseEnter}
          onFocus={onFocus}
          tabIndex={0}
        >
          {item.label}
        </S.ItemNoLink>
      )}
    </S.ItemWrapper>
  );
};

export default SubMenuItem;
