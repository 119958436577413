import Link from 'components/Globals/Base/Link';
import SvgIcon from 'components/Globals/Base/SvgIcon';
import * as S from './SocialListStyles';

export enum SocialListStyle {
  Square = 0,
  Round = 1,
}

export interface SocialItem {
  type: 'facebook' | 'instagram' | 'twitter' | 'linkedin' | 'pinterest';
  url: string;
}

interface SocialItemProps {
  socialItem: SocialItem;
  type: SocialListStyle;
}

const SocialItem = (props: SocialItemProps) => {
  const { socialItem, type } = props;

  let icon;

  switch (socialItem.type) {
    case 'facebook':
      icon = type === SocialListStyle.Round ? <SvgIcon name='Facebook' /> : <SvgIcon name='FacebookSquare' />;
      break;
    case 'twitter':
      icon = <SvgIcon name='Twitter' />;
      break;
    case 'linkedin':
      icon = <SvgIcon name='LinkedIn' />;
      break;
    case 'instagram':
      icon = <SvgIcon name='Instagram' />;
      break;
    case 'pinterest':
      icon = <SvgIcon name='Pinterest' />;
      break;
    default:
      break;
  }

  const content = (
    <Link
      href={socialItem.url}
      ariaLabel={`Go to our ${socialItem.type} profile`}
      dataCy={`${socialItem.type}-icon`}
      trackingData={{
        contentPlacementCD: 'footer',
        eventAction: 'social icon',
        eventCategory: 'social platform',
        eventLabel: socialItem.type,
      }}
    >
      {icon}
    </Link>
  );

  if (type === SocialListStyle.Square) {
    return <S.SquareLink>{content}</S.SquareLink>;
  }

  return <S.RoundLink>{content}</S.RoundLink>;
};

export interface SocialListProps {
  items: SocialItem[];
  style?: SocialListStyle;
}

export const SocialList = (props: SocialListProps) => {
  const { items, style = SocialListStyle.Round } = props;

  const content = items ?
    items.map((item) => (
      <SocialItem
        type={style}
        key={item.type}
        socialItem={item}
      />
    )) :
    null;

  if (style === SocialListStyle.Square) {
    return <S.SquareList>{content || {}}</S.SquareList>;
  }

  return <S.RoundList>{content || {}}</S.RoundList>;
};
