import { UserPaywallContext } from 'providers/PaywallProvider';
import { useContext } from 'react';

interface NativoToutProps {
  featuredMediaType: string;
}

const NativoTout = (props: NativoToutProps) => {
  const { featuredMediaType } = props;

  const { paywallCheck } = useContext(UserPaywallContext).paywallState;

  const isVideoMedia = ['fortune_video', 'stn_video_media'].includes(featuredMediaType);

  const nativoFeaturedMediaTypeClassName = isVideoMedia ? 'nativo-tout--in-content-video' : 'nativo-tout--in-content';

  if (paywallCheck === 'no-paywall')
    return (
      <div
        className={`nativo-tout ${nativoFeaturedMediaTypeClassName}`}
        data-cy='nativoTout'
      />
    );

  return <div data-cy='nativoTout' />;
};

export default NativoTout;
