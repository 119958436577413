/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */

export interface BarsProps {
  className?: string;
}

export const Bars = ({ className }: BarsProps) => (
  <svg className={className} width='24' height='16' viewBox='0 0 24 16' fill='#000' xmlns='http://www.w3.org/2000/svg'>
    <rect width='24' height='2.66667'/>
    <rect y='6.66675' width='24' height='2.66667'/>
    <rect y='13.3333' width='24' height='2.66667'/>
  </svg>
);
