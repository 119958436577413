import { useContext, useEffect, useState } from 'react';
import { UserContext } from 'providers/UserProvider';
import { AccessValues } from 'lib/personalizationApi/entities/MyAccountDataResponse';
import {
  CloseButton,
  ExpiredContent,
  ExpiredCopy,
  ExpiredDangerIcon,
  ExpiredSubAlertWrapper,
  RenewLink,
} from './ExpiredSubAlertStyles';

const ExpiredSubAlert = () => {
  const [display, setDisplay] = useState(false);

  const {
    userState: { myAccountData },
  } = useContext(UserContext);

  useEffect(() => {
    if (
      myAccountData &&
      myAccountData.accesses.success &&
      (myAccountData.accesses.value as AccessValues).expiredAlert
    ) {
      const closedExpiredAlert = localStorage.getItem('closedExpiredAlert');
      if (closedExpiredAlert !== 'true') {
        setDisplay(true);
      }
    } else {
      setDisplay(false);
    }
  }, [myAccountData]);

  const handleClose = () => {
    setDisplay(false);
    localStorage.setItem('closedExpiredAlert', 'true');
  };

  return display ? (
    <ExpiredSubAlertWrapper>
      <ExpiredContent>
        <ExpiredDangerIcon />
        <ExpiredCopy>
          Your Subscription is expired. You can renew it <RenewLink href='/subscribe'>here</RenewLink>.
        </ExpiredCopy>
      </ExpiredContent>
      <CloseButton onClick={handleClose}>X</CloseButton>
    </ExpiredSubAlertWrapper>
  ) : null;
};

export default ExpiredSubAlert;
