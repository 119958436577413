import * as yup from 'yup';
import { emailSchema } from 'components/MyAccount/PersonalInfo/PersonalInfoForm/validations';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'components/Globals/Base/Button';
import RawHtml from 'utils/miscUtils/rawHtml';
import Input from 'components/Globals/Base/Input';
import Checkbox from 'components/Globals/Base/Checkbox';
import * as S from './StickyFooterFormStyles';

interface StickyFooterFormProps {
  consent: string;
  marketingConsent: string;
  submitButton: string;
}

const StickyFooterForm = ({ consent, marketingConsent, submitButton }: StickyFooterFormProps) => {
  // TODO: geo location detection
  const isGdpr = true;
  const stickyFooterFormSchema = isGdpr ?
    {
      emailSchema,
      marketingConsent: yup.boolean().oneOf([true], 'Required'),
    } :
    emailSchema;
  const {
    formState: { errors },
    control,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(stickyFooterFormSchema),
  });

  const onSubmit = (data: any) => {
    console.info(data);
  };

  return (
    <S.StickyFooterFormContainer>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <S.InputWrapper>
          <Controller
            name='email'
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                type='email'
                placeholder='EMAIL'
                className='email-input'
                aria-label='email'
              />
            )}
          />
        </S.InputWrapper>
        {errors.email && <p>{errors.email.message}</p>}
        <div className='consent'>{consent && RawHtml({ html: consent })}</div>
        {isGdpr ? (
          <Controller
            name='marketingConsent'
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field}
                name='marketingConsent'
                className='marketing-consent'
                onChange={(value) => console.info(value)}
              >
                {marketingConsent && RawHtml({ html: marketingConsent })}
              </Checkbox>
            )}
          />
        ) : null}
        <Button
          type='submit'
          className='btn btn-primary'
          hasRightArrow={false}
        >
          {submitButton}
        </Button>
      </form>
    </S.StickyFooterFormContainer>
  );
};

export default StickyFooterForm;
