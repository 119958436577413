import React from 'react';
import { UICrashFallbackContainer } from './UICrashFallbackStyles';

interface UICrashFallbackProps {
  message?: string;
}

const UICrashFallback: React.FC<UICrashFallbackProps> = ({ message }) => (
  <UICrashFallbackContainer>
    <b>⚠</b>
    {message}
  </UICrashFallbackContainer>
);

export default UICrashFallback;
