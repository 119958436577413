import React from 'react';
import { RightRailFlexArticlesListConfig } from 'interfaces/ads/Ad';
import RightRailFlexArticles from './RightRailFlexArticles';
import { RightRailMultipleContainer } from './Styles';

const RightRailFlexArticlesList: React.FC<RightRailFlexArticlesListConfig> = ({
  count,
  marginTop,
}: RightRailFlexArticlesListConfig) => (
  <RightRailMultipleContainer $marginTop={marginTop}>
    {Array.from({ length: count }, (e, i) => (
      <RightRailFlexArticles
        key={`right-rail-article-${i}`}
        id={i}
      />
    ))}
  </RightRailMultipleContainer>
);

export default RightRailFlexArticlesList;
