import React, { KeyboardEvent, MutableRefObject, useRef, useState } from 'react';
import useClickOutside from 'hooks/useClickOutside';
import * as S from './DropdownStyles';

export interface Option {
  value: string;
  label: string;
}

export interface Props {
  id?: string;
  isExpanded?: boolean;
  selected?: string | number;
  onBtnClick?: () => void;
  onOptionClick: (value: string, index: number) => void;
  className?: string;
  label?: string;
  assistiveText?: string;
  placeholder?: string;
  list: Option[] | string[];
  required?: boolean;
  disabled?: boolean;
  size?: 'large' | 'small';
  dataCy?: string;
  hasDisclaimer?: boolean;
}

export interface DropdownListProps {
  isOpen: boolean;
  onOptionClick: (option: Option, index: number) => void;
  list: Option[] | string[];
  firstItemRef: MutableRefObject<HTMLLIElement | null>;
  lastItemRef: MutableRefObject<HTMLLIElement | null>;
  inputRef?: MutableRefObject<HTMLDivElement | null>;
  selected?: string | number;
  disabled?: boolean;
  dataCy?: string;
}

export const DropdownList = ({
  isOpen,
  onOptionClick,
  list,
  inputRef,
  firstItemRef,
  lastItemRef,
  disabled,
  dataCy,
  selected,
}: DropdownListProps) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const liRef = useRef<{ [key: number]: HTMLLIElement | null }>({});

  const setIndex = (index: number) => {
    const tabLi = liRef?.current[index];
    if (tabLi) {
      tabLi.focus();
      setSelectedIndex(index);
    }
  };

  const onKeyDown = (event: KeyboardEvent, option: Option, index: number) => {
    const isFirstItem = selectedIndex === 0;
    const isLastItem = selectedIndex === list.length - 1;

    const nextItemIndex = isLastItem ? 0 : selectedIndex + 1;
    const prevItemIndex = isFirstItem ? list.length - 1 : selectedIndex - 1;

    const nextItem = () => {
      if (isLastItem && inputRef?.current) {
        inputRef.current.focus();
      } else {
        setIndex(nextItemIndex);
      }
    };
    const prevItem = () => {
      if (isFirstItem && inputRef?.current) {
        inputRef.current.focus();
      } else {
        setIndex(prevItemIndex);
      }
    };
    const selectItem = () => {
      inputRef?.current?.focus();
      onOptionClick(option, index);
    };

    const keyMap = {
      ArrowDown: nextItem,
      ArrowUp: prevItem,
      Enter: selectItem,
    } as { [key: string]: () => void };

    const action = keyMap[event.key];

    if (action) {
      event.preventDefault();
      action();
    }
  };

  return list.length > 0 ? (
    <S.List
      role='listbox'
      aria-labelledby='listbox_level'
      id='listbox'
      tabIndex={-1}
      className={isOpen && !disabled ? 'active' : ''}
      data-cy={`${dataCy}-list`}
    >
      {list.map((item, index) => {
        const isObject = typeof item !== 'string';
        const option: Option = isObject ? item : { label: item, value: item };
        const isSelected = selected === option.value;
        const showItem = Boolean(option.value && option.label);

        return showItem ? (
          <S.ListItem
            role='option'
            aria-selected={isSelected}
            /* eslint-disable-next-line react/no-array-index-key */
            key={`${option.value}-${index}`}
            tabIndex={-1}
            onFocus={() => setSelectedIndex(index)}
            onKeyDown={(event) => onKeyDown(event, option, index)}
            onClick={() => {
              onOptionClick(option, index);
            }}
            ref={(element) => {
              liRef.current[index] = element;
              if (index === 0) {
                // eslint-disable-next-line no-param-reassign
                firstItemRef.current = element;
              }
              if (index === list.length - 1) {
                // eslint-disable-next-line no-param-reassign
                lastItemRef.current = element;
              }
            }}
            $isOption
            $isSelected={isSelected}
          >
            {option.label}
          </S.ListItem>
        ) : null;
      })}
    </S.List>
  ) : (
    <S.List
      role='presentation'
      className={isOpen && !disabled ? 'active' : ''}
      data-cy={`${dataCy}-list`}
    >
      <S.ListItem
        role='presentation'
        $isOption={false}
      >
        No options
      </S.ListItem>
    </S.List>
  );
};

const Dropdown = ({
  id = '',
  label = '',
  assistiveText = '',
  selected,
  placeholder,
  isExpanded = false,
  onBtnClick,
  onOptionClick,
  className = '',
  required = false,
  size = 'large',
  disabled = false,
  list,
  dataCy = '',
  hasDisclaimer = false,
}: Props) => {
  const [dropdownOpened, setDropdownOpened] = useState(isExpanded);
  const firstItemRef = useRef<HTMLLIElement>(null);
  const lastItemRef = useRef<HTMLLIElement>(null);

  const dropdownRef = useRef<HTMLDivElement>(null);
  useClickOutside(dropdownRef, () => setDropdownOpened(false));

  const handleClick = () => {
    if (onBtnClick) onBtnClick();
    setDropdownOpened(!dropdownOpened);
  };

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    switch (event.key) {
      case 'ArrowDown':
        if (firstItemRef.current) {
          firstItemRef.current.focus();
          event.preventDefault();
        }
        break;
      case 'ArrowUp':
        if (lastItemRef.current) {
          lastItemRef.current.focus();
          event.preventDefault();
        }
        break;
      case 'Tab':
        setDropdownOpened(false);
        break;
      default:
        break;
    }
  };

  const handleOptionClick = (opt: Option, index: number) => {
    onOptionClick(opt.value, index);
    setDropdownOpened(false);
  };

  const selectedOptionText =
    ((list as Option[])?.find((option) => option.value === selected) as Option)?.label || selected;

  return (
    <S.Wrapper
      className={`${className} `}
      ref={dropdownRef}
      data-cy={dataCy}
    >
      {label && (
        <S.Label
          id='listbox_level'
          htmlFor={id}
          className={`${className}-label ${!label ? 'hidden' : ''}`}
          data-cy={`${dataCy}-label`}
        >
          {label} {required && <span>*</span>}
          {hasDisclaimer && <span className='disclaimer'>*</span>}
        </S.Label>
      )}
      <S.Button
        $size={size}
        type='button'
        role='combobox'
        id={id}
        aria-label={`Selected list item ${selectedOptionText}`}
        aria-controls='listbox'
        aria-haspopup='listbox'
        aria-expanded={dropdownOpened}
        onClick={handleClick}
        disabled={disabled}
        title={`${selectedOptionText || placeholder}`}
        className={`${className}-button ${placeholder && !selected ? 'has-placeholder' : ''}`}
        data-cy={`${dataCy}-button`}
        onKeyDown={handleOnKeyDown}
      >
        <span>{selectedOptionText || placeholder}</span>
      </S.Button>
      <DropdownList
        isOpen={dropdownOpened}
        onOptionClick={(value, index) => handleOptionClick(value, index)}
        list={list}
        selected={selected}
        firstItemRef={firstItemRef}
        lastItemRef={lastItemRef}
      />
      {assistiveText && (
        <S.Text
          className={`assistive-text ${className}`}
          data-cy={`${dataCy}-assistive-text`}
        >
          {assistiveText}
        </S.Text>
      )}
    </S.Wrapper>
  );
};

export default Dropdown;
