import CustomLink from 'components/Globals/Base/Link';
import styled, { css } from 'styled-components';
import { breakpoint, font } from 'styles/globals';
import { getColor, Theme } from 'styles/theme';

export const Wrapper = styled.ul<{ theme: Theme; $isSimple: boolean; $subCategoriesNr: number }>`
  display: none;
  position: absolute;
  right: ${(props) => (props.$isSimple ? '0' : '')};
  z-index: 1000;
  background: ${getColor('surfaceWhite')};
  filter: drop-shadow(4px 4px 20px rgba(17, 17, 17, 0.05));
  box-shadow: 4px 4px 20px 0px rgba(17, 17, 17, 0.05);
  width: 100%;
  max-width: 961px;
  min-width: 1000px;
  padding: 32px;
  min-height: 400px;
  max-height: 70vh;
  overflow: auto;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(${(props) => props.$subCategoriesNr}, max-content) 1fr;
  
  a:has(+ &.isOpen) {
    color: ${getColor('textAccentPrimary')};
  }

  @media (${breakpoint.xlMin}) {
    max-width: 1184px;
  }
  
  ${(props) =>
    !props.$isSimple &&
    css`
    left: 50%;
    transform: translateX(-50%);
    transform-origin: center;
  `};
`;

const subCategory = css`
  &.subCategory {
    padding-block: 8px;
    &:first-child {
      padding-top: 28px;
    }
    grid-column: 1;  
  }
`;

export const RightMenu = styled.ul<{ $isVisible: boolean }>`
  display: ${({ $isVisible }) => ($isVisible ? 'grid' : 'none')};
  grid-column: 2/5;
  grid-row: 1/-1;
  grid-template-columns: repeat(3, 1fr);
`;

export const Column = styled.ul<{ theme: Theme }>`
  display: flex;
  flex-direction: column;  
  padding: 20px;
  gap: 16px;
  &:first-of-type {
    border-left: 1px solid ${getColor('borderPrimary')};
  }
  &:not(:nth-of-type(3)) {
    border-right: 1px solid ${getColor('borderPrimary')};
  }
`;

export const ItemWrapper = styled.li`
  ${subCategory};
`;

const itemStyling = css<{ theme: Theme; $variant: 'big' | 'small'; $isActive?: boolean }>`
  font-family: ${font.graphikCond};
  text-wrap: wrap;
  letter-spacing: 0.5px;
  font-size: ${({ $variant }) => ($variant === 'big' ? '20px' : '16px')};
  font-weight: ${({ $variant }) => ($variant === 'big' ? '600' : '500')};
  color: ${({ $isActive, $variant }) => {
    if ($isActive) {
      return getColor('textAccentPrimary');
    }
    if ($variant === 'big') {
      return getColor('textPrimary');
    }
    return getColor('textSecondary');
  }};
`;

const linkStyles = css<{ theme: Theme }>`
  &:hover,
  &:focus {
    color: ${getColor('textAccentPrimary')};
  }
  &:active {
    color: ${getColor('textSecondary')};
  }
    `;

export const ItemLink = styled(CustomLink)<{ theme: Theme; $variant: 'big' | 'small'; $isActive?: boolean }>`
  ${itemStyling}
  ${linkStyles}
`;

export const ItemNoLink = styled.span<{ theme: Theme; $variant: 'big' | 'small'; $isActive?: boolean }>`
  ${itemStyling}
`;

export const ExploreAllLink = styled(CustomLink)<{ $isVisible: boolean; $isOutsideColumn?: boolean }>`
  align-self: flex-start;
  text-align: left;
  justify-content: left;  
  padding: 8px 0;
  ${({ $isOutsideColumn, $isVisible }) =>
    ($isOutsideColumn ?
      {
        display: $isVisible ? 'inline-flex' : 'none',
        gridColumn: 4,
        gridRowEnd: -1,
        margin: 'auto 0 20px 20px',
      } :
      { margin: 'auto 0 0' })};
`;

const smallTopPickCard = css`
  grid-template-columns: 66px 1fr;
  img {
    max-width: 66px;
    min-height: 44px;
    max-height: 44px;
    object-fit: cover;
  }
`;

const largeTopPickCard = css`
  grid-template-columns: 1fr;
  img {
    max-width: 100%;
    min-height: 150px;
    max-height: 150px;
    object-fit: cover;
  }
`;

export const TopPickCard = styled(CustomLink)<{ theme: Theme; $variant: string }>`
  display: grid;
  gap: 16px;
  font-family: ${font.graphikCond};
  letter-spacing: 0.5px;
  font-size: 14px;
  font-weight: 500;
  text-wrap: wrap;

  span {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    line-height: 1.5; 
  }

  ${({ $variant }) => ($variant === 'small-card' ? smallTopPickCard : largeTopPickCard)}  
`;
