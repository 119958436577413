import { ConversionResponse } from 'services/Piano/entities/PianoCallbackParams';
import { IterablePageData } from 'services/Iterable/entities';
import IterableCookie from './IterableCookie';

export default class IterableTracker {
  email: string;

  TokenService;

  events: { pageViewed: string; checkoutStart: string };

  iterableHost: string | undefined;

  constructor(email: string) {
    this.email = email;
    this.TokenService = new IterableCookie(email);
    this.iterableHost = process.env.ITERABLE_API_URL;
    this.events = {
      checkoutStart: 'checkoutStart',
      pageViewed: 'pageViewed',
    };
  }

  private getIterableHeaders = async (): Promise<HeadersInit> => {
    const token = await this.TokenService.get();

    return new Headers({
      'Api-Key': process.env.ITERABLE_JS_API_KEY ?? 'default',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      Host: this.iterableHost ?? 'default',
    });
  };

  trackEvent = async (eventName: string, dataFields: object) => {
    const data = {
      dataFields,
      email: this.email,
      eventName,
    };

    const headers = await this.getIterableHeaders();
    const host = `${this.iterableHost || 'default'}${process.env.ITERABLE_TRACK_PATH || 'default'}`;

    return fetch(host, {
      body: JSON.stringify(data),
      headers,
      method: 'POST',
    });
  };

  trackPageView = async (pageViewData: IterablePageData) => this.trackEvent(this.events.pageViewed, pageViewData);

  trackStartCheckout = async (url: string, termName: string) => {
    const dataFields = {
      termName,
      url,
    };

    return this.trackEvent(this.events.checkoutStart, dataFields);
  };

  trackCheckoutComplete = async (
    email: string,
    templateId: number | null,
    campaignId: number,
    { termConversionId, termId, rid, chargeAmount }: ConversionResponse,
  ) => {
    const data = {
      campaignId,
      id: termConversionId,
      items: [
        {
          id: termId,
          name: rid,
          price: chargeAmount,
          quantity: 1,
          url: window.location.href,
        },
      ],
      quantity: 1,
      templateId,
      total: chargeAmount,
      user: {
        email, // Email must match JWT Payload email
      },
    };

    const headers = await this.getIterableHeaders();

    await fetch(`${this.iterableHost || 'default'}${process.env.ITERABLE_TRACK_PURCHASE_PATH || 'default'}`, {
      body: JSON.stringify(data),
      headers,
      method: 'POST',
    })
      .then()
      .catch((error) => console.error(error));
  };
}
