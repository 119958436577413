/* eslint-disable no-param-reassign */
import React, { useContext, useEffect, useState } from 'react';
import { PageAdConfig } from 'interfaces/ads/Ad';
import { PermutivePageConfig } from 'services/Permutive/entities';
import Permutive from 'services/Permutive';
import { UserContext } from 'providers/UserProvider';
import Bombora from 'services/Bombora';
import { AdContext, defaultAdContext } from 'services/Ad';
import AdManager from './AdManager';

interface AdProviderProps {
  children: JSX.Element;
  pageAdConfig?: PageAdConfig;
  permutivePageConfig: PermutivePageConfig;
}

/**
 * If no consent is give, do not load ads.
 * Load Permutive before the ad library.
 */
const AdProvider: React.FC<AdProviderProps> = ({ children, pageAdConfig, permutivePageConfig }) => {
  const { hasConsent } = useContext(UserContext);
  const [adContextValue, setAdContextValue] = useState(defaultAdContext);
  const { hasLoggedIn } = useContext(UserContext);
  const renderAdScripts = hasConsent && pageAdConfig;

  useEffect(() => {
    if (hasConsent === false) {
      setAdContextValue(defaultAdContext);
    }
  }, [hasConsent]);

  if (pageAdConfig) {
    pageAdConfig.loginStatus = hasLoggedIn ? 'logged-in' : 'guest';
  }

  return (
    <>
      {renderAdScripts && (
        <>
          <Bombora />
          <AdManager
            pageAdConfig={pageAdConfig}
            setAdContextValue={setAdContextValue}
          />
        </>
      )}
      <AdContext.Provider value={adContextValue}>{children}</AdContext.Provider>
      {renderAdScripts && (
        <Permutive
          permutivePageConfig={permutivePageConfig}
          pageAdConfig={pageAdConfig}
        />
      )}
    </>
  );
};

export default AdProvider;
