import { useEffect, useState } from 'react';

const usePageview = (adConsent: any): number => {
  const [pv, setPv] = useState(0);
  useEffect(() => {
    if (adConsent) {
      const newPv = pv + 1;
      setPv(newPv);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adConsent]);

  return pv;
};

export default usePageview;
