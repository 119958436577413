import { MenuItemProps } from 'interfaces/navigation/Header';
import SubMenuItem from 'components/TopicBasedNavigation/SubmenuV2/SubmenuItem';
import getTrackingData from 'components/TopicBasedNavigation/SubmenuV2/utils';
import * as S from 'components/TopicBasedNavigation/SubmenuV2/SubmenuStyles';
import { NextCustomImage as Image } from 'components/Image';

export enum TopicSubmenuType {
  ARTICLES = 'articles',
  TOP_PICKS = 'top-picks',
}

interface TopicItemProps {
  item: MenuItemProps;
  submenuType: TopicSubmenuType;
  parentLabels: string[];
  isSelected: boolean;
}

const TopicItem = (props: TopicItemProps) => {
  const { item, parentLabels, submenuType, isSelected } = props;
  const HIDDEN_TOPIC_LABEL = '-';
  const showTopic = item.label !== HIDDEN_TOPIC_LABEL;
  const topicSubmenuList = item.subMenu;
  const hasOneSubmenuItem = topicSubmenuList?.length === 1;
  const topicLabels = [...parentLabels, ...(showTopic ? [item.label] : [])];

  return (
    <>
      {showTopic && (
        <SubMenuItem
          item={item}
          type='topic'
          trackingData={getTrackingData(topicLabels)}
          isSelected={isSelected}
        />
      )}
      {submenuType === TopicSubmenuType.ARTICLES &&
        topicSubmenuList?.map((article) => (
          <SubMenuItem
            key={article.id}
            item={article}
            type='article'
            trackingData={getTrackingData([...topicLabels, article.label])}
          />
        ))}
      {submenuType === TopicSubmenuType.TOP_PICKS &&
        topicSubmenuList?.map((topPick) => (
          <S.TopPickCard
            type='brandGray'
            key={topPick.id}
            href={topPick.url}
            $variant={hasOneSubmenuItem ? 'large-card' : 'small-card'}
            trackingData={getTrackingData([...topicLabels, topPick.label])}
          >
            {topPick?.thumbnail && (
              <Image
                width={66}
                height={44}
                quality={60}
                src={topPick?.thumbnail}
                alt={topPick.label}
              />
            )}
            <span>{topPick.label}</span>
          </S.TopPickCard>
        ))}
    </>
  );
};

export default TopicItem;
