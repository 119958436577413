/* eslint-disable sort-keys-fix/sort-keys-fix */
const WIDGETS_TEST_IDS = {
  CD_RATES_WIDGET: 'cd-rates-widget',
  CONTENT_HIGHLIGHT_CARD_WIDGET: 'content-highlight-card-widget',
  PINNED_ROUNDUPS: 'pinned-roundups-widget',
  EXPLORE_OUR_PRODUCTS_WIDGET: 'explore-our-products-widget',
  MEET_OUR_TEAM_PROFILE_CARD: 'meet-our-team-profile-card',
  MEET_OUR_TEAM_WIDGET: 'meet-our-team-widget',
  MEET_OUR_TEAM_WIDGET_ARROW_NEXT: 'meet-our-team-widget-arrow-next',
  MEET_OUR_TEAM_WIDGET_ARROW_PREV: 'meet-our-team-widget-arrow-prev',
  MEET_OUR_TEAM_WIDGET_TITLE: 'meet-our-team-widget-title',
  MONTHLY_ARTICLES_WIDGET: 'monthly-articles-widget',
  SAVINGS_WIDGET: 'savings-widget',
  TOP_HIGHLIGHT_CARD_WIDGET: 'top-highlight-card-widget',

  PRODUCT_TABLE_WRAPPER: 'product-table-wrapper',
  PRODUCT_TABLE_TITLE: 'product-table-title',

  READ_OUR_REVIEWS_WRAPPER: 'read-our-reviews-wrapper',
  READ_OUR_REVIEWS_TAB: 'read-our-reviews-tab',
  PRODUCT_TABLE_HEADERS: 'product-table-headers',
  PRODUCT_TABLE_HEADER: 'product-table-header',
  PRODUCT_TABLE_ROW: 'product-table-row',
  PRODUCT_TABLE_CARD_IMAGE_LINK: 'product-table-card-image-link',
  PRODUCT_TABLE_CARD_IMAGE: 'product-table-card-image',
  PRODUCT_TABLE_CARD_CAPTION_LINK: 'product-table-card-caption-link',
  PRODUCT_TABLE_CARD_TITLE_LINK: 'product-table-card-title-link',
  PRODUCT_TABLE_CARD_TITLE: 'product-table-card-title',
  PRODUCT_TABLE_CARD_DESCRIPTION: 'product-table-card-description',
  PRODUCT_TABLE_CARD_FEE: 'product-table-card-fee',
  PRODUCT_TABLE_CARD_FEE_TOOLTIP: 'product-table-card-fee',
  PRODUCT_TABLE_CARD_RATE: 'product-table-card-rate',
  PRODUCT_TABLE_CARD_RATE_VALUE: 'product-table-card-rate-value',
  PRODUCT_TABLE_CARD_RATE_TOOLTIP: 'product-table-card-rate-tooltip',
  PRODUCT_TABLE_CARD_OFFER: 'product-table-card-offer',
  PRODUCT_TABLE_CARD_OFFER_TOOLTIP: 'product-table-card-offer-tooltip',
  PRODUCT_TABLE_CARD_CTA_LINK: 'product-table-card-cta-link',
  PRODUCT_TABLE_CARD_CAPTION: 'product-table-card-caption',
  PRODUCT_TABLE_CARD_CTA_CAPTION: 'product-table-card-cta-caption',
};

export default WIDGETS_TEST_IDS;
