import { ReactElement, ReactNode, useContext, useState } from 'react';
import { CloseIconWhite } from 'icons/CloseIconWhite';
import SvgIcon from 'components/Globals/Base/SvgIcon';
import { NoticeContext } from 'providers/NoticeProvider';
import { NoticeWrapper, CloseWrapper, MessageWrapper } from './NoticeStyles';

export interface NoticeProps {
  message?: ReactElement | ReactElement[] | string | ReactNode;
  type?: 'success' | 'warning' | 'error';
  visible?: boolean;
}

const Notice = ({ type, message, visible }: NoticeProps) => {
  const [isVisible, setIsVisible] = useState(visible);
  const { setNoticeVisible } = useContext(NoticeContext) || {};

  const closeNotice = () => {
    setIsVisible(false);
    if (setNoticeVisible) {
      setNoticeVisible(false);
    }
  };

  return (
    <NoticeWrapper className={`${type} ${!isVisible && 'hidden'}`}>
      <MessageWrapper className={type}>
        {type === 'success' && (
          <SvgIcon
            name='CheckMarkFull'
            className='icon'
          />
        )}
        {type === 'error' && (
          <SvgIcon
            name='Error'
            className='icon'
          />
        )}
        {type === 'warning' && (
          <SvgIcon
            name='Warning'
            className='icon'
          />
        )}
        <p data-cy='notice-message'>{message}</p>
        <CloseWrapper
          onClick={closeNotice}
          aria-label='Close notice'
          role='button'
        >
          <CloseIconWhite />
        </CloseWrapper>
      </MessageWrapper>
    </NoticeWrapper>
  );
};

export default Notice;
