import { Email } from 'icons/SocialMedia/Email';
import { Facebook } from 'icons/SocialMedia/Facebook';
import { FacebookSquare } from 'icons/SocialMedia/FacebookSquare';
import { Instagram } from 'icons/SocialMedia/Instagram';
import { LinkedIn } from 'icons/SocialMedia/LinkedIn';
import { Pinterest } from 'icons/SocialMedia/Pinterest';
import { ShareIcon } from 'icons/SocialMedia/Share';
import { Twitter } from 'icons/SocialMedia/Twitter';
import { UserCircle } from 'icons/UserCircle';
import { PlusCircle } from 'icons/ButtonIcons/PlusCircle';
import { MinusCircle } from 'icons/ButtonIcons/MinusCircle';
import { CheckMarkCircle } from 'icons/ButtonIcons/CheckMarkCircle';
import { InfoCircle } from 'icons/ButtonIcons/InfoCircle';
import { CheckMarkFull } from 'icons/ButtonIcons/CheckMarkFull';
import { Error } from 'icons/ButtonIcons/Error';
import { Warning } from 'icons/ButtonIcons/Warning';
import { Plus } from 'icons/ButtonIcons/Plus';
import { Play } from 'icons/ButtonIcons/Play';
import { Search } from 'icons/ButtonIcons/Search';
import { SimpleCheck } from 'icons/ButtonIcons/SimpleCheck';
import { Upload } from 'icons/ButtonIcons/Upload';
import { Redirect } from 'icons/ButtonIcons/Redirect';
import { Refresh } from 'icons/ButtonIcons/Refresh';
import { Edit } from 'icons/ButtonIcons/Edit';
import { Filter } from 'icons/ButtonIcons/Filter';
import { Verified } from 'icons/ButtonIcons/Verified';

export interface SvgIconProps {
  name:
    | 'Edit'
    | 'Email'
    | 'Error'
    | 'FacebookSquare'
    | 'Facebook'
    | 'Filter'
    | 'InfoCircle'
    | 'Instagram'
    | 'LinkedIn'
    | 'Pinterest'
    | 'Plus'
    | 'Play'
    | 'ShareIcon'
    | 'Search'
    | 'SimpleCheck'
    | 'Twitter'
    | 'UserCircle'
    | 'PlusCircle'
    | 'MinusCircle'
    | 'CheckMarkCircle'
    | 'CheckMarkFull'
    | 'Warning'
    | 'Upload'
    | 'Redirect'
    | 'Refresh'
    | 'Verified';
  className?: string;
}

const ICONS = {
  CheckMarkCircle,
  CheckMarkFull,
  Edit,
  Email,
  Error,
  Facebook,
  FacebookSquare,
  Filter,
  InfoCircle,
  Instagram,
  LinkedIn,
  MinusCircle,
  Pinterest,
  Play,
  Plus,
  PlusCircle,
  Redirect,
  Refresh,
  Search,
  ShareIcon,
  SimpleCheck,
  Twitter,
  Upload,
  UserCircle,
  Verified,
  Warning,
};

const SvgIcon = ({ name, className = '' }: SvgIconProps) => {
  const Icon = ICONS[name];
  return Icon ? <Icon className={className} /> : null;
};

export default SvgIcon;
