/**
 * Frontend-only function used for reporting to the backend server's logs.
 *
 * @param {string} type - Log message type; could be an error, warn, or the name of a feature.
 * @param {string} name - Logged event's name; could be either the name of an Error, or the method of a feature.
 * @param {Record<string, any>} params - Additional data to add to the payload. Will result in a stringified JSON.
 * @param {number} [duration] Optional duration of the operation being logged, in milliseconds.
 */
const logToBackend = (type: string, name: string, params: Record<string, any>, duration: number = 0) => {
  fetch('/api/log', {
    body: JSON.stringify({
      duration,
      name,
      params,
      type,
    }),
    method: 'POST',
  })
    .then(() => {})
    .catch(() => {});
};

export default logToBackend;
