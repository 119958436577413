import { useCallback, useEffect, useState } from 'react';
import * as S from './ProgressBarStyles';

const getFooterHeight = () => {
  const footerDesktop = document.getElementById('recommends-footer-desktop');
  const footerDesktopStyle = footerDesktop && window.getComputedStyle(footerDesktop);

  const footerMobile = document.getElementById('recommends-footer-mobile');
  const footerMobileStyle = footerMobile && window.getComputedStyle(footerMobile);

  if (footerDesktopStyle && footerDesktopStyle.display !== 'none') {
    return footerDesktop.scrollHeight;
  }
  if (footerMobileStyle && footerMobileStyle.display !== 'none') {
    return footerMobile.scrollHeight;
  }
  return 0;
};

const ProgressBar = () => {
  const [percentage, setPercentage] = useState<number>(0);

  const handleScrollProgress = useCallback(() => {
    const scrollTop = window.scrollY;
    const footerHeight = getFooterHeight();
    const docHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight - footerHeight;

    const scrollPercentage = Math.min((scrollTop / docHeight) * 100, 100);
    setPercentage(scrollPercentage);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScrollProgress);

    return () => {
      window.removeEventListener('scroll', handleScrollProgress);
    };
  });

  return (
    <S.Wrapper data-cy='progress-bar'>
      <S.ProgressBar $percentage={percentage} />
    </S.Wrapper>
  );
};

export default ProgressBar;
