import React from 'react';
import { BaseAdConfig } from 'interfaces/ads/Ad';
import ADS_TEST_IDS from 'constants/testsIds/ads';
import { LeaderboardAd } from './Styles';
import AdSlotToggle from './AdSlotToggle';

const Leaderboard: React.FC<BaseAdConfig> = ({ id }: BaseAdConfig) => (
  <AdSlotToggle
    component={LeaderboardAd}
    placementName='Leaderboard'
    index={id + 1}
    delay={1}
    slotId={`Leaderboard${id}`}
    dataCy={ADS_TEST_IDS.LEADERBOARD}
  />
);

export default Leaderboard;
