import styled, { css } from 'styled-components';
import { breakpoint, font } from 'styles/globals';
import { getColor, Theme } from 'styles/theme';
import { arrowLeft } from 'styles/icons';
import orderBreakpoints from 'utils/miscUtils/orderBreakpoints';
import * as S from 'components/Globals/Base/Button/buttonStyles';
import { ButtonSizeStyling } from 'components/Globals/Base/Button/types';
import { LinkTypes } from './types';

// Link types
export const primaryLink = css<{ theme: Theme }>`
  color: ${getColor('textPrimary')};

  &:hover,
  &:hover > *:not(.no-hover),
  &:focus,
  &:focus > *:not(.no-focus) {
    color: ${getColor('textLink')};
    text-decoration: none;
  }

  &:active,
  &:active > *:not(.no-active) {
    color: ${getColor('textSecondary')};
  }
`;

export const secondaryLink = css<{ theme: Theme }>`
  color: ${getColor('textPrimary')};

  &:hover,
  &:hover > *:not(.no-hover),
  &:focus,
  &:focus > *:not(.no-focus) {
    color: ${getColor('textLink')};
    text-decoration: underline;
  }

  &:active,
  &:active > *:not(.no-active) {
    text-decoration: none;
    color: ${getColor('textSecondary')};
  }
`;

export const bluePrimaryLink = css<{ theme: Theme }>`
  color: ${getColor('textLink')};

  &:hover,
  &:hover > *:not(.no-hover) {
    text-decoration: underline;
  }

  &:active,
  &:focus,
  &:active > *:not(.no-active),
  &:focus > *:not(.no-focus) {
    text-decoration: none;
    color: ${getColor('textSecondary')};
  }
`;

export const blueSecondaryLink = css<{ theme: Theme }>`
  color: ${getColor('textLink')};
  text-decoration: underline;

  &:hover,
  &:hover > *:not(.no-hover),
  &:focus,
  &:focus > *:not(.no-focus) {
    text-decoration: none;
  }

  &:active,
  &:active > *:not(.no-active) {
    text-decoration: underline;
    color: ${getColor('textSecondary')};
  }
`;

export const brandPrimaryLink = css<{ theme: Theme }>`
  color: ${getColor('textPrimary')};
  text-decoration: none;

  &:hover,
  &:hover > *:not(.no-hover),
  &:focus,
  &:focus > *:not(.no-focus) {
    color: ${getColor('textAccentPrimary')};
    text-decoration: none;
  }

  &:active,
  &:active > *:not(.no-active) {
    color: ${getColor('textSecondary')};
  }
`;

export const brandSecondaryLink = css<{ theme: Theme }>`
  color: ${getColor('textPrimary')};

  &:hover,
  &:hover > *:not(.no-hover),
  &:focus,
  &:focus > *:not(.no-focus) {
    color: ${getColor('textAccentPrimary')};
    text-decoration: underline;
  }

  &:active,
  &:active > *:not(.no-active) {
    color: ${getColor('textSecondary')};
  }
`;

export const brandAccentPrimaryLink = css<{ theme: Theme }>`
  color: ${getColor('textAccentPrimary')};

  &:hover,
  &:hover > *:not(.no-hover),
  &:focus,
  &:focus > *:not(.no-focus) {
    text-decoration: underline;
  }

  &:active,
  &:active > *:not(.no-active) {
    color: ${getColor('textSecondary')};
  }
`;

export const brandAccentSecondaryLink = css<{ theme: Theme }>`
  color: ${getColor('textAccentPrimary')};
  text-decoration: underline;

  &:hover,
  &:hover > *:not(.no-hover),
  &:focus,
  &:focus > *:not(.no-focus) {
    text-decoration: none;
  }

  &:active,
  &:active > *:not(.no-active) {
    color: ${getColor('textSecondary')};
    text-decoration: underline;
  }
`;

export const brandGrayLink = css<{ theme: Theme }>`
  color: ${getColor('textSecondary')};

  &:hover,
  &:hover > *:not(.no-hover),
  &:focus,
  &:focus > *:not(.no-focus) {
    color: ${getColor('textAccentPrimary')};
    text-decoration: none;
  }

  &:active,
  &:active > *:not(.no-active) {
    color: ${getColor('textPrimary')};
  }
`;

export const redLink = css<{ theme: Theme }>`
  color: ${getColor('textAccentSecondary')};

  &:hover,
  &:hover > *:not(.no-hover) {
    color: ${getColor('textAccentSecondary')};
    text-decoration: underline;
  }

  &:active,
  &:focus, 
  &:active > *:not(.no-active),
  &:focus > *:not(.no-focus) {
    color: ${getColor('textSecondary')};
    text-decoration: none;
  }
`;

export const topicNavigation = css<{ theme: Theme }>`
  color: ${getColor('surfaceQuartenary')};
  font-family: ${font.saolText};

  &.selected {
    color: ${getColor('textPrimary')};
  }

  &:hover {
    color: ${getColor('textLink')};
  }

  &:active,
  &:focus {
    color: ${getColor('textPrimary')};
  }
`;

export const image = css<{ theme: Theme }>`
  color: ${getColor('textPrimary')};

  &:hover {
    color: ${getColor('textLink')};
    img,
    .img {
      filter: brightness(80%);
    }
  }

  &:active,
  &:focus {
    color: ${getColor('textSecondary')};
    img,
    .img {
      filter: brightness(70%);
    }
  }
`;

export const whiteLink = css<{ theme: Theme }>`
  color: ${getColor('textWhite')};

  &:hover,
  &:hover > *:not(.no-hover),
  &:focus,
  &:focus > *:not(.no-focus) {
    text-decoration: underline;
  }

  &:active,
  &:active > *:not(.no-active) {
    text-decoration: none;
    color: ${getColor('textSecondary')};
  }
`;

export const whiteButton = css<{ theme: Theme }>`
  ${S.whiteButton}
  
  &:hover,
  &:hover > *:not(.no-hover),
  &:focus,
  &:focus > *:not(.no-focus) {
    text-decoration: none;
  }

  &:active,
  &:active > *:not(.no-active) {
    text-decoration: underline;
    color: ${getColor('textPrimary')};
  }
`;

export const Link = styled.a<{
  theme: Theme;
  $type: keyof typeof LinkTypes;
  $size?: { default: keyof ButtonSizeStyling; [key: string]: keyof ButtonSizeStyling };
  $center?: boolean;
  $hasRightArrow?: boolean;
  $hasLeftArrow?: boolean;
}>`
  cursor: pointer;
  text-decoration: none;
  font-family: inherit;
  &.link {
    background-color: transparent;
    border: 0;
  }

  ${({ $center }) =>
    $center &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
  `};

  ${({ $size, $type }) => {
    const buttonTypes: string[] = Object.keys(LinkTypes).filter((key) => key.includes('Button'));
    const isButton = buttonTypes.includes($type);
    if (!isButton) return '';

    const isTertiary = $type && $type.indexOf('tertiary') > -1;
    const sizesStyling = S.buttonSizes[isTertiary ? 'tertiary' : 'general'];

    const { default: defaultSize, ...customSizes } = $size || { default: 'small' };
    const breakpoints = orderBreakpoints(customSizes) as { [key: string]: keyof ButtonSizeStyling };

    return css`
      ${sizesStyling[defaultSize]};
      ${Object.keys(breakpoints).map(
    (bkp: string) => css`
          @media (${breakpoint[bkp]}) {
            ${sizesStyling[breakpoints[bkp]]};
          }
      `,
  )}
    `;
  }}

  ${({ $type }) => {
    switch ($type) {
      // Default
      case 'primary':
        return primaryLink;
      case 'secondary':
        return secondaryLink;

      // Blue
      case 'bluePrimary':
        return bluePrimaryLink;
      case 'blueSecondary':
        return blueSecondaryLink;

      // Brand
      case 'brandPrimary':
        return brandPrimaryLink;
      case 'brandSecondary':
        return brandSecondaryLink;
      case 'brandAccentPrimary':
        return brandAccentPrimaryLink;
      case 'brandAccentSecondary':
        return brandAccentSecondaryLink;
      case 'brandGray':
        return brandGrayLink;

      // Buttons
      case 'primaryButton':
        return S.primaryButton;
      case 'secondaryButton':
        return S.secondaryButton;
      case 'tertiaryButton':
        return S.tertiaryButton;
      case 'tertiaryBlackButton':
        return S.tertiaryBlackButton;
      case 'primaryBrandButton':
        return S.primaryBrandButton;
      case 'secondaryBrandButton':
        return S.secondaryBrandButton;
      case 'tertiaryBrandButton':
        return S.tertiaryBrandButton;
      case 'accentButton':
        return S.accentButton;
      case 'whiteButton':
        return whiteButton;

      // Main Menu
      case 'topicNavigation':
        return topicNavigation;

      // Image
      case 'image':
        return image;

      // Other
      case 'white':
        return whiteLink;
      case 'red':
        return redLink;
      default:
        return null;
    }
  }}

  ${({ $hasRightArrow }) =>
    !$hasRightArrow &&
    css`
    &::after {
      display: none;
    }

    &:hover::after,
    &:focus::after,
    &:active::after {
      display: none;
    }
  `};
  ${({ $hasLeftArrow }) =>
    $hasLeftArrow &&
    css<{ theme: Theme }>`
    &::before {
      ${arrowLeft}
    }

    &:active {
      &::before {
        border-color: ${getColor('neutralDarkGray')};
      }
    }

    &::before {
      border-color: ${getColor('iconPrimary')};
    }
  `};
`;

export default Link;
