import CustomWindow from 'interfaces/Utils';
import { UserContext } from 'providers/UserProvider';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { ExecutionResult } from 'services/Piano/entities/Tinypass';
import { getSessionIdentifiers } from 'utils/trackDataUtils';

declare let window: CustomWindow;

interface PaywallIframeEvent {
  eventName: string;
  eventData: {
    displayMode: string;
    experienceActionId: string;
    experienceId: string;
    shownOfferExperiences: string[];
    templateId: string;
    templateVariantId: string;
  };
}

/**
 * @param experienceResult the result of the last execution of the Piano experience logic
 * @returns a list of Experience IDs for experiences that resulted in a showOffer event
 */
function filterExperiencesWithShownOffers(experienceResult: ExecutionResult) {
  const { events } = experienceResult.result;
  const experiencesWithShownOffers = events.reduce((acc: string[], event) => {
    if (event.eventType === 'showOffer') {
      const { experienceId } = event.eventExecutionContext;
      acc.push(experienceId);
    }
    return acc;
  }, []);

  return experiencesWithShownOffers;
}

/**
 * @param node inserted DOM node detected by the MutationObserver
 * @returns a paywall iframe if the node contains a paywall iframe, otherwise null
 */
function isPaywallIframe(node: Node) {
  if (node.nodeName === 'DIV' && (node as HTMLElement).classList.contains('tp-modal')) {
    const wrapper = node.childNodes[0];
    const iframe = Array.from(wrapper.childNodes).find((child) => child.nodeName === 'IFRAME') as HTMLIFrameElement;
    const params = new URLSearchParams(iframe.src.split('?')[1]);
    const checkoutModal = params.get('isCheckout');
    if (iframe && iframe.id.startsWith('offer-') && !checkoutModal) {
      return iframe;
    }
  } else if (node.nodeName === 'DIV' && (node as HTMLElement).classList.contains('tp-container-inner')) {
    const nodeList = Array.from(node.childNodes);
    const iframe = nodeList.find((child) => child.nodeName === 'IFRAME') as HTMLIFrameElement;
    if (iframe && iframe.id.startsWith('offer-')) {
      return iframe;
    }
  }
  return null;
}

const usePaywallIframeObserver = () => {
  const { hasConsent } = useContext(UserContext);
  const eventQueueRef = useRef<any[]>([]);
  const shouldTrackRef = useRef(false);

  /**
   * Tracks an event if the flag to allow tracking is set, otherwise queues the event.
   */
  const trackEvent = useCallback((event: PaywallIframeEvent) => {
    if (shouldTrackRef.current) {
      const { analytics } = window;
      const sessionIdentifiers = getSessionIdentifiers();
      const { eventName, eventData } = event;
      const eventPayload = {
        ...sessionIdentifiers,
        ...eventData,
      };
      analytics.track(eventName, eventPayload);
    } else {
      eventQueueRef.current.push(event);
    }
  }, []);

  /**
   * When consent is given/recognized, sends the queued events to Segment, clears the queue,
   * and sets the flag to allow tracking on subsequent events as they occur.
   */
  useEffect(() => {
    if (hasConsent) {
      try {
        shouldTrackRef.current = true;
        const { analytics } = window;
        const sessionIdentifiers = getSessionIdentifiers();

        eventQueueRef.current.forEach((event: PaywallIframeEvent) => {
          const { eventName, eventData } = event;
          const eventPayload = {
            ...sessionIdentifiers,
            ...eventData,
          };
          analytics.track(eventName, eventPayload);
        });

        eventQueueRef.current = [];
      } catch (error) {
        console.error('Error processing queued paywall iframe observer events:', error);
      }
    }
  }, [hasConsent]);

  /**
   * Observes the DOM for the appearance of paywall-related iframes and tracks the display context of the iframe.
   */
  useEffect(() => {
    const callback: MutationCallback = (mutationsList) => {
      try {
        mutationsList.forEach((mutation) => {
          if (mutation.type === 'childList') {
            mutation.addedNodes.forEach((node) => {
              const iframe = isPaywallIframe(node);
              if (iframe) {
                const params = new URLSearchParams(iframe.src.split('?')[1]);
                const { tp } = window;
                if (tp) {
                  // eslint-disable-next-line no-underscore-dangle
                  const experienceResult = tp.experience._getLastExecutionResult();
                  const shownOfferExperiences = filterExperiencesWithShownOffers(experienceResult);

                  const iframeDisplayContext = {
                    displayMode: params.get('displayMode') || '',
                    experienceActionId: params.get('experienceActionId') || '',
                    experienceId: params.get('experienceId') || '',
                    shownOfferExperiences,
                    templateId: params.get('templateId') || '',
                    templateVariantId: params.get('templateVariantId') || '',
                  };
                  trackEvent({
                    eventData: iframeDisplayContext,
                    eventName: 'paywall markup rendered',
                  });
                }
              }
            });
          }
        });
      } catch (error) {
        console.error('Error observing paywall iframes:', error);
      }
    };
    const observer = new MutationObserver(callback);
    const config = { childList: true, subtree: true };
    if (process.env.FEATURE_FLAG_PAYWALL_IFRAME_OBSERVER) {
      observer.observe(document.body, config);
    }

    return () => observer.disconnect();
  }, [trackEvent]);
};

export default usePaywallIframeObserver;
