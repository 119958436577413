import { useEffect, useState } from 'react';
import { breakpoint } from 'styles/globals';

export default function useBreakpoint(breakpointName: string) {
  const [matches, setMatches] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(`(${breakpoint[breakpointName]})`);
    setMatches(mediaQueryList.matches);
    const handleChange = (mql: MediaQueryListEvent) => {
      setMatches(mql.matches);
    };
    mediaQueryList.addEventListener('change', handleChange);

    return () => {
      mediaQueryList.removeEventListener('change', handleChange);
    };
  }, [breakpointName]);

  return matches;
}
