import { NextCustomImage } from 'components/Image';
import * as S from './ExploreProductsStyles';
import WIDGETS_TEST_IDS from '../../constants/testsIds/widgets';

export interface Cards {
  href: string;
  img?: string;
  text: string;
}

const ExploreProducts = ({ title, cards }: { title: string; cards: Cards[] }) => (
  <S.Wrapper
    className='explore-our-products-widget'
    data-cy={WIDGETS_TEST_IDS.EXPLORE_OUR_PRODUCTS_WIDGET}
  >
    <S.Title>{title}</S.Title>
    <S.CardContainer numOfItems={cards.length}>
      {cards.map((card) => (
        <S.Card
          key={card.href}
          href={card.href}
        >
          {card.img && (
            <NextCustomImage
              src={card.img}
              alt={card.text}
              width={30}
              height={30}
              className='img'
            />
          )}
          <S.CardText>{card.text}</S.CardText>
        </S.Card>
      ))}
    </S.CardContainer>
  </S.Wrapper>
);
export default ExploreProducts;
