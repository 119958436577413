import { MenuItemProps } from 'interfaces/navigation/Header';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import cx from 'classnames';
import * as S from './NavigationStyles';
import NavItem from './NavItem';

export interface TopicNavigationProps {
  color: string;
  topics: MenuItemProps[];
  subdomain: string;
  isSimple: boolean;
  showNewTopicNav?: boolean;
}

const TopicNavigation = ({ color, topics, subdomain, isSimple, showNewTopicNav = false }: TopicNavigationProps) => {
  const router = useRouter();
  const isHomepage = router.asPath === '/' || router.asPath === '/europe/' || router.asPath === '/asia/';
  const [currentUrl, setCurrentUrl] = useState('');

  useEffect(() => {
    setCurrentUrl(router.asPath.split('?')[0]);
  }, [router.asPath]);

  if (topics.length === 0) return null;

  return (
    <S.NavigationWrapper
      className={cx(subdomain, { homepage: isHomepage })}
      $showNewTopicNav={showNewTopicNav}
      $isSimple={isSimple}
    >
      <S.NavigationList
        $isSimple={isSimple}
        data-cy='topicNavigation'
      >
        {topics.map((item) => (
          <NavItem
            key={item.id}
            {...item}
            currentUrl={currentUrl}
            color={color}
            subdomain={subdomain}
            isSimple={isSimple}
            showNewTopicNav={showNewTopicNav}
          />
        ))}
      </S.NavigationList>
    </S.NavigationWrapper>
  );
};

export default TopicNavigation;
