/* eslint-disable import/prefer-default-export */
import Script from 'next/script';

const ArcherAdsScript: React.FC = () => (
  <Script
    src='https://publisher-listings.campusexplorer.com/static/listings.min.js'
    async
  />
);

export { ArcherAdsScript };
