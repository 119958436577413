import styled from 'styled-components';
import { Theme, getColor } from 'styles/theme';
import { breakpoint } from 'styles/globals';

const WidgetWrapper = styled.div<{ theme: Theme }>`
  max-width: 1344px;
  height: 104px;
  margin: auto;

  &:has(.tradingview-widget-container__widget:empty) {
    .tradingview-widget-copyright {
      display: none;
    }
  }
  
  .tradingview-widget-copyright {
    // !important is needed to override tradingview widget CSS
    text-align: left !important;
    padding-left: 16px;

    .blue-text {
      // !important is needed to override tradingview widget CSS
      color: ${getColor('textLink')} !important;
    }

    @media (${breakpoint.mdMin}) {
      padding-left: 24px;
    }
  }

  
  .tradingview-widget-container {
    @media (${breakpoint.mdMax}) {
      overflow-y: scroll;
      scrollbar-width: none;
    }
  }

  iframe {
    border-bottom: 1px solid ${getColor('borderSecondary')};

    @media (${breakpoint.mdMax}) {
      // !important is needed to override tradingview widget CSS
      width: 1344px !important;
    }
  }
`;

export default WidgetWrapper;
