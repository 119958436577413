import { PianoPageConfig } from 'services/Piano/entities/PianoPageConfig';
import { dateDifferenceInHours } from 'utils/dateUtils';
import { JsonLdSchema } from './metaDataUtils/setJsonLdSchema';

export function addPaywallSchema(schema: string, pageConfig: PianoPageConfig): string {
  const schemaObj = JSON.parse(schema) as JsonLdSchema;
  const paywallStory = isPaywallContent(pageConfig);

  if (paywallStory || pageConfig.franchiseWall) {
    schemaObj.isAccessibleForFree = false;
    schemaObj.hasPart = {
      '@type': 'WebPageElement',
      cssSelector: pageConfig.franchiseWall ? '.franchise-content' : '.paywall',
      isAccessibleForFree: 'False',
    };
  } else {
    schemaObj.isAccessibleForFree = true;
  }

  const schemaWithPaywall = JSON.stringify(schemaObj);
  return schemaWithPaywall;
}

export function isPaywallContent(pageConfig: PianoPageConfig): boolean {
  return (
    (pageConfig.contentSection.startsWith('P') ||
      ['F-BreakingNews', 'F-Conference', 'F-Search', 'F-Wire', 'F-Commentary', 'legacy'].includes(
        pageConfig.contentSection,
      )) &&
    !pageConfig.tags.includes('Always Free')
  );
}

export function olderThan12Hours(publishDate: string): boolean {
  const duration = dateDifferenceInHours(new Date(publishDate), new Date());

  return duration > 12;
}

export function hasExcludedTag(tags: String[]): boolean {
  const excludedTopics: String[] = [
    'Always Free',
    'Battle for Talent',
    'Breakthrough!',
    'CFO Daily',
    'CHRO Daily',
    'Everyday AI',
    'Fortune Well',
    'Leadership Next',
    'RV Widget Test',
    'Tech Debate',
    'Tech Forward',
    'The Modern Board',
    'The Path to Zero',
    'The Trust Factor Newsletter',
  ];

  return tags.some((e) => excludedTopics.includes(e));
}

export function isAutoPaywallContent(pageConfig: PianoPageConfig): boolean {
  const publishedOver12hr = olderThan12Hours(pageConfig.publishDate);
  const excludedTag = hasExcludedTag(pageConfig.tags);

  let autoPaywall;
  switch (pageConfig.contentSection) {
    case 'F-BreakingNews':
    case 'F-Conference':
    case 'F-Search':
      autoPaywall = true;
      break;
    default:
      autoPaywall = false;
      break;
  }

  return autoPaywall && publishedOver12hr && !excludedTag;
}
