import { Interpolation } from 'styled-components';
import Wrapper from './PlaceholderStyles';

export interface Props {
  size: Interpolation<React.CSSProperties>;
  className?: string;
}

const Placeholder = ({ size, className = '' }: Props) => (
  <Wrapper
    className={className}
    $size={size}
  />
);

export default Placeholder;
