import styled from 'styled-components';
import { breakpoint, font } from 'styles/globals';
import { Theme, getColor } from 'styles/theme';

interface DrawerMenuProps {
  $isSubscribeBtn: boolean;
}

export const ContainerDrawer = styled.div<{ theme: Theme }>`
  height: 100vh;
  overflow-y: scroll;
  width: 100%;
  position: fixed;
  top: 0;
  left: -100%;
  background-color: ${getColor('surfaceWhite')};
  transition: all 0.3s ease-in-out;
  z-index: 100002;
  padding: 0 16px;
  visibility: hidden;
  
  &.open {
    left: 0;
    visibility: visible;
  }

  @media (${breakpoint.mdMin}) {
    width: 100%;
    left: -100%;
    padding: 0 24px 24px;
  }

  @media (${breakpoint.lgMin}) {
    max-width: 680px;
    left: -680px;
  }

  .extra-padding {
    padding-bottom: 64px;
  }

  @supports (height: 100svh) {
    height: 100svh;

    .extra-padding {
      padding-bottom: 0;
    }
  }
`;

export const CloseWrapper = styled.div<{ theme: Theme }>`
  display: flex;
  align-items: center;
  position: sticky;
  background-color: ${getColor('surfaceWhite')};
  top: 0;
  left: 20px;
  padding-top: 16px;
  padding-bottom: 8px;
  z-index: 9999;
  justify-content: space-between;

  @media (${breakpoint.mdMin}) {
    padding-top: 24px;
  }
`;

export const MyAccountWrapper = styled.div<{ theme: Theme }>`
  & .subscribe-link {
    display: flex;

    @media (${breakpoint.xlMin}) {
      font-size: 16px;
    }
  }
`;

export const TopLeftWrapper = styled.div<DrawerMenuProps>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  .link {
    font-family: ${font.graphikCond};
    font-weight: 500;
    font-size: 16px;
    margin-left: 32px;
    display: flex;
    text-transform: uppercase;
  }

  @media (${breakpoint.mdMin}) {
    justify-content: ${(props) => (props.$isSubscribeBtn ? 'flex-start' : 'space-between')};
    width: ${(props) => (props.$isSubscribeBtn ? 'inherit' : '100%')};
  }
`;

export const OutsideTheMenu = styled.div<{ theme: Theme }>`
  z-index: 100001;
  position: fixed;
  inset: 0;
  background-color: ${getColor('surfaceBlack')};
  opacity: 0;
  transition: all 0.3s ease-in-out;
  visibility: hidden;
`;
