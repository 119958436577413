import styled from 'styled-components';
import { breakpoint } from 'styles/globals';
// import { breakpoint } from 'styles/globals';
import { Theme } from 'styles/theme';

export const Wrapper = styled.div<{ theme: Theme }>`
  
  container: cardContainer/inline-size;

  .edu-card-table {
    table {
      width: 100%;
    }
  }

  @container cardContainer (max-width: 300px){
    .edu-card-container {
      grid-template-columns: 1fr;
    }
  }

  // the sidebar sponsored ad is displayed inside the article content on tablet and mobile
  &.sponsored-ad-right-rail {
    &-desktop{
      display: none;
      @media (${breakpoint.xlMin}) {
        display: block;
      }
    }

    &-tablet-mobile {
      display: block;
      max-width: 400px;
      margin: auto;
      margin-bottom: 24px;
      @media (${breakpoint.xlMin}) {
        display: none;
      }
    }
  }
`;
export const CardTable = styled.div<{ theme: Theme }>`

  .edu-card-table {
    table {
      width: 100%;
    }
  }

`;
