import CustomWindow from 'interfaces/Utils';
import sha256 from 'crypto-js/sha256';
import CookieService from 'utils/miscUtils/CookieService';

declare let window: CustomWindow;

const permutiveIdentify = (pianoId: string) => {
  const segmentId = CookieService.get('ajs_user_id') || '';
  window.permutive.identify([
    {
      id: pianoId,
      priority: 0,
      tag: 'piano_id',
    },
    {
      id: segmentId,
      priority: 1,
      tag: 'segment_id',
    },
    {
      id: window.tp.pianoId && window.tp.pianoId.getUser() ? sha256(window.tp.pianoId.getUser().email).toString() : '',
      priority: 2,
      tag: 'email_sha256',
    },
  ]);
};

export default permutiveIdentify;
