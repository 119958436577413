import styled from 'styled-components';

export const Hr = styled.hr`
  height: 16px;
  border: none;
  background-repeat: no-repeat;
  background-position: 50%;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjQTNBNUE5IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxjaXJjbGUgY3g9IjIiIGN5PSIyIiByPSIyIi8+PGNpcmNsZSBjeD0iMTQiIGN5PSIyIiByPSIyIi8+PGNpcmNsZSBjeD0iMjYiIGN5PSIyIiByPSIyIi8+PGNpcmNsZSBjeD0iMzgiIGN5PSIyIiByPSIyIi8+PC9nPjwvc3ZnPg==);
  margin-bottom: 36px;
`;

export default Hr;
