/* eslint-disable import/prefer-default-export */
import Script from 'next/script';

const RedVentureScript: React.FC = () => (
  <Script
    src='https://static.myfinance.com/widget/myFinance.js'
    async
  />
);

export { RedVentureScript };
