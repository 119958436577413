import React from 'react';
import InStream from 'components/Ad/InhouseAd/InStream';
import StickyAdFooter from 'components/Ad/InhouseAd/StickyFooter';
import HtmlAd from 'components/Ad/InhouseAd/Html';
import { PageType } from 'interfaces/content/articles/Post';
import { HtmlInhouseAdProps, InhouseAdConfig, InstreamInhouseAdProps } from 'interfaces/ads/Ad';

const InhouseAd: React.FC<InhouseAdConfig> = ({
  inhouseAd,
  pageType,
  placementName,
  currentAuthors,
  currentTags,
  currentSection,
}) => {
  if ((pageType === PageType.ARTICLE || pageType === PageType.ARTICLE_V2) && placementName === 'Sticky') {
    return (
      <StickyAdFooter
        pageType={pageType}
        currentSection={currentSection || ''}
        currentTags={currentTags || ''}
        currentAuthors={currentAuthors || ''}
      />
    );
  }

  if (
    (pageType === PageType.ARTICLE || pageType === PageType.ARTICLE_V2) &&
    (placementName === 'InStream' || placementName === 'InContent') &&
    inhouseAd &&
    inhouseAd.adType === 'json'
  ) {
    return (
      <InStream
        pageType={pageType}
        placementName={placementName}
        inhouseAd={inhouseAd as InstreamInhouseAdProps}
      />
    );
  }
  if (inhouseAd && inhouseAd.adType === 'html') {
    return (
      <HtmlAd
        inhouseAd={inhouseAd as HtmlInhouseAdProps}
        pageType={pageType}
        placementName={placementName}
      />
    );
  }
  return null;
};

export default InhouseAd;
