import styled from 'styled-components';
import { breakpoint } from 'styles/globals';
import { arrowRight } from 'styles/icons';
import { getBodyStyle, getColor, Theme } from 'styles/theme';

export const Wrapper = styled.div<{ theme: Theme; $isInDrawerMenu: boolean }>`
  position: ${(props) => props.$isInDrawerMenu && 'fixed'};
  display: ${(props) => (!props.$isInDrawerMenu ? 'none' : 'flex')};
  justify-content: flex-end;
  align-items: center;
  background:  ${getColor('surfacePrimary')};
  padding: 4px 16px;
  width: 100%;
  bottom: 0;
  left: 0;

  @media(${breakpoint.lgMin}) {
    display: flex;
    padding: 4px 24px;
  }
  `;

export const Button = styled.a<{ theme: Theme }>`
  ${getBodyStyle('graphikCompact', { default: 'default' }, 'regular')};
  padding: 8px 0;
  text-decoration: none;

  @media(${breakpoint.lgMin}) {
    padding: 0;
  }

  &:after {
    ${arrowRight};
    margin-left: 4px;
    margin-bottom: 0;
  }

  &:hover, &:focus {
    color: ${getColor('brandPrimary')}
  }
`;
