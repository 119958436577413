import { useState, useEffect } from 'react';
import { generateUniqueId } from 'utils/numberUtils';

const useUniqueAdId = (): number => {
  const [uniqueId, setUniqueId] = useState<number>(0);

  useEffect(() => {
    const newId = generateUniqueId();
    setUniqueId(newId);
  }, []);

  return uniqueId;
};

export default useUniqueAdId;
