import ErrorBoundaryBase from 'components/ErrorBoundary/base';
import React from 'react';
import { UIFragments } from 'utils/log/constants/uiFragments';
import { AppErrorSeverity } from 'utils/log/constants/errorSeverity';
import UICrashFallback from './UICrashFallback';

interface ErrorBoundaryProps {
  placeholder?: boolean;
  message?: string;
  fallback?: React.ReactNode;
  fragment?: UIFragments;
  severity?: AppErrorSeverity;
}

/**
 * Flexible Error Boundary provider
 *
 * Allows React render loop errors to be caught either silently (default), with a message, or with a custom fallback
 *
 * @param {ErrorBoundaryProps} props - Component configuration
 * @param {boolean} [props.placeholder] - Set this to `true` to display the default placeholder as its fallback
 * @param {message} [props.message] - Displays a custom message in place of the crashed component
 * Works only when `placeholder` is `true` and not using a custom fallback component
 * @param {React.ReactNode} [props.fallback] - Provide a custom fallback component; overrides default placeholder
 * @param {UIFragments} [props.fragment] - An optional token describing the UI fragment of the error; used for logging
 * @param {AppErrorSeverity} [props.severity] - Severity of errors occurring in this boundary; used for logging
 * @returns {React.ReactNode} The children of this component wrapped inside an error boundary
 */
const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({
  children,
  placeholder = false,
  message,
  fragment,
  severity,
  fallback,
}: React.PropsWithChildren<ErrorBoundaryProps>) => {
  let renderFallback = null;

  if (fallback) {
    renderFallback = fallback;
  } else if (placeholder) {
    renderFallback = <UICrashFallback message={message} />;
  }

  return (
    <ErrorBoundaryBase
      __unsafe_standalone_use={false}
      fragment={fragment}
      severity={severity}
      fallback={renderFallback}
    >
      {children}
    </ErrorBoundaryBase>
  );
};

/**
 * Produces a safe version of a Component, wrapping it inside a <ErrorBoundary />
 *
 * Any errors within this component will take down the entire component with a fallback,
 * if no other error boundaries are present in its hierarchy
 * @param {React.ComponentType} Component - The React component to wrap
 * @param {ErrorBoundaryProps} [boundaryProps] - Optional props to apply to the ErrorBoundary wrapper
 * @param {boolean} [boundaryProps.placeholder] - Set this to `true` to display the default placeholder as its fallback
 * @param {message} [boundaryProps.message] - Displays a custom message in place of the crashed component
 * Works only when `placeholder` is `true` and not using a custom fallback component
 * @param {React.ReactNode} [boundaryProps.fallback] - Provide a custom fallback component; overrides default placeholder
 * @param {UIFragments} [boundaryProps.fragment] - An optional token describing the UI fragment of the error; used for logging
 * @param {AppErrorSeverity} [boundaryProps.severity] - Severity of errors occurring in this boundary; used for logging
 * @returns A React component wrapped inside ErrorBoundary
 */
export const withErrorBoundary = <P,>(
  Component: React.ComponentType<P>,
  boundaryProps: ErrorBoundaryProps = {},
): React.FC<P> => {
  const WrappedComponent: React.FC<P> = (props) => (
    <ErrorBoundary {...boundaryProps}>
      <Component {...props} />
    </ErrorBoundary>
  );

  WrappedComponent.displayName = `WithErrorBoundary(${Component.displayName || Component.name || 'Component'})`;

  return WrappedComponent;
};

export default ErrorBoundary;
