/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable max-len */
import Script from 'next/script';

const Bombora = () => (
  <Script
    id='bombora'
    dangerouslySetInnerHTML={{
      __html: `
        !function(e,t,c,n,o,a,m){e._bmb||(o=e._bmb=function(){o.x?o.x.apply(o,arguments):o.q.push(arguments)},o.q=[],a=t.createElement(c),a.async=true,a.src="https://vi.ml314.com/get?eid=91560&tk=pz1S3PHbmG2bxjdly0eNosi1ACrbASlXUkF1oGj39mY03d&fp="+(e.localStorage&&e.localStorage.getItem(n)||""),m=t.getElementsByTagName(c)[0],m.parentNode.insertBefore(a,m))}(window,document,"script","_ccmaid");
        window.googletag = window.googletag || {cmd: []};
        googletag.cmd.push(function() {
          _bmb('vi', function(data) {
            if (data != null) {
              var tmpSegment = [
                data.industry_id,
                data.revenue_id,
                data.size_id,
                data.functional_area_id,
                data.professional_group_id,
                data.seniority_id,
                data.decision_maker_id,
                data.install_data_id,
                data.topic_id,
                data.interest_group_id,
                data.segment,
                data.b2b_interest_cluster_id
                ].filter(Boolean).join(',');

              tmpSegment != '' && googletag.pubads().setTargeting("bmb",tmpSegment.split(','));
            }
          });
        });
      `,
    }}
  />
);

export default Bombora;
