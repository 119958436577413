import { MenuItemProps } from 'interfaces/navigation/Header';
import Link from 'components/Globals/Base/Link';
import { useEffect, useMemo, useState } from 'react';
import { depthOf } from 'utils/objectUtils';
import * as S from './MenuItemStyles';

const getPathFromUrl = (url: string) => {
  try {
    const path = new URL(url).pathname;
    return path;
  } catch (e) {
    return url;
  }
};

interface ExtendedMenuItemProps extends MenuItemProps {
  isExpanded?: boolean;
  currentUrl: string;
}

const MenuItem = (props: ExtendedMenuItemProps) => {
  const { label, subMenu, url, nestingLevel, currentUrl, isExpanded = false, onKeyDown } = props;
  const path = getPathFromUrl(url);
  const [isOpened, setIsOpened] = useState(false);
  const maxNestingLevel = useMemo<number>(() => {
    if (!nestingLevel) {
      const depth: number = depthOf<MenuItemProps>(props, 'subMenu');
      return depth;
    }
    // eslint-disable-next-line react/destructuring-assignment
    return props.maxNestingLevel as number;
  }, [nestingLevel, props]);

  const trackingData = {
    contentPlacementCD: 'Global Side navigation',
    eventAction: `Global Side navigation - level ${nestingLevel || '0'}`,
    eventCategory: 'navigation',
  };
  useEffect(() => {
    if (currentUrl?.includes(path)) {
      setIsOpened(true);
    }
  }, [currentUrl, path]);

  const menuItem = (
    <S.Item
      className={`level-${nestingLevel || 0} ${isOpened ? 'open' : 'close'}`}
      $nestingLevel={nestingLevel || 0}
      $subMenu={nestingLevel !== maxNestingLevel}
      data-cy={`itemLink${nestingLevel || 0}`}
    >
      <Link
        href={url}
        trackingData={{ ...trackingData, eventLabel: label }}
        onKeyDown={onKeyDown}
      >
        {label}
      </Link>
      {subMenu && !isExpanded ? (
        <S.Icon
          className={isOpened ? 'arrow up' : 'arrow'}
          onClick={() => setIsOpened((prev) => !prev)}
          type='button'
        >
          <span className='arrow' />
        </S.Icon>
      ) : null}
    </S.Item>
  );

  return subMenu ? (
    <>
      {menuItem}
      <S.Submenu
        $visibility={isOpened || isExpanded ? 'true' : 'false'}
        className={`submenu ${isExpanded ? 'fortune-sections' : ''}`}
        data-cy={`subMenuLevel${nestingLevel || 0}`}
      >
        {subMenu.map((submenuItem) => (
          <MenuItem
            key={submenuItem.id}
            id={submenuItem.id}
            label={submenuItem.label}
            url={submenuItem.url}
            subMenu={submenuItem.subMenu}
            currentUrl={currentUrl}
            nestingLevel={(nestingLevel || 0) + 1}
            maxNestingLevel={maxNestingLevel}
          />
        ))}
      </S.Submenu>
    </>
  ) : (
    menuItem
  );
};

export default MenuItem;
