import { useState, useEffect } from 'react';

/**
 * Hook that returns true when running in the browser, and false during SSR.
 * Usefull for pieces of code that needs to run in the browser (like ads)
 *
 * @example
 * const isBrowser = useIsBrowser();
 * if (isBrowser) {
 *    // some browser specific code
 * }
 */
export default function useIsBrowser(): boolean {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => setIsMounted(true), []);

  return isMounted;
}
