/* eslint-disable sort-keys-fix/sort-keys-fix */
import { css } from 'styled-components';
import { MicrositeType, Microsites } from 'constants/constants';
import { font } from 'styles/globals';
import { commonColors, brandColors } from 'styles/colors';
import { bodySizes, bodyWeight, graphikCondSizes, saolSizes } from 'styles/typography/typeFonts';
import { ThemeProps } from './types';
import themeMethods from './methods';

export type Theme = ThemeProps;
export const { getColor, getBodyStyle } = themeMethods;

const theme = (microsite: MicrositeType): Theme => {
  const isEducation = Microsites.Education === microsite;
  const fallbackDestination = isEducation ? Microsites.Recommends : Microsites.Own;
  return {
    microsite,
    colors: {
      ...commonColors,
      ...(brandColors[microsite] || brandColors[fallbackDestination]),
    },
    typography: {
      bodyText: {
        bodyTextFonts: {
          georgia: {
            custom: css`
              font-family: ${font.georgia};
            `,
            sizes: bodySizes,
            weights: bodyWeight,
          },
          graphikCompact: {
            custom: css`
              font-family: ${font.graphikCompact};
            `,
            sizes: bodySizes,
            weights: bodyWeight,
          },
          graphikCond: {
            custom: css`
              font-family: ${font.graphikCond};
            `,
            sizes: bodySizes,
            weights: bodyWeight,
          },
        },
        defaultBodyTextStyles: css`
          line-height: 150%;
          color: ${commonColors.textPrimary};
          letter-spacing: 0.5px;
          font-weight: 400;
        `,
      },
      headings: {
        defaultHeadingStyles: css`
        font-weight: 600;
        line-height: 120%;
        color: ${commonColors.textPrimary};
      `,
        headingFonts: {
          graphikCond: {
            custom: css`
            font-family: ${font.graphikCond};
            letter-spacing: 0.5px;
          `,
            sizes: graphikCondSizes,
          },
          saol: {
            custom: css`
            font-family: ${font.saolText};
            letter-spacing: 0;
          `,
            sizes: saolSizes,
          },
        },
      },
    },
  };
};

export default theme;
