export const clearHTMLString = (htmlString: string) => {
  if (!htmlString) return '';
  const entities: { [key: string]: string } = {
    '&#39;': "'",
    '&#8217;': '’',
    '&amp;': '&',
    '&gt;': '>',
    '&lt;': '<',
    '&nbsp;': '',
    '&quot;': '"',
    '<em></em>': '',
    // Add more HTML entities and their replacements as needed
  };
  const tagRegex = /<[^>]+>/g;
  return htmlString
    .replace(new RegExp(Object.keys(entities).join('|'), 'g'), (match) => entities[match])
    .replace(tagRegex, '');
};

export const ensureUrlTrailingSlash = (url: string) => `${url}${url.endsWith('/') ? '' : '/'}`;

export const removeNonstandardSpaces = (text: string) =>
  text.replace(/[\u00A0\u1680\u180E\u2000-\u200B\u202F\u205F\u3000\uFEFF]/g, ' ');

export const scrubContent = (content: string): string => {
  let scrubbedContent = content;

  if (/<div class="footer(.|\n)*/gm.test(content)) {
    scrubbedContent = scrubbedContent.replace(/<div class="footer(.|\n)*/gm, '');
    if (/(<div class="paywall")|(<div class="regwall")/gm.test(content)) {
      scrubbedContent += '</div>'; // Replaces the closing tag for Pay/Regwall <div> that would be deleted by above
    }
  }

  scrubbedContent = scrubbedContent.replace(/<div class="article">([\s\S]*)<\/div>/gm, '$1');
  scrubbedContent = scrubbedContent.replace(/<div class="article-content">([\s\S]*)<\/div>/gm, '$1');
  return scrubbedContent;
};

export const generateAlphabet = () => Array.from({ length: 26 }, (item, i) => String.fromCharCode(65 + i));

export const validateAlphabetLetter = (letter: string) => {
  if (letter.length !== 1 || !letter.match(/[A-Z]/i)) {
    return false;
  }

  return true;
};

export const ellipsis = (title: string, count: number) => {
  if (title?.length > count) {
    const shortTitle = title.substring(0, count);
    return `${shortTitle.substr(0, Math.min(shortTitle.length, shortTitle.lastIndexOf(' ')))}...`;
  }

  return title;
};

export const isUrl = (str: string): boolean => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(str);
};

export const isValidUrl = (url: string) => {
  try {
    return !!new URL(url);
  } catch (error) {
    return false;
  }
};

export const getQueryVariable = (name: string) => {
  const query = window.location.search.substring(1).split('&');
  for (let i = 0; i !== query.length; i += 1) {
    const pair = query[i].split('=');
    if (decodeURIComponent(pair[0]) === name) {
      return decodeURIComponent(pair[1]);
    }
  }
  return '';
};

export const isValidBirthday = (str: string): boolean => {
  const pattern = /\b(0[1-9]|1[0-2]) \/ (1[0-9]{3}|20[0-2][0-9])\b/g;
  return !!pattern.test(str);
};

export const isValidEmail = (str: string): boolean => {
  // eslint-disable-next-line no-useless-escape
  const pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  return !!pattern.test(str);
};

export const span = (value: string, length: number) => Array.from({ length }, () => value).join(' ');

export const substractLastSlash = (slug: string) => {
  if (slug.substring(slug.length - 2) === '//') {
    return slug.slice(0, -1);
  }
  return slug;
};

export const joinWordsByChar = (text: string, char: string): string => {
  const words = text.toLowerCase().split(' ');

  const joinByChar = words.join(char);

  return joinByChar;
};

export const insertAfterFirstWord = (text: string, insertedWord: string): string => {
  const array = text.split(' ');
  array.splice(1, 0, insertedWord);

  return array.join(' ');
};

export const toSnakeCase = (text: string) => joinWordsByChar(text, '_');
// Trims leading and trailing slashes from a string.
export const trimSlashes = (value: string): string => value?.replace(/^\/|\/$/g, '') || '';

export function objectToLiteralString(obj: {}) {
  const results: string[] = Object.entries(obj).map(([key, value]) => {
    if (typeof value === 'object' && value !== null) {
      return `${key}:${objectToLiteralString(value)}`;
    }
    if (typeof value === 'string') {
      return `${key}:"${value}"`;
    }
    return `${key}:${value}`;
  });
  return `{${results.join(',')}}`;
}

/* 
  @description: This function generates random string of `length` characters
*/
export const createRandomString = (length: number) => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
};
