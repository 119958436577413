import styled from 'styled-components';
import { breakpoint, font } from 'styles/globals';

export const FooterNavigationMenuWrapper = styled.ul`
  font-family: ${font.graphikCond};
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  list-style: none;
  display: grid;
  gap: 16px;
`;

interface MenuItemWrapperProps {
  key: string;
}

export const MenuItemWrapper = styled.li<MenuItemWrapperProps>`
  @media (${breakpoint.mdMin}) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    }

  > a {
    letter-spacing: 1px;
    text-decoration: none;
  }
`;
