import styled from 'styled-components';
import { getColor, Theme } from 'styles/theme';

export const LoaderWrapper = styled.div<{ theme: Theme }>`
  align-items: center;
  background-color: ${getColor('surfaceWhite')};
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100vw;
  z-index: 99999;

  .logo-fortune-animated {

    path {
      animation: dash 10s linear infinite;
      stroke-dasharray: 200;
    }
  
    @keyframes dash {
      
      to {
        stroke-dashoffset: 2000;
      }
    }
  }
`;

export default LoaderWrapper;
