import { HtmlInhouseAdProps, InhouseAdProps, InstreamInhouseAdProps, StickyInhouseAdProps } from 'interfaces/ads/Ad';
import cookies from 'js-cookie';
import { PageType } from 'interfaces/content/articles/Post';
import { transformInhouseAd } from 'utils/inhouseAdHelpers';
import { PERSONALIZATION_API_HOST, PERSONALIZATION_API_KEY } from './constants';

export type PersonalizedAds = { [pageType: string]: { [placement: string]: { [index: string]: InhouseAdProps } } };
export type PersonalizedAdWidgets = {
  [pageType: string]: {
    [placement: string]: { [index: string]: InstreamInhouseAdProps | StickyInhouseAdProps | HtmlInhouseAdProps };
  };
};
interface QueryParams {
  id: string;
  type: string;
  pageType?: PageType;
  customAd?: any;
}

const getPersonalizedAds = async ({
  pageType,
  placementName,
  customAd,
  currentSection,
  currentTags,
  currentAuthors,
}: {
  pageType: PageType;
  placementName: string;
  customAd?: string | null;
  currentSection?: string;
  currentTags?: string;
  currentAuthors?: string;
}) => {
  const anonId = cookies.get('ajs_anonymous_id');
  const userId = cookies.get('ajs_user_id');
  try {
    const queryParams: QueryParams = {
      id: `${userId || anonId}`,
      type: userId ? 'user_id' : 'anonymous_id',
      ...(pageType && { pageType }),
      ...(placementName && { placementName }),
      // current section/topics/authors - for ads related to current page view
      ...(currentSection && { currentSection }),
      ...(currentTags && { currentTags }),
      ...(currentAuthors && { currentAuthors }),
      // uc - only for testing purposes
      ...(customAd && { uc: customAd }),
    };

    const queryParamsObject: Record<string, string> = {
      ...queryParams,
    };

    const response = await fetch(
      `${PERSONALIZATION_API_HOST}/getCustomContent?${new URLSearchParams(queryParamsObject).toString()}`,
      {
        headers: {
          'x-api-key': PERSONALIZATION_API_KEY,
        },
        method: 'GET',
      },
    );

    if (!response.ok) throw new Error('Errored!');
    const inhouseAds = (await response.json()) as PersonalizedAds;

    return {
      [pageType]: {
        [placementName]: {
          1: transformInhouseAd(pageType, placementName, 1, inhouseAds[pageType][placementName][1]),
        },
      },
    } as PersonalizedAdWidgets;
  } catch (error) {
    // getPersonalizedAds failed
    console.error(error);
    return null;
  }
};
export default getPersonalizedAds;
