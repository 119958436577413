import React from 'react';
import ADS_TEST_IDS from 'constants/testsIds/ads';
import useUniqueAdId from 'hooks/useUniqueAdId';
import { PageType } from 'interfaces/content/articles/Post';
import { AdsCenteredDiv, InStreamAd } from './Styles';
import AdSlotToggle from './AdSlotToggle';

interface InStreamProps {
  index: number;
  className?: string;
  renderAds: boolean;
  dataCy?: string;
  delay?: number;
  pageType?: PageType;
}

const InStream: React.FC<InStreamProps> = ({ index, className, renderAds, dataCy, delay, pageType }: InStreamProps) => {
  const inContentPageTypes = [PageType.ARTICLE_V2, PageType.COMPANY_V2];
  const adId = useUniqueAdId();

  if (renderAds) {
    return (
      <AdsCenteredDiv>
        <InStreamAd className={`${className || 'simple'}`}>
          <AdSlotToggle
            pageType={pageType}
            placementName={pageType && inContentPageTypes.includes(pageType) ? 'InContent' : 'InStream'}
            index={index + 1}
            slotId={`InStream${adId}`}
            dataCy={`${ADS_TEST_IDS.IN_STREAM}-${adId}`}
            personalized={false}
            delay={delay}
            // personalized InStream Ads are turned off for now
            // personalized={index === 0 && (pageType === PageType.ARTICLE || pageType === PageType.ARTICLE_V2)}
          />
        </InStreamAd>
      </AdsCenteredDiv>
    );
  }

  return <div data-cy={dataCy} />;
};

export default InStream;
