import styled from 'styled-components';
import { breakpoint, font } from 'styles/globals';
import { getBodyStyle, getColor, Theme } from 'styles/theme';

export const Wrapper = styled.div<{ theme: Theme }>`
  background-color: ${getColor('shadeBrandPrimary50')};
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px;

  @media (min-width: ${breakpoint.mdMin}) {
    gap: 24px;
  }
`;

export const Title = styled.div<{ theme: Theme }>`
  font-family: ${font.saolText};
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  
  @media (${breakpoint.mmMin}) {
    font-size: 24px;
    line-height: 28px;
  }
`;

export const CardContainer = styled.div<{ numOfItems: number }>`
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
  
  > * {
    flex: 1 1 ${(props) =>
    (props.numOfItems < 2 ? `calc(100% / ${props.numOfItems} - 20px)` : `calc(100% / 2 - 20px)`)};
    max-width: ${(props) =>
    (props.numOfItems < 2 ? `calc(100% / ${props.numOfItems} - 20px)` : `calc(100% / 2 - 20px)`)};
    margin: 10px;
  }

  @media (${breakpoint.mdMin}) {
    > * {
      flex: 1 1 ${(props) =>
    (props.numOfItems < 3 ? `calc(100% / ${props.numOfItems} - 20px)` : `calc(100% / 3 - 20px)`)};
      max-width: ${(props) =>
    (props.numOfItems < 3 ? `calc(100% / ${props.numOfItems} - 20px)` : `calc(100% / 3 - 20px)`)};
    }
  }

  @media (${breakpoint.lgMin}) {
    > * {
      flex: 1 1 ${(props) =>
    (props.numOfItems < 6 ? `calc(100% / ${props.numOfItems} - 20px)` : `calc(100% / 6 - 20px)`)};
      max-width: ${(props) =>
    (props.numOfItems < 6 ? `calc(100% / ${props.numOfItems} - 20px)` : `calc(100% / 6 - 20px)`)};
    }
  }
`;

export const Card = styled.a<{ theme: Theme }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 90px;
  align-items: center;
  gap: 8px;
  background-color: ${getColor('surfaceBrand')};
  text-decoration: none;
  padding: 20px;
  border-radius: 8px;

  &:hover, &:focus {
    background-color: ${getColor('shadeBrandPrimary900')};
    color: ${getColor('textWhite')}; 
  }

  &:focus {
    box-shadow: 0px 0px 7px 0px ${getColor('textLink')};
  }  
  
  &:active {
    background-color: ${getColor('surfaceBrand')};
    box-shadow: 0px 0px 7px 0px ${getColor('textLink')};
  }

  .img {
    height: 30px!important;
    object-fit: contain!important;
  }
`;

export const CardText = styled.p<{ theme: Theme }>`
  text-align: center;
  ${getBodyStyle('graphikCond', { default: 'medium' }, 'semiBold')};
  color: ${getColor('textWhite')};
`;
