import { createContext, useCallback, useState } from 'react';
import { PageType } from 'interfaces/content/articles/Post';
import { PlacementName, HtmlInhouseAdProps, InstreamInhouseAdProps, StickyInhouseAdProps } from 'interfaces/ads/Ad';
import getPersonalizedAds, { PersonalizedAdWidgets } from 'lib/personalizationApi/getPersonalizedAds';
import merge from 'lodash.merge';

interface PersonalizationProviderProps {
  children: JSX.Element;
}

export interface PersonalizationContextInterface {
  performAdUpdates: (
    pageType: PageType,
    placement: PlacementName | 'Sticky',
    section?: string,
    tags?: string,
    authors?: string,
  ) => Promise<void>;
  getAdForSlot: (
    pageType: PageType,
    placement: PlacementName | 'Sticky',
    index: number,
  ) => InstreamInhouseAdProps | StickyInhouseAdProps | HtmlInhouseAdProps | null;
}

export const PersonalizationContext = createContext<PersonalizationContextInterface>({
  getAdForSlot: () => null,
  performAdUpdates: () => Promise.resolve(),
});

const usePersonalizationContext = () => {
  const [ads, setAds] = useState<PersonalizedAdWidgets>({});

  const performAdUpdates = async (
    pageType: PageType,
    placement: string,
    section?: string,
    tags?: string,
    authors?: string,
  ) => {
    // only for testing purposes, on dev env, an use case can be manually set by adding it in url and send it as query param to the API
    const isDevelopment = process.env.ENVIRONMENT !== 'prod';
    const urlParams = new URLSearchParams(window.location.search);
    const ucParamsValue = isDevelopment ? urlParams.get('uc') : null;

    // call personalization api to retrieve newer personalized ad content
    return getPersonalizedAds({
      currentAuthors: authors,
      currentSection: section,
      currentTags: tags,
      customAd: ucParamsValue,
      pageType,
      placementName: placement,
    }).then((fetchedAds) => {
      if (fetchedAds) {
        // update ad slot if page requests personalized inhouse ads
        setAds((prevAds) => merge({ ...prevAds }, { ...fetchedAds }));
      }
    });
  };

  const getAdForSlot = useCallback(
    (pageType: PageType, placement: PlacementName | 'Sticky', index: number) => {
      let ad: InstreamInhouseAdProps | StickyInhouseAdProps | HtmlInhouseAdProps | null = null;

      if (ads[pageType] && ads[pageType][placement] && ads[pageType][placement][index]) {
        ad = ads[pageType][placement][index];
      }

      return ad;
    },
    [ads],
  );

  return {
    getAdForSlot,
    performAdUpdates,
  };
};

/* eslint-disable react/prop-types */
export const PersonalizationProvider: React.FC<PersonalizationProviderProps> = ({ children }) => {
  const personalizationContextValue = usePersonalizationContext();
  return (
    <PersonalizationContext.Provider value={personalizationContextValue}>{children}</PersonalizationContext.Provider>
  );
};
