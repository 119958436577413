const DangerIcon = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_2757_20476)'>
      <path d='M11.0822 2.62354L18.2772 15.0852C18.3869 15.2752 18.4446 15.4908 18.4446 15.7102C18.4446 15.9296 18.3869 16.1452 18.2772 16.3352C18.1675 16.5252 18.0097 16.683 17.8196 16.7927C17.6296 16.9024 17.4141 16.9602 17.1947 16.9602H2.80466C2.58524 16.9602 2.36969 16.9024 2.17967 16.7927C1.98965 16.683 1.83185 16.5252 1.72215 16.3352C1.61244 16.1452 1.55469 15.9296 1.55469 15.7102C1.55469 15.4908 1.61245 15.2752 1.72216 15.0852L8.91716 2.62354C9.39799 1.7902 10.6005 1.7902 11.0822 2.62354ZM9.99966 4.08187L3.52632 15.2935H16.473L9.99966 4.08187ZM9.99966 12.5002C10.2207 12.5002 10.4326 12.588 10.5889 12.7443C10.7452 12.9006 10.833 13.1125 10.833 13.3335C10.833 13.5545 10.7452 13.7665 10.5889 13.9228C10.4326 14.0791 10.2207 14.1669 9.99966 14.1669C9.77864 14.1669 9.56668 14.0791 9.4104 13.9228C9.25412 13.7665 9.16632 13.5545 9.16632 13.3335C9.16632 13.1125 9.25412 12.9006 9.4104 12.7443C9.56668 12.588 9.77864 12.5002 9.99966 12.5002ZM9.99966 6.66687C10.2207 6.66687 10.4326 6.75467 10.5889 6.91095C10.7452 7.06723 10.833 7.27919 10.833 7.5002V10.8335C10.833 11.0545 10.7452 11.2665 10.5889 11.4228C10.4326 11.5791 10.2207 11.6669 9.99966 11.6669C9.77864 11.6669 9.56668 11.5791 9.4104 11.4228C9.25412 11.2665 9.16632 11.0545 9.16632 10.8335V7.5002C9.16632 7.27919 9.25412 7.06723 9.4104 6.91095C9.56668 6.75467 9.77864 6.66687 9.99966 6.66687Z' fill='#111111'/>
    </g>
    <defs>
      <clipPath id='clip0_2757_20476'>
        <rect width='20' height='20' fill='white'/>
      </clipPath>
    </defs>
  </svg>
);

export default DangerIcon;