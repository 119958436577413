import { calculateWhereToInsertEmbeds, insertElementsIntoTree } from 'utils/miscUtils/childrenUtils';
import { InStreamListConfig } from 'interfaces/ads/Ad';
import { PageType } from 'interfaces/content/articles/Post';
import InStream from './InStream';

const insertAds = (
  elements: JSX.Element,
  frequency: number,
  renderAds: boolean,
  lastPWithoutAds?: number,
  pageType?: PageType,
) => {
  if (frequency === 0) return elements;

  const insertAdAfter = calculateWhereToInsertEmbeds(elements, frequency, lastPWithoutAds);
  const insertElementsArray = insertAdAfter.map((after, index) => ({
    after,
    node: (
      <InStream
        index={index}
        // eslint-disable-next-line react/no-array-index-key
        key={`ad-${index}`}
        delay={0}
        renderAds={renderAds}
        pageType={pageType}
      />
    ),
  }));
  return insertElementsIntoTree(elements, insertElementsArray);
};

const InStreamList = ({ frequency, children, lastPWithoutAds, renderAds, pageType }: InStreamListConfig) => (
  <div>{insertAds(children, frequency, renderAds, lastPWithoutAds, pageType)}</div>
);

export default InStreamList;
