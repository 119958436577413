import { InstreamInhouseAdProps } from 'interfaces/ads/Ad';
import InhouseAdContainter from 'components/Ad/InhouseAd/InhouseAdStyles';
import useBreakpoint from 'hooks/useBreakpoint';
import { NextCustomImage } from 'components/Image';
import { getGtmTrackingHtmlProps } from 'utils/inhouseAdHelpers';
import { useCallback, useEffect } from 'react';
import { InhouseAdTrackingObject } from 'services/Gtm/entities';
import { pushToDataLayer } from 'services/Gtm/functions';

const AD_CONTAINER_CLASSNAME = 'fortune-inhouse-ad';

const InStream = ({
  inhouseAd,
  pageType,
  placementName,
}: {
  inhouseAd: InstreamInhouseAdProps;
  pageType: string;
  placementName: string;
}) => {
  const isLgMin = useBreakpoint('lgMin');
  const isXlMin = useBreakpoint('xlMin');

  const adSlotType = pageType === 'article-v2' ? 'wide' : 'normal';
  const currentAdFormat = (adSlotType === 'wide' ? isLgMin : isXlMin) ?
    { ...inhouseAd.desktopImage, height: 500, width: 1940 } :
    { ...inhouseAd.mobileImage, height: 500, width: 600 };

  const getTrackingEvent = useCallback(
    (event: string): InhouseAdTrackingObject => ({
      adId: inhouseAd.adId,
      event,
      placementId: `${pageType}-${placementName}`,
      ...inhouseAd.tracking,
    }),
    [inhouseAd],
  );

  useEffect(() => {
    // Ad load event
    // This does not count as an impression
    // In fact, this helps us find out how many ad displays
    // in page have actually generated impressions
    pushToDataLayer(getTrackingEvent('fortune.inhouseAd.request'));
  }, [getTrackingEvent]);

  return (
    <InhouseAdContainter
      className={AD_CONTAINER_CLASSNAME}
      // Spread HTML tracking props for GTM
      {...getGtmTrackingHtmlProps(pageType, placementName, inhouseAd)}
      adSlotType={adSlotType}
      href={inhouseAd.cta.href}
      aria-label={inhouseAd.cta.text}
      target={inhouseAd.cta.blank}
    >
      <NextCustomImage
        alt={inhouseAd.name}
        src={currentAdFormat.src}
        width={currentAdFormat.width}
        height={currentAdFormat.height}
      />
    </InhouseAdContainter>
  );
};

export default InStream;
