import React, { ErrorInfo } from 'react';
import logToBackend from 'utils/log/logToBackend';
import { AppErrorSeverity } from 'utils/log/constants/errorSeverity';
import { LoggerTags } from 'utils/log/constants/loggerTags';
import { UIFragments } from '../../lib/utils/log/constants/uiFragments';

interface ErrorBoundaryBaseProps {
  // Magic parameter passed by <ErrorBoundary />
  // Used to throw warnings to the console, in case this is used standalone
  // (which is not recommended)
  __unsafe_standalone_use?: boolean;
  severity?: AppErrorSeverity;
  fragment?: UIFragments;
  fallback: React.ReactNode;
  children: React.ReactNode;
}

interface ErrorBoundaryBaseState {
  hasError: boolean;
}

/**
 * Base Error Boundary component implementation
 *
 * DO NOT USE THIS DIRECTLY
 *
 * USE `<ErrorBoundary />` INSTEAD
 */
class ErrorBoundaryBase extends React.Component<ErrorBoundaryBaseProps, ErrorBoundaryBaseState> {
  constructor(props: ErrorBoundaryBaseProps) {
    super(props);
    this.state = { hasError: false };

    // Warn the developer if this component is used instead of <ErrorBoundary />
    // This is domain-specific code where we can ignore ESLint's complaints
    /* eslint-disable camelcase */
    /* eslint-disable no-console */
    const { __unsafe_standalone_use = true } = props;
    if (__unsafe_standalone_use) {
      console.warn(
        'You are using <ErrorBoundaryBase /> as a standalone component, which is not the intended use of Error Boundaries in this application.',
      );
      console.warn('Please use <ErrorBoundary /> instead.');
    }
    /* eslint-enable camelcase */
    /* eslint-enable no-console */
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { fragment, severity } = this.props;

    logToBackend(LoggerTags.UI_ERROR, error.name, {
      ...(fragment ? { fragment } : {}),
      message: error.message,
      severity: severity ?? AppErrorSeverity.NORMAL,
      traceback: errorInfo.componentStack,
      url: window.location.href,
    });
  }

  render() {
    const { children, fallback } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return fallback;
    }

    return children;
  }
}

export default ErrorBoundaryBase;
