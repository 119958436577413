import { CompanyRankingList } from 'api/fragments';

export function omitKeys<T extends { [key: string]: any }>(obj: T, keys: string[]): T {
  const newObj = { ...obj };
  keys.forEach((key) => {
    delete newObj[key];
  });
  return newObj;
}

export function omitKeysArray<T extends { [key: string]: any }>(obj: T[], keys: string[]): T[] {
  return obj.map((el) => omitKeys(el, keys));
}

export function depthOf<T>(object: T, accessor: string, level = 0): number {
  // @ts-ignore
  const children = object[accessor] as T[] | undefined;
  let newLevel = level;
  if (children) {
    children.forEach((child) => {
      newLevel = Math.max(newLevel, depthOf(child, accessor, level + 1));
    });
  }
  return newLevel;
}

export const showRankValue = (ranking: CompanyRankingList) => {
  let showRank = true;

  if (ranking.hideRanking === null || ranking.hideRanking || (!ranking.hideRanking && ranking.rank === null))
    showRank = false;

  return showRank;
};
