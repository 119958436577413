import Link from 'components/Globals/Base/Link';
import { MenuItemProps } from 'interfaces/navigation/Header';
import { EventData } from 'services/Gtm/entities';
import * as S from './SubmenuStyles';

interface SubMenuItemProps extends MenuItemProps {
  level: number;
  parentLabel: string;
  trackingData: EventData;
}

const SubMenuItem = ({ label, url, subMenu, level, parentLabel, trackingData }: SubMenuItemProps) => (
  <>
    <S.MenuItemSubLi $padding={level * 8}>
      <Link
        href={url || undefined}
        ariaLabel={`Go to ${label}`}
        trackingData={{
          ...trackingData,
          eventAction: `${trackingData.eventAction} - level ${level}`,
          eventLabel: `${parentLabel} > ${label}`,
        }}
        className='submenu-link'
      >
        {label}
      </Link>
    </S.MenuItemSubLi>
    {subMenu &&
      subMenu.map((menuItem: MenuItemProps) => (
        <SubMenuItem
          key={menuItem.label}
          {...menuItem}
          trackingData={trackingData}
          level={level + 1}
          parentLabel={parentLabel}
        />
      ))}
  </>
);

export default SubMenuItem;
