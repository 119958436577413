import BinanceTickerWrapper from './BinanceTickerBarStyles';

const BinanceTickerBar = () => (
  <BinanceTickerWrapper
    className='binance-widget-marquee'
    data-cmc-ids='1,1027,5426,52,2010,74,5994,5805,7083,3408'
    data-theme='light'
    data-transparent='false'
    data-locale='en'
    data-powered-by='Powered by'
    data-disclaimer='Disclaimer'
    data-cy='ticker'
  />
);

export default BinanceTickerBar;
