import React from 'react';
import styled, { css, Interpolation } from 'styled-components';
import { color } from 'styles/globals';
import { getColor, Theme } from 'styles/theme';

export const loadingBackgroundSize = '600px';

export const loadingBackground = css<{ theme: Theme }>`
  animation: opacity 2.5s both;
  background-color: ${color.lightGray};
  background-image: linear-gradient(
    90deg,
    ${getColor('neutralDarkGray')}00 20%,
    ${getColor('neutralDarkGray')}1A 50%,
    ${getColor('neutralDarkGray')}00 80%
  );
  background-size: ${loadingBackgroundSize};
  background-position: 0 0;

  @keyframes opacity {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
`;

const Wrapper = styled.div<{ $size: Interpolation<React.CSSProperties>; theme: Theme }>`
  ${({ $size }) => $size}
  ${loadingBackground};
`;

export default Wrapper;
