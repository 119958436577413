import React, { useContext, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { PersonalizationContext } from 'providers/PersonalizationProvider';
import {
  ProgrammaticAdInfo,
  HtmlInhouseAdProps,
  InstreamInhouseAdProps,
  StickyInhouseAdProps,
} from 'interfaces/ads/Ad';
import { AdSlotExtended } from 'interfaces/ads/AdSlot';
import { PageType } from 'interfaces/content/articles/Post';
import InhouseAd from 'components/Ad/InhouseAd';
import sponsoredLineItemIds from 'services/Ad/utils/sponsoredLineItemIds';

let interval: NodeJS.Timeout | null = null;

const AdSlot: React.FC<AdSlotExtended> = ({
  adContext,
  component,
  pageType = PageType.NO_TYPE,
  placementName,
  index,
  slotId,
  dataCy = '',
  delay = 0,
  personalized = false,
  hideGAM,
}: AdSlotExtended) => {
  const {
    initialized: adLibInitialized,
    getPlacementConfig: getAdSlotConfig,
    displayAdSlot,
    registerAdSlotRenderCallback,
  } = adContext;
  const [isLibAdSponsored, setIsLibAdSponsored] = useState(!hideGAM);
  const hasLibAdDisplayed = useRef(false);
  const isRenderCallbackRegistered = useRef(false);
  const [adPreloaded, setAdPreloaded] = useState(false);
  const { performAdUpdates: performInhouseAdUpdates, getAdForSlot: getInhouseAdForSlot } =
    useContext(PersonalizationContext);
  const [inhouseAd, setInhouseAd] = useState<InstreamInhouseAdProps | StickyInhouseAdProps | HtmlInhouseAdProps | null>(
    null,
  );
  const placementConfig = getAdSlotConfig(placementName);
  const slotConfig = {
    id: slotId,
    index,
    path: placementConfig.path,
    placementName,
  };

  const [adSlotRef, isAdSlotInViewport] = useInView({ rootMargin: '-400px 0px 0px 0px', threshold: 0 });

  useEffect(() => {
    if (personalized) {
      // eslint-disable-next-line no-void
      void performInhouseAdUpdates(pageType, placementName);
    }
  }, []);

  useEffect(() => {
    if (adLibInitialized && !hideGAM && delay) {
      setTimeout(() => {
        displayAdSlot(slotConfig);
        setAdPreloaded(true);
      }, delay);
    }
  }, [adLibInitialized, hideGAM]);

  useEffect(() => {
    if (personalized) {
      // search for ad in persisted ads
      const adData = getInhouseAdForSlot(pageType, placementName, index);
      setInhouseAd(adData);
    }
  }, [getInhouseAdForSlot, index, placementName, pageType]);

  const adSlotRenderedCallback = (event: ProgrammaticAdInfo) => {
    const { lineItemId } = event;
    setIsLibAdSponsored(lineItemId !== null && sponsoredLineItemIds.includes(lineItemId));
  };

  useEffect(() => {
    if (adLibInitialized && !hideGAM) {
      if (!isRenderCallbackRegistered.current) {
        registerAdSlotRenderCallback(slotId, adSlotRenderedCallback);
        isRenderCallbackRegistered.current = true;
      }

      if (isAdSlotInViewport && (!inhouseAd || isLibAdSponsored)) {
        if (!hasLibAdDisplayed.current) {
          hasLibAdDisplayed.current = true;
          if (!adPreloaded) displayAdSlot(slotConfig);
          interval = setInterval(() => {
            displayAdSlot(slotConfig);
          }, placementConfig.refreshRate * 999999);
        }
      } else if (interval) {
        // Stop refreshing ads if not in viewport
        clearInterval(interval);
        // TODO if sponsored ad, destroy ad slot in gam; see warning console
      }
    }
  }, [
    displayAdSlot,
    getAdSlotConfig,
    isAdSlotInViewport,
    adLibInitialized,
    placementName,
    slotId,
    index,
    inhouseAd,
    adSlotRenderedCallback,
    isLibAdSponsored,
    registerAdSlotRenderCallback,
    hideGAM,
  ]);

  const Comp = component || 'div';

  // don't show any ad if GAM is hidden and there is no personalised inhouse ad available
  if (!inhouseAd && hideGAM) {
    return null;
  }

  if (inhouseAd && !isLibAdSponsored) {
    // override default GAM ad with an inhouse ad
    return (
      <InhouseAd
        inhouseAd={inhouseAd}
        pageType={pageType}
        placementName={placementName}
        data-cy={dataCy}
      />
    );
  }
  return (
    <Comp
      id={slotId}
      ref={adSlotRef}
      data-cy={dataCy}
    />
  );
};

export default AdSlot;
