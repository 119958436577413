// import setUtmParams from 'utils/adsUtmParams';
import { withErrorBoundary } from 'components/ErrorBoundary';
import { UIFragments } from 'utils/log/constants/uiFragments';
import { ParseEduAdWidgets } from 'utils/miscUtils/rawHtml';
import * as S from './PaidPlacementStyles';

export enum AD_TAGS {
  EDX = 'edx',
  ART_INTEL = 'art-intel',
  COUNSELING = 'counseling',
  BOOTCAMP = 'bootcamps',
  ACCOUNTING = 'accounting',
  BOARDING_SCHOOLS = 'boarding-schools',
  BUSINESS = 'business',
  SOCIAL_WORK = 'social-work',
  EDUCATION = 'education',
  NURSING = 'nursing',
  COMPUTER_SCIENCE = 'comp-sci',
  ANALYTICS = 'analytics',
  CYBERSECURITY = 'cyber',
  DATA_SCIENCE = 'data-science',
  EXECUTIVE_EDUCATION = 'executive-education',
  PSYCHOLOGY = 'psychology',
  PUBLIC_HEALTH = 'public-health',
  DIGITAL_MARKETING_BOOTCAMP = 'digitalmarketing-bootcamp',
  UIUX_BOOTCAMP = 'uxui-bootcamp',
  CYBER_BOOTCAMP = 'cyber-bootcamp',
  DATA_BOOTCAMP = 'data-bootcamp',
  CODING_BOOTCAMP = 'coding-bootcamp',
  DEFAULT = 'edu-a-default',
  DEFAULT_SCHOOL = 'edu-s-default',
  NO_ADS = 'no-ads',
  NO_OUTBRAIN = 'no-outbrain',
  NO_VIDEO_ADS = 'No Video Ads',
  NO_RECIRCULATION_MODULES = 'No Recirculation Modules',
  NO_TRINITY_ADS = 'No Trinity Ads',
  NO_LATEST_NEWS = 'No Latest News',
  NO_MOST_POPULAR_NEWS = 'No Most Popular News',
}

const PaidPlacement = ({
  adContent,
  adTag,
  className,
  position,
  utmCampaign,
  entityId,
  dataCy,
}: {
  adContent: string;
  adTag: string;
  className?: string;
  position: 'header' | 'in_stream' | 'right_side' | 'footer';
  utmCampaign?: string;
  entityId?: string | null;
  dataCy?: string;
}) => {
  const args = {
    adTag,
    entityId,
    position: position === 'right_side' ? 'sidebar' : 'instream',
    utmCampaign: utmCampaign ? `${utmCampaign}_${adTag}` : adTag,
  };

  return adContent ? (
    <S.Wrapper
      data-cy={dataCy}
      className={className}
      data-adtag={adTag}
    >
      {adContent && ParseEduAdWidgets(adContent, args)}
    </S.Wrapper>
  ) : null;
};
export default withErrorBoundary(PaidPlacement, { fragment: UIFragments.AD });
