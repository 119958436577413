import { calculateBrowserWidth } from './browser';

function calcPubmaticUrl(pubId: number): string {
  const purl = window.location.href;
  let profileVersionId = '';

  const windowSize = calculateBrowserWidth();

  const url =
    windowSize < 768 ?
      `ads.pubmatic.com/AdServer/js/pwt/${pubId}/7653` :
      `ads.pubmatic.com/AdServer/js/pwt/${pubId}/7652`;

  if (purl.indexOf('pwtv=') > 0) {
    const regexp = /pwtv=(.*?)(&|$)/g;
    const matches = regexp.exec(purl);
    if (matches!.length >= 2 && matches![1].length > 0) {
      profileVersionId = `/${matches![1]}`;
    }
  }

  return `https://${url}${profileVersionId}/pwt.js`;
}

export default calcPubmaticUrl;
