import { useEffect, useMemo } from 'react';

/**
 * This hook is used to block the scrolling of the page.
 */
export default function useLockBodyScroll(lock: boolean) {
  const initialStyle = useMemo(
    () => typeof window !== 'undefined' && window.getComputedStyle(document.body).overflow,
    [],
  );
  useEffect(() => {
    if (initialStyle) document.body.style.overflow = lock ? 'hidden' : initialStyle;
    return () => {
      if (initialStyle) document.body.style.overflow = initialStyle;
    };
  }, [lock, initialStyle]);
}
