import { ChangeEvent, forwardRef, LegacyRef } from 'react';
import { InputProps } from './types';
import * as S from './InputStyles';

const InputWithRef = (
  {
    id = '',
    type = 'text',
    placeholder = '',
    assistiveText,
    label,
    className = '',
    required = false,
    size = { default: 'large' },
    variant = 'default',
    onChange,
    ...rest
  }: InputProps,
  ref: LegacyRef<HTMLInputElement>,
) => {
  if (label || assistiveText || variant === 'underlined') {
    return (
      <S.InputWrapper
        className={className}
        $variant={variant}
      >
        {label && (
          <S.Label
            htmlFor={id}
            aria-required={required}
          >
            {label} {required && <span>*</span>}
          </S.Label>
        )}
        <S.Input
          ref={ref}
          $size={size}
          $variant={variant}
          id={id}
          placeholder={placeholder}
          type={type}
          onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
          {...rest}
        />
        {assistiveText && <S.Text className={`assistive-text ${className}`}>{assistiveText}</S.Text>}
      </S.InputWrapper>
    );
  }

  return (
    <S.Input
      ref={ref}
      $size={size}
      $variant={variant}
      id={id}
      placeholder={placeholder}
      type={type}
      onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
      className={className}
      {...rest}
    />
  );
};

const Input = forwardRef(InputWithRef);
export default Input;
