/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import NativoTout from 'components/Nativo/NativoTout';
import RelatedVideo from 'components/RelatedVideo';
import Paywall from 'components/Paywall';
import Regwall from 'components/Regwall';
import { Children, cloneElement, isValidElement, ReactElement, ReactNode } from 'react';

export type InsertElementsArray = { after: ReactNode; node: ReactNode }[];

export type ForEachCallback = (child: ReactElement, next: ReactElement | undefined) => void;

const getNextValidChild = (children: ReactNode | ReactNode[], currentChildIndex: number) => {
  const childrenArray = Children.toArray(children);
  const nextId: number = childrenArray.findIndex((child, index) => index > currentChildIndex && isValidElement(child));
  if (nextId === -1) {
    return undefined;
  }
  return childrenArray[nextId];
};

interface CustomChild {
  props: {
    children?: ReactNode;
  };
}
/**
 * Recursive forEach on React children.
 */
export const forEachChild = (children: CustomChild | CustomChild[], callback: ForEachCallback): void => {
  Children.forEach(children, (child: CustomChild, index: number) => {
    if (isValidElement(child)) {
      const next = getNextValidChild(children, index);
      callback(child, next as ReactElement | undefined);
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (isValidElement(child) && child.props && child.props.children) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      forEachChild(child.props.children as JSX.Element, callback);
    }
  });
};

const getElementToInsert = (child: ReactNode, insert: InsertElementsArray): ReactNode | undefined =>
  insert.find((entry) => entry.after === child)?.node;

/**
 * Inserts a list of nodes into a react component tree with one iteration.
 *  The way it does this is by recursively iterating over the react children and
 *  rebuilding the whole tree. When it finds a node after which something must be inserted,
 *  it will insert the node there.
 *
 * @param children - React children where to insert.
 * @param insert - An array of *ReactNode* pairs, one indication what to insert and one indicating after
 *  which node it should be inserted.
 * @returns A copy of the react component tree with the new nodes inserted.
 */
export const insertElementsIntoTree = (
  children: CustomChild | CustomChild[],
  insert: InsertElementsArray,
): ReactNode | ReactNode[] => {
  const results: ReactNode[] = [];

  Children.forEach(children, (child: CustomChild) => {
    const toInsert = getElementToInsert(child, insert);
    if (!isValidElement(child)) {
      results.push(child);
    } else if (child.props && child.props.children) {
      const newChild = cloneElement(child, {
        children: insertElementsIntoTree(child.props.children as CustomChild[], insert),
      });
      results.push(newChild);
    } else {
      results.push(child);
    }
    if (toInsert) {
      results.push(toInsert);
    }
  });

  // eslint-disable-next-line no-prototype-builtins
  const isSingleNode = children && !children.hasOwnProperty('length');
  if (isSingleNode) {
    return results[0];
  }

  return results;
};

/**
 * Inserts a list of embeds into an initial html content, by taking into account the instructions for each embed.
 *
 * @param initialContent - a React element representing an initial html content
 * (e.g. article html content/ longform html content) which needs to have some React Elements embedded into it
 * @param embeds - the list of embeds which will be inserted
 * @param embedInstructions - a list of instructions for each embed which are telling the node type and the index
 * where that embed will be inserted (e.g. insert first embed after the first index=3 html tags of type node=p)
 * if index=-1 it means that the component will be added as the last child of the html content
 */
export const embedComponentsIntoContent = (
  initialContent: ReactElement,
  embeds: ReactElement[],
  embedInstructions: { node: string; index: number }[],
): ReactElement => {
  let finalHtmlContent: ReactElement = initialContent;

  embeds.forEach((component: ReactElement, idx: number) => {
    const { node: nodeType, index: insertionIndex } = embedInstructions[idx];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const rawHtmlContentChildren = finalHtmlContent.props.children as ReactElement[];

    const lastChild = rawHtmlContentChildren[rawHtmlContentChildren.length - 1];
    const walled = lastChild && (lastChild.type === Paywall || lastChild.type === Regwall);

    if (walled && component.type === NativoTout && insertionIndex === 0) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const wallChildren = lastChild.props.children as ReactElement[];

      if (wallChildren.length && wallChildren[0].type !== NativoTout) {
        wallChildren.unshift(component);

        const newLastChild = cloneElement(lastChild, {
          children: wallChildren,
        });

        rawHtmlContentChildren.splice(-1, 1, newLastChild);

        finalHtmlContent = cloneElement(finalHtmlContent, {
          children: rawHtmlContentChildren,
        });
      }
    } else if (RelatedVideo === component.type) {
      const result: ReactElement[] = [];
      const wallElement = rawHtmlContentChildren.find(
        (contentChild) => contentChild?.type === Paywall || contentChild?.type === Regwall,
      );

      if (wallElement) {
        const wallChildren = wallElement.props.children as ReactElement[];
        if (
          wallChildren.length &&
          wallChildren[wallChildren.length - 1].props.className !== 'related-video-container'
        ) {
          Children.forEach(wallChildren, (child: ReactElement) => {
            if (child && nodeType === child.type) {
              result.push(child);
            }
          });
          let after;
          if (insertionIndex < 0) {
            after = result[result.length + insertionIndex];
          } else {
            after = result[insertionIndex];
          }

          const embedAfter = [{ after, node: component }];

          finalHtmlContent = insertElementsIntoTree(finalHtmlContent, embedAfter) as ReactElement;
        }
      }
    } else if (walled && insertionIndex > 0) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const wallChildren = lastChild.props.children as ReactElement[];
      const result: ReactElement[] = [];
      Children.forEach(wallChildren, (child: ReactElement) => {
        if (child && nodeType === child.type) {
          result.push(child);
        }
      });
      const embedAfter = result.filter((element, index) => insertionIndex === index + 1);

      const insertElementsArray = embedAfter.map((after) => ({
        after,
        node: component,
      }));

      finalHtmlContent = insertElementsIntoTree(finalHtmlContent, insertElementsArray) as ReactElement;
    } else if (insertionIndex > -1) {
      const result: ReactElement[] = [];
      Children.forEach(rawHtmlContentChildren, (child: ReactElement) => {
        if (child && nodeType === child.type) {
          result.push(child);
        }
      });
      const embedAfter = result.filter((element, index) => insertionIndex === index);

      const insertElementsArray = embedAfter.map((after) => ({
        after,
        node: component,
      }));

      finalHtmlContent = insertElementsIntoTree(finalHtmlContent, insertElementsArray) as ReactElement;
    } else {
      // eslint-disable-next-line no-unused-expressions, @typescript-eslint/no-unsafe-call
      Array.isArray(finalHtmlContent.props.children) && finalHtmlContent.props.children.push(component);
    }
  });

  return finalHtmlContent;
};

/**
 * Calculate after which elements an embed should be inserted. The logic is to insert an embed every
 *  *frequency* paragrahps, but making sure all embeds are between two paragraphs.
 * @param elements - React children where to insert embeds.
 * @param frequency - Frequency of embeds.
 * @param lastPWithoutEmbeds - number of paragraphs that should not have any embeds through them starting at the bottom
 * @returns Array of elements after which an embed should be inserted
 */
export const calculateWhereToInsertEmbeds = (
  elements: JSX.Element,
  frequency: number,
  lastPWithoutEmbeds?: number,
): ReactElement[] => {
  const result: { element: ReactElement; index: number }[] = [];
  let count = 0; // number of p tags seen since last embed
  let totalCount = 0; // total number of elements traversed
  forEachChild(elements, (child: ReactElement, next: ReactElement | undefined) => {
    if (child.type === 'p') {
      count += 1;
      if (count >= frequency && next && next.type === 'p') {
        // if we remove the p tag conditon, we can insert more ads
        result.push({ element: child, index: totalCount });
        count = 0; // reseting count to keep track of paragraphs between embeds
      }
      totalCount += 1;
    }
  });

  if (lastPWithoutEmbeds) {
    let i = result.length - 1; // results array that contains components
    while (i >= 0 && result[i].index > totalCount - lastPWithoutEmbeds - 1) {
      result.splice(i, 1);
      i -= 1;
    }
  }
  return result.map((entry) => entry.element);
};
