/* eslint-disable react/destructuring-assignment */
import { PianoPageConfig } from 'services/Piano/entities/PianoPageConfig';
import { UserContext } from 'providers/UserProvider';
import React, { useEffect, useState, useMemo, createContext, SetStateAction, Dispatch, useContext } from 'react';
import { isPaywallContent } from 'utils/paywallUtils';
import { PaywallContext, defaultPaywallContext } from 'services/Paywall';
import usePaywallIframeObserver from 'hooks/usePaywallIframeObserver';

interface PaywallProps {
  children: JSX.Element;
  pianoPageConfig: PianoPageConfig;
}

const defaultSetPayWallState = (() => {}) as Dispatch<SetStateAction<PaywallContext>>;

export const UserPaywallContext = createContext({
  paywallState: defaultPaywallContext,
  setPaywallState: defaultSetPayWallState,
});

const PaywallProvider: React.FC<PaywallProps> = ({ children, pianoPageConfig }) => {
  const [paywallState, setPaywallState] = useState(defaultPaywallContext);
  const user = useContext(UserContext);
  const { userState } = user;
  usePaywallIframeObserver();

  useEffect(() => {
    const regwallContent = pianoPageConfig.contentSection.search('F-Service') !== -1;
    const paywallContent = isPaywallContent(pianoPageConfig);
    const { isLoggedIn } = user.userState;
    const { isBypassed, paywall30 } = user;

    let paywallCheck = paywall30;
    if (!paywallContent || (paywallContent && paywall30 === 'no-paywall') || isBypassed) {
      // Not paywallable content OR paywallable content and not paywalled
      paywallCheck = 'no-paywall';
    } else if (paywallContent && paywall30 === 'paywalled' && !isBypassed) {
      // Paywallable content and paywalled
      paywallCheck = 'paywalled';
    }
    const regwallCheck = regwallContent && !isLoggedIn && !isBypassed;

    setPaywallState({
      paywallCheck,
      regwallCheck,
    });
  }, [user, userState, pianoPageConfig]);

  const memoUserPaywallvalue = useMemo(() => ({ paywallState, setPaywallState }), [paywallState, setPaywallState]);

  return <UserPaywallContext.Provider value={memoUserPaywallvalue}>{children}</UserPaywallContext.Provider>;
};

export default PaywallProvider;
